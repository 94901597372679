// In your testimonial data file or a separate type definitions file
type Testimonial = {
    id: number;
    name: string;
    relationship: string;
    testimonial: string;
    date: string;
  };
  

const testimonialData: Testimonial[] = [
    {
      "id": 1,
      "name": "Desmond",
      "relationship": "Uncle",
      "testimonial": "Seeing my nephew use this app and improve his math skills while being excited about earning his allowance has been wonderful. It's great to see educational tools that engage children in such a positive way.",
      "date": "2024-02-01"
    },
    {
      "id": 2,
      "name": "Tina",
      "relationship": "Educator",
      "testimonial": "I recommended this app to my students as a supplementary learning tool, and the feedback has been overwhelmingly positive. It's rewarding to see students engage with math outside the classroom in such a productive and fun way.",
      "date": "2024-04-25"
    },
    {
      "id": 2,
      "name": "Mary",
      "relationship": "Friend",
      "testimonial": "My friend's daughter has been using this app, and the change in her math confidence is incredible. Plus, she's learning the value of money and saving, which is fantastic!",
      "date": "2024-02-15"
    },
    {
      "id": 3,
      "name": "Anthony",
      "relationship": "Parent",
      "testimonial": "This app has made math fun for my kids. They're always excited to solve problems and see their earnings grow. It's a great tool for both education and teaching financial responsibility.",
      "date": "2024-03-05"
    },
    {
        "id": 4,
        "name": "Nikkie",
        "relationship": "Parent",
        "testimonial": "My son has never been so enthusiastic about learning math before. The app's rewards system motivates him to practice more, and I can see his math skills improving. It's wonderful to have a tool that supports his education in such an engaging way.",
        "date": "2024-03-20"
      },
      {
        "id": 5,
        "name": "Henry",
        "relationship": "Parent",
        "testimonial": "As a parent, I appreciate how this app teaches not just math, but also the value of money and hard work. My daughter loves the challenges and the rewards. It's a part of our daily learning routine now.",
        "date": "2024-04-10"
      },
      {
        "id": 6,
        "name": "Mary",
        "relationship": "Friend",
        "testimonial": "My friend's daughter has been using this app, and the change in her math confidence is incredible. Plus, she's learning the value of money and saving, which is fantastic!",
        "date": "2024-02-15"
      },
      {
        "id": 7,
        "name": "Leslie",
        "relationship": "Parent",
        "testimonial": "I've been thrilled with the progress my children have made in math since starting with this app. The interactive and rewarding system has made them eager learners, turning what used to be a struggle into a fun and productive activity.",
        "date": "2024-05-10"
      },
      {
        "id": 8,
        "name": "Peter",
        "relationship": "Guardian",
        "testimonial": "As a guardian to two curious minds, I find the app's balance between educational content and engaging rewards perfectly suited for encouraging a love for math. It's highlighted the practical use of math in everyday life for them.",
        "date": "2024-05-25"
      },
      {
        "id": 9,
        "name": "Cathy",
        "relationship": "Educator",
        "testimonial": "As an elementary school teacher, I've seen remarkable improvements in my students' math skills and overall confidence. I credit this app for providing a platform where students can practice math in a stress-free environment, making my teaching even more effective.",
        "date": "2024-06-05"
      },
      {
        "id": 10,
        "name": "Matthew",
        "relationship": "Sibling",
        "testimonial": "I introduced my younger sister to this app, and now she's not only ahead in her math class but also teaching me a thing or two. It's amazing how this app has turned learning into a fun challenge that we can now share.",
        "date": "2024-06-20"
      }
  ]
  
  export default testimonialData;