import React, { useState, useEffect, useContext, useRef } from 'react';
import './EarnGame.css'; // Ensure to create and import the CSS for this component
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import { getAuth, updateCurrentUser } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import {functions, db, auth} from '../../firebase'
import { doc, getDoc, updateDoc, runTransaction, onSnapshot } from 'firebase/firestore';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
// Import your custom components and utilities
import { generateMathProblem, MathProblem } from '../../utils/mathProblem';
import { dollarToCent, centToDollar } from '../../utils/currencyUtils';
import { current } from '@reduxjs/toolkit';
import Header from '../../componnts/Header/Header';
import EarningsDisplay from '../../componnts/EarningsDisplay/EarningsDisplay';
import MathProblemDisplay from '../../componnts/MathProblemDisplay/MathProblemDisplay';
import NumberPadButton from '../../componnts/NumberPadButton/NumberPadButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { updateUserSettings } from '../../services/authServices';
import Confetti from 'react-confetti';
import MathJaxProblem from '../../componnts/MathJaxProblem/MathJaxProblem';
import { MathProblemAnswer } from '../../utils/mathProblem';
import PageTracker from '../../componnts/PageTracker/PageTracker';

// Define the props for the MilestoneModal component
interface MilestoneModalProps {
  onClose: () => void;
  achievementLevel: string;
  badgeTitle: string;
  badgeUrl: string;
}


const MilestoneModal: React.FC<MilestoneModalProps> = ({
  onClose,
  achievementLevel,
  badgeTitle,
  badgeUrl
}) => {
  return (
    <div className="achievement-modal-overlay">
      <Confetti/>
      <div className="achievement-modal-content">
        <h2>Achievement Unlocked!</h2>
        <p>Congratulations on reaching level {achievementLevel}</p>
        <h4><strong>{badgeTitle}</strong></h4>
        <img src={badgeUrl} className="achievement-modal-badge" alt={`Badge for level ${achievementLevel}`} />
        <button className="achievement-modal-button" onClick={onClose}>OK</button>
      </div>
    </div>
  );
};

interface UserSettings {
  difficulty: string;
  soundOn: boolean;
  categories: string[];
}

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  difficulty: string;
  onToggleSound: (isSoundOn: boolean) => void;
  soundOn: boolean;
  
  setIsSettingsModalOpen: (isSettingsModalOpen: boolean) => void;
  userCategories: string[];
  
  handleUpdateSettings: (settings: UserSettings) => void;
  clearCategoriesInFirestore: () => void;
  
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  isOpen,
  onClose,
  difficulty,
  soundOn,

  setIsSettingsModalOpen,
  userCategories,
  onToggleSound,
  handleUpdateSettings,
  clearCategoriesInFirestore,
}) => {
  const getCategoryOptions = (gradeLevel: string) => {
    switch(gradeLevel) {
      case '1':
        return ['1st Grade Addition', '1st Grade Subtraction'];
      case '2':
        return ['2nd Grade Addition', '2nd Grade Subtraction', '2nd Grade Multiplication'];
      case '3':
        return ['3rd Grade Addition', '3rd Grade Subtraction', '3rd Grade Multiplication', '3rd Grade Division'];
      case '4':
        return ['4th Grade Addition', '4th Grade Subtraction', '4th Grade Multiplication', '4th Grade Division', 'Decimals'];
      case '5':
        return ['Fraction Addition', 'Fraction Subtraction',];
      case '6':
        return ['Fraction Addition', 'Fraction Subtraction', 'Fraction Multiplication', 'Fraction Division' ];
      case '7':
        return ['Percentages', 'Mean', 'Median', 'Probability'];
      case '8':
        return ['Exponents', 'Multiply Powers', 'Divide Powers', 'Square Roots'];
      case '9':
        return ['Basic Linear','Basic Quadratic','Basic Inequality','Basic Systems of Equations',];
      case '10':
        return ['Angle of Triangle', 'Perimeter', 'Area', 'Volume', 'Pythagorean Theorem', 'Surface Area', 'Circumference'];
      case '11':
        return ['Complex Linear','Complex Quadratic','Complex Inequality','Complex Systems of Equations','Complex SimplifyExpression','Complex Function Evaluation', 'Complex Polynomial Operations', 'Complex Rational','Complex LogFunction','Complex SequenceAndSeries'];
      case '12':
        return ["Function Evaluation", "Exponential Functions", "Trigonometric Identities", "Limits", "Derivatives", "Integrals"];
      default:
        return ['1st Grade Addition', '1st Grade Subtraction']; // Default categories
    }
  };
  const [localDifficulty, setLocalDifficulty] = useState(difficulty);
  const [localCategories, setLocalCategories] = useState(userCategories);

  useEffect(() => {
    setLocalDifficulty(difficulty);
  }, [difficulty]);

  useEffect(() => {
    setLocalCategories(userCategories);
  }, [userCategories]);

  const handleSoundSettingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isSoundOn = event.target.checked; // This will be a boolean
    onToggleSound(isSoundOn); // Now correctly matches the expected function type
  };
  

  const handleCategoryChange = (category: any, isChecked: boolean) => {
    // If trying to uncheck and it's the last checked category, prevent it
  //   if (!isChecked && localCategories.length === 1 && localCategories.includes(category)) {
  //     alert("At least one category must be selected."); // or use a more sophisticated notification
  //     return;
  // }
    if (isChecked) {
      setLocalCategories([...localCategories, category]);
    } else {
      setLocalCategories(localCategories.filter((cat) => cat !== category));
    }
  };

  const handleSave = async () => {
    // Get only the relevant categories based on the selected difficulty
    const relevantCategories = getCategoryOptions(localDifficulty);
  
    // Filter localCategories to include only those that are relevant
    const updatedCategories = localCategories.filter(category => 
        relevantCategories.includes(category));
  
    // Check for at least one category other than 'Decimals'
    const hasNonDecimalCategory = updatedCategories.some(category => category !== 'Decimals');
  
    // Ensure there's at least one non-decimal category selected
    if (!hasNonDecimalCategory) {
      alert("Please select at least one category. (Decimals cannot be the only category, if chosen).");
      return;
    }
  
    // Define new settings
    const newSettings = {
        difficulty: localDifficulty,
        soundOn: soundOn,
        categories: updatedCategories
    };
  
    // Clear categories in Firestore
    await clearCategoriesInFirestore();
  
    // Update state and Firestore with new settings
    await handleUpdateSettings(newSettings);
  
    setIsSettingsModalOpen(false);
  };
  
  

  if (!isOpen) return null;

  
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="settings-close-container">
          <span className="settings-close" onClick={onClose}>&times;</span>
        </div>
        <h2 className="modal-title">Game Settings</h2>

        {/* Dropdown Menu for Difficulty */}
        <label className="difficulty-label" htmlFor="difficulty-select">Difficulty</label>
        <select 
        className="difficulty-select"
          id="difficulty-select"
          value={localDifficulty}
          onChange={(e) => setLocalDifficulty(e.target.value)}
        >
          <option value="1">1st Grade</option>
          <option value="2">2nd Grade</option>
          <option value="3">3rd Grade</option>
          <option value="4">4th Grade</option>
          <option value="5">5th Grade</option>
          <option value="6">6th Grade</option>
          <option value="7">7th Grade</option>
          <option value="8">8th Grade</option>
          <option value="9">9th Grade (Algebra 1)</option>
          <option value="10">10th Grade (Geometry)</option>
          <option value="11">11th Grade (Algebra 2)</option>
          <option value="12">12th Grade (Pre-Calculus)</option>
        </select>

        {getCategoryOptions(localDifficulty).map((category, index) => (
          <div className="checkbox-options" key={category}>
          <label>
          <input 
              id={`checkbox-${index}`} // Ensure each checkbox has a unique ID
              className="checkbox"
              type="checkbox"
              checked={localCategories.includes(category)}
              onChange={(e) => handleCategoryChange(category, e.target.checked)}
          />
          <span className="checkbox-label">{category}</span>
          </label>
      </div>
        ))}

        {/* Toggle Switch for Sound */}
        <div className="sound-toggle">
          <label className="sound-label" htmlFor="sound-toggle">Sound:</label>
          <label className="switch">
            <input 
              type="checkbox" 
              checked={soundOn}
              onChange={handleSoundSettingChange}
            />
            <span className="slider round"></span>
          </label>
        </div>

        <div className="save-button-container">
        <button className="save-button" onClick={handleSave}>
          Save
        </button>

        </div>
      </div>
    </div>
  );
};



// Define interfaces for any types you need
interface BalanceResponseData {
  earned: number;
  remaining: number;
  potential: number;
  redeemed: number;
}

interface UserResponseData {
  userData: {
    settings: any;
    avatar: string;
    username: string;
    achievementsByDifficulty: any;
    statsByDifficulty: any;
    acknowledgedMilestones: any;
    reveals: any;
    subscriptionActive: any;
  };
}

const correctAnswerSound = new Audio('/correct-answer.mp3');
const wrongAnswerSound = new Audio('/wrong-answer.mp3');

const EarnGame: React.FC = () => {
  const { user, initializing } = useContext(AuthContext) ?? { user: null };
  const [gameInitialized, setGameInitialized] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showIndicator, setShowIndicator] = useState<boolean>(false);
  const [isEarningLimitReached, setIsEarningLimitReached] = useState(false);
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);
  const [showRevealBlurb, setShowRevealBlurb] = useState(false);
  const [revealUsed, setRevealUsed] = useState<boolean>(false);
  const [showRevealModal, setShowRevealModal] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isAnswerCorrect, setIsAnswerCorrect] = useState<boolean>(false);
  const [mathProblem, setMathProblem] = useState<MathProblem | null>(null);
  const [userEarnings, setUserEarnings] = useState<BalanceResponseData>({ earned: 0, remaining: 0, potential: 0, redeemed: 0 });
  const [correctAnswer, setCorrectAnswer] = useState<any | null>(null);
  const problemValue = 0.10
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(false);
  const [difficulty, setDifficulty] = useState<string>('1');
  const [soundOn, setSoundOn] = useState<boolean>(true);
  const [avatarURI, setAvatarURI] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [reveals, setReveals] = useState<number>(0);
  const [subscriptionActive, setSubscriptionActive] = useState<boolean>(false);
  const [achievementLevel, setAchievementLevel] = useState<string>('');
  const [currentCorrectAnswers, setCurrentCorrectAnswers] = useState<number>(0);
  const [nextMilestone, setNextMilestone] = useState<any>(null);
  const [badgeImageUrl, setBadgeImageUrl] = useState<string>('');
  const [badgeImageUrl2, setBadgeImageUrl2] = useState<string>('');
  const [badgeTitle, setBadgeTitle] = useState<string>('');
  const [badgeTitle2, setBadgeTitle2] = useState<string>('');
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showMilestoneModal, setShowMilestoneModal] = useState(false);
  const milestones = [
    10, 20, 50, 75, 100, // Tier 1 - Pro
    200, 400, 800, 1600, 3200, // Tier 2 - Genius
    6400, 12800, 25600, 51200, 102400 // Tier 3 - Master
  ];
  const [postfixText, setPostfixText] = useState(""); // State variable to hold the text/formula to display after the input box
  const [prefixText, setPrefixText] = useState("");
  const [inputNumerator, setInputNumerator] = useState('');
const [inputDenominator, setInputDenominator] = useState('');
const [activeFractionInput, setActiveFractionInput] = useState('numerator');
const navigate = useNavigate();
// const [inequalityOperation, setInequalityOperation] = useState<any>('');

interface PaymentLinkResponse {
  url: string;
}
interface PaymentMethodResponseData {
  paymentMethodId: string;
  stripeCustomerId: string;
}

const RevealModal = () => {
  const subscribedMessage = (
    <>
      <p className="reveal-modal-info">As a subscribed user, you get 10 free reveals each day.</p>
      <p className="reveal-modal-info">You've used all your reveals for today. You can wait for tomorrow's reveals or buy 10 more for $1 to continue using the feature.</p>
    </>
  );

  // Message for nonsubscribed users
  const nonSubscribedMessage = (
    <p className="reveal-modal-info">You have used all available reveals. Subscribe for 10 free reveals daily or buy 10 more for $1 to continue using the feature.</p>
  );

  return (
    <div className="revealModalOverlay">
      <div className="revealModalContent">
        <h2 className="reveal-modal-title">No More Reveals</h2>
        {subscriptionActive ? subscribedMessage : nonSubscribedMessage}
        <div className="reveal-button-container">
          <button className="reveal-button" onClick={handleCloseRevealModal}>OK</button>
          <button className="reveal-button" onClick={handleBuyMoreReveals}>Buy More</button>
        </div>
      </div>
    </div>
  );
}

// Function to close the modal
const handleCloseRevealModal  = () => {
  setShowRevealModal(false); // Hide the modal
};

// Function to handle "Buy More" action
const handleBuyMoreReveals = async () => {
  if (!user) {
    alert('User must be logged in to buy more reveals.');
    return;
  }
  setLoading(true);

  try {
    const idToken = await user.getIdToken(true);

    // Assuming you have a Cloud Function named 'createOneTimePaymentLink'
    const createOneTimePaymentLink = httpsCallable<unknown, PaymentLinkResponse>(functions, 'createOneTimePaymentLink');
    const linkResult = await createOneTimePaymentLink({
      idToken,
      email: user.email,
      name: user.displayName,
      // Include any other necessary data for the one-time payment
    });
    window.location.href = linkResult.data.url; // Redirect to the payment page
  } catch (error) {
    console.error('Error creating one-time payment link:', error);
  } finally {
    updatePaymentMethod();
    setLoading(false);
  }
  setShowRevealModal(false); // Optionally close the modal after initiating purchase
};

const updatePaymentMethod = async () => {
  if (!user) {
    console.log('Error updating payment method.');
    return;
  }
  setLoading(true);
  // Logic to handle confirming a subscription
  // This could involve calling a cloud function to create a subscription
  // For example, using a cloud function to create a subscription
  try {
    const idToken = await user.getIdToken(true);
    const getCustomerAndPaymentMethod = httpsCallable<
          unknown,
          PaymentMethodResponseData
        >(functions, 'getCustomerAndPaymentMethod');
        const paymentMethodResponse = await getCustomerAndPaymentMethod({
          idToken,
        });

        const paymentMethodId = paymentMethodResponse.data.paymentMethodId;
        const customerId = paymentMethodResponse.data.stripeCustomerId;

        // Now call setDefaultPaymentMethod
        const setDefaultPaymentMethod = httpsCallable(
          functions,
          'setDefaultPaymentMethod',
        );
        await setDefaultPaymentMethod({customerId, paymentMethodId});

      //  console.log(
      //     'Success',
      //     'Payment method added and set as default successfully.',
      //   );
  } catch (error) {
    console.error('Error creating payment link:', error);
    // ... handle errors
  } finally {
    setLoading(false);
  }
};


const handleToggleSound = (isSoundOn: any) => {
  setSoundOn(isSoundOn);
};



  const handleMilestoneAcknowledgement = async () => {
    setShowMilestoneModal(false)
    // setShowConfetti(false)
  };
  
const openSettingsModal = (): void => {
  setIsSettingsModalOpen(true);
};

const closeSettingsModal = (): void => setIsSettingsModalOpen(false);
  // ... other state variables as needed

  const fetchUserData = async () => {
    if (!user) return;

    const idToken = await user.getIdToken(true);
    const getUser = httpsCallable<unknown, UserResponseData>(functions, 'getUser');
    const userResult = await getUser({ idToken });
    const userData = userResult.data.userData;
   
    // Handling the fetched user data
    if (userData) {
      const difficultyLevel = userData.settings.difficulty;
      setDifficulty(userData.settings.difficulty);
      setSelectedCategories(userData.settings.categories || ['1st Grade Addition', '1st Grade Subtraction']);
      setSoundOn(userData.settings.soundOn);
      setAvatarURI(userData.avatar);
      setUsername(userData.username);
      setReveals(userData.reveals)
      setSubscriptionActive(userData.subscriptionActive)

      const newAchievementLevel = userData.achievementsByDifficulty[difficulty]?.achievementLevel || 0;
      setAchievementLevel(newAchievementLevel);
      setBadgeTitle(badgeTitles[newAchievementLevel.toString()] || badgeTitles['0']);
      setBadgeImageUrl(badgeUrls[newAchievementLevel.toString()] || badgeUrls['0']);
      const correctAnswers = userData.statsByDifficulty[difficultyLevel]?.correctAnswers || 0;
      setCurrentCorrectAnswers(correctAnswers);
      setNextMilestone(calculateNextMilestone(correctAnswers));
  }  
};

// Redirection logic
useEffect(() => {
  if (!user && !initializing) {
    navigate('/login');
  }
}, [user, initializing, navigate]);

  useEffect(() => {
    const initializeGame = async () => {
      if (!user) {
        console.log("No user found, skipping initialization");
        return;
      }

      setLoading(true);
      try {
        await fetchBalanceInfo(); 
        await fetchUserData();
        setGameInitialized(true);
      } catch (error) {
        console.error("Error during game initialization:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeGame();
  }, []); // Runs only once on component mount

  useEffect(() => {
    if (!user) return; // Ensure there's a user before setting up the listener
  
    const userId = user.uid; // Assuming you have the user's UID from the Auth context
    const userRef = doc(db, 'Users', userId);
  
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        setReveals(userData?.reveals || 0);
      } else {
        console.error('User document does not exist');
        // Handle the case where the user document doesn't exist, if necessary
      }
    });
  
    return () => unsubscribe(); // Clean up the listener when the component unmounts or the user changes
  }, [user]); // Depend on 'user' to re-run this effect if the user changes
  

  useEffect(() => {
    fetchUserData();
    handleAchievementDisplay();
  }, [user, difficulty, achievementLevel]);

  // New function to handle achievement display logic
  const handleAchievementDisplay = () => {
    const isMilestoneReached = milestones.includes(currentCorrectAnswers);
    if (isMilestoneReached) {
      setShowMilestoneModal(true);
      setTimeout(() => setShowConfetti(false), 5000); // Display confetti for 5 seconds
    } else {
      setShowMilestoneModal(false);
    }
    
    setBadgeTitlesAndImages();
  };

  // New function to set badge titles and images
  const setBadgeTitlesAndImages = () => {
    const newBadgeTitle = getBadgeTitle(achievementLevel);
    const newBadgeTitle2 = getBadgeTitle(achievementLevel + 1);
    setBadgeTitle(newBadgeTitle);
    setBadgeTitle2(newBadgeTitle2);
    
    const newBadgeUrl = getBadgeImageUrl(achievementLevel);
    const newBadgeUrl2 = getBadgeImageUrl(achievementLevel + 1);
    setBadgeImageUrl(newBadgeUrl);
    setBadgeImageUrl2(newBadgeUrl2);
  };

  const getBadgeImageUrl = (level:string) => {
    // Define your logic to return the URL based on the achievement level
    return badgeUrls[String(level)] || badgeUrls['0'];
  };
  const getBadgeTitle = (level:string) => {
    // Define your logic to return the URL based on the achievement level
    return badgeTitles[String(level)] || badgeTitles['0'];
  };
  // const previousCorrectAnswers = useRef(currentCorrectAnswers);
  // console.log("Previous Achievment Level", previousAchievementLevel)

  

    
    useEffect(() => {
      // Function to fetch and update progress based on the selected difficulty
      const updateProgressForDifficulty = async (selectedDifficulty:string) => {
        if (!user) return;

        // Fetch the user's stats for the selected difficulty
        const userRef = doc(db, 'Users', user.uid);
        try {
          const userDoc = await getDoc(userRef);
          if (!userDoc.exists()) {
            throw new Error('User document does not exist!');
          }

          const userData = userDoc.data();
          const correctAnswers = userData?.statsByDifficulty[selectedDifficulty]?.correctAnswers || 0;

          // Update current correct answers and calculate next milestone
          setCurrentCorrectAnswers(correctAnswers);
          const newNextMilestone = calculateNextMilestone(correctAnswers);
          setNextMilestone(newNextMilestone);
        } catch (error) {
          console.error('Error fetching user stats for difficulty:', error);
        }
      };

      // Call the function with the new difficulty
      updateProgressForDifficulty(difficulty);
      handleNewProblem();
    }, [difficulty, selectedCategories]); // Dependency on 'difficulty'


  
  const fetchBalanceInfo = async () => {
    try {
      const idToken = await user?.getIdToken(true);
      const getBalanceInfo = httpsCallable<unknown, BalanceResponseData>(
        functions,
        'getBalanceInfo',
      );

      const result = await getBalanceInfo({ idToken });
      const { earned, remaining, redeemed, potential } = result.data as BalanceResponseData;
      
      setUserEarnings({ earned, remaining, potential, redeemed });

    } catch (error) {
      console.error('Error retrieving balance info:', error);
    } finally {
      setLoading(false);
    }
  };

// Function to update user settings in Firebase
const updateUserSettings = async (newSettings:any) => {
  setDifficulty(newSettings.difficulty);
  setSelectedCategories(newSettings.categories);
  if (!user) {
    console.error('No user found for updating settings');
    return;
  }

  const userRef = doc(db, 'Users', user.uid);
  try {
    await updateDoc(userRef, {
      'settings': newSettings
    });
    // console.log('User settings updated successfully');
  } catch (error) {
    console.error('Error updating user settings:', error);
    // You can perform additional error handling or recovery here if needed
  }
};

const clearCategoriesInFirestore = async () => {
  if (!user) return;

  const userRef = doc(db, 'Users', user.uid);
  try {
    // Clear the categories in Firestore
    await updateDoc(userRef, {
      'settings.categories': []
    });
    // console.log('Categories cleared in Firestore');
  } catch (error) {
    console.error('Error clearing categories in Firestore:', error);
  }
};


interface UserSettings {
  difficulty: string;
  soundOn: boolean;
  categories: string[];
}

const handleUpdateSettings = async (settings:UserSettings) => {
  // Assuming settings is an object with difficulty, soundOn, and categories
  setDifficulty(settings.difficulty);
  setSoundOn(settings.soundOn);
  setSelectedCategories(settings.categories);

  // Now call the update function with the new settings
  await updateUserSettings(settings);
};



const toggleInfoBlurb = () => {
  setShowInfoBlurb(!showInfoBlurb);
};

const handleRevealClick = async () => {
  if (reveals > 0 && !revealUsed) {
    setRevealUsed(true); // Mark reveal as used for this problem
    setShowRevealBlurb(!showRevealBlurb);
    const newRevealsCount = reveals - 1;
      setReveals(newRevealsCount); // Update local state

      if (user) {
        const userRef = doc(db, 'Users', user.uid);
        try {
          // Update Firestore
          await updateDoc(userRef, {
            reveals: newRevealsCount
          });
          // console.log('Reveals count updated successfully in Firestore');
          setShowRevealBlurb(true); // Show the answer
        } catch (error) {
          console.error('Error updating reveals count in Firestore:', error);
        }
      }
    } else if (reveals === 0) {
      setShowRevealModal(true);
    }
  };

const topicToPrefixText: { [key: string]: string } = {
  'Basic Linear Algebra': 'x =',
  'Complex Linear Algebra': 'x =',
  'Basic Quadratic Equations': 'x =',
  'Complex Quadratic Equations': 'x =',
  'Basic Inequalities': `x`,
  'Complex Inequalities': `x`,
  'Basic Systems of Equations': `x =`,
  'Complex Systems of Equations': `x =`,
  'Complex Function Evaluation': `g(x) =`,
  'Rational Expressions': `=`,
  'Complex Logarithmic Functions': `x =`,
  'Sequences and Series': `Sum =`,
  'Basic Simplify Expressions': `=`,
  'Complex Simplify Expressions': `=`,
  'Polynomial Operations': `=`,
  'Function Evaluation': `f(x) =`,
  // 'Logarithmic Functions': `x =`,
  'Limits': `=`,
  'Derivatives': `f'(x) =`,
  'Integrals': `s =`,
  
  // Add more mappings as needed
  // For more complex scenarios, use functions to determine the text
};
const topicToPostfixText: { [key: string]: string } = {
  'Basic Linear Algebra': '',
  'Complex Linear Algebra': '',
  'Complex Quadratic Equations': '',
  'Basic Inequalities': ``,
  'Complex Inequalities': ``,
  'Basic Systems of Equations': ``,
  'Complex Systems of Equations': ``,
  'Complex Function Evaluation': ``,
  'Rational Expressions': ``,
  'Complex Logarithmic Functions': ``,
  'Sequences and Series': ``,
  'Basix Simplify Expressions': `x`,
  'Complex Simplify Expressions': `x^2`,
  'Polynomial Operations': ``,
  'Function Evaluation': ``,
  // 'Logarithmic Functions': ``,
  'Limits': ``,
  'Derivatives': ``,
  'Integrals': ``,
  // Add more mappings as needed
  // For more complex scenarios, use functions to determine the text
};

// Function to get postfix text based on the topic
const getPrefixTextForTopic = (topic: string, operation?: string) => {
  // if (topic.includes('Polynomial') && xValue !== undefined) {
  //   return `x = ${xValue}\\, `;
  // }
  // Check if the topic is in the mapping

  if (topic in topicToPrefixText) {
    // If the topic is an inequality, add the operation
    if (topic.includes('Inequalities') && operation) {
      return `${topicToPrefixText[topic]} ${operation}`;
    }
    return topicToPrefixText[topic];
  }
  return '=';
};

// Function to get postfix text based on the topic
const getPostfixTextForTopic = (topic:string) => {
  
  // Check if the topic is in the mapping
  if (topic in topicToPostfixText) {
    
    const value = topicToPostfixText[topic];
    return value || '';
  }
  return '';
};

  const handleNewProblem = () => {
    setShowRevealBlurb(false)
    setRevealUsed(false);
    const problemValueCents = dollarToCent(problemValue);
      let newEarnedCents = dollarToCent(userEarnings.earned) + problemValueCents;
    
      if (centToDollar(newEarnedCents) > userEarnings.potential) {
        const remainingPotentialCents = dollarToCent(userEarnings.potential) - dollarToCent(userEarnings.earned) + dollarToCent(userEarnings.redeemed);
        if (remainingPotentialCents <= problemValueCents) {
          newEarnedCents = dollarToCent(userEarnings.potential);
          setIsEarningLimitReached(true);
        } else {
          // console.log('Earning potential reached. Continue playing for practice.');
          setIsEarningLimitReached(true);
          return;
        }
      } else {
        setIsEarningLimitReached(false);
      }

    const newMathProblem = generateMathProblem(
     
      difficulty,
      selectedCategories,
      
    );

    setMathProblem(newMathProblem);
    if (newMathProblem && (difficulty === '5' || difficulty === '6')) {
      setCorrectAnswer(newMathProblem.answer.fractional);
    } else {
      setCorrectAnswer(newMathProblem.answer);
    }
    // console.log(newMathProblem.answer)
    
    const newPrefixText = getPrefixTextForTopic(newMathProblem.topic, newMathProblem.inequalityOperation);
      const newPostfixText = getPostfixTextForTopic(newMathProblem.topic);
      setPrefixText(newPrefixText);
      setPostfixText(newPostfixText);
    
    clearInput(); // Clear the input field
  };


  const clearInput = () => {
    setInputValue('');
    setInputNumerator('');
    setInputDenominator('');
  };

  // Assuming 'difficultyLevel' is the current difficulty level of the user
const updateAchievementLevel = async (difficultyLevel:any) => {
  if (!user) return;

  const userRef = doc(db, 'Users', user.uid);
  try {
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      throw new Error('User document does not exist!');
    }

    const userData = userDoc.data();
    const correctAnswers = userData?.statsByDifficulty[difficultyLevel]?.correctAnswers || 0;
    let newAchievementLevel = userData?.achievementsByDifficulty[difficultyLevel]?.achievementLevel || 0;
    // Check and update achievement level based on correct answers
    for (let i = milestones.length - 1; i >= 0; i--) {
      if (correctAnswers >= milestones[i] && newAchievementLevel < i + 1) {
        newAchievementLevel = i + 1; // +1 because levels start from 1
        break; // Exit the loop once the new level is found
      }
    }

    // Update the user's achievement level in Firestore if there's a change
    if (userData?.achievementsByDifficulty[difficultyLevel]?.achievementLevel !== newAchievementLevel) {
      await updateDoc(userRef, {
        [`achievementsByDifficulty.${difficultyLevel}.achievementLevel`]: newAchievementLevel,
      });
      setAchievementLevel(newAchievementLevel); // Assuming this sets state in your React component
    }
    
  } catch (error) {
    console.error('Error updating achievement level:', error);
  }
};



const updateStatsByCategories = async (categories:any, isCorrect:any) => {
  if (!Array.isArray(categories)) {
    categories = [categories]; // Ensure categories is always an array
  }
  if (!user) return;

  const userRef = doc(db, 'Users', user.uid);
  try {
    await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userRef);
      if (!userDoc.exists) {
        throw new Error('User document does not exist!');
      }
      const userData = userDoc.data();

      let updates:any = {};
      for (const category of categories) {
        const stats = userData?.statsByCategory[category] || { correctAnswers: 0, totalAttempts: 0 };
        updates[`statsByCategory.${category}`] = {
          correctAnswers: isCorrect ? stats.correctAnswers + 1 : stats.correctAnswers,
          totalAttempts: stats.totalAttempts + 1,
        };
      }

      transaction.update(userRef, updates);
    });
  } catch (error) {
    console.error('Error updating category stats:', error);
  }
};

  
  const updateStatsByDifficulty = async (difficultyLevel:any, isCorrect:any) => {
    if (!user) return;

  const userRef = doc(db, 'Users', user.uid);
  try {
    const statsKey = `statsByDifficulty.${difficultyLevel}`;
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      throw new Error('User document does not exist!');
    }

    const userData = userDoc.data();
    const stats = userData?.statsByDifficulty?.[difficultyLevel] || { correctAnswers: 0, totalAttempts: 0 };
    const updatedStats = {
      correctAnswers: isCorrect ? stats.correctAnswers + 1 : stats.correctAnswers,
      totalAttempts: stats.totalAttempts + 1,
    };

    // Prepare the update object
    const updates: { [key: string]: any } = {};
    updates[statsKey] = updatedStats;

    // Update Firestore
    await updateDoc(userRef, updates);
    // console.log('Updated stats successfully');
  } catch (error) {
    console.error('Error updating difficulty stats:', error);
  }
};

// Function to convert a decimal to a fraction (as a string "numerator/denominator")
const decimalToFraction = (decimal: number): string => {
  // Simple implementation (for demonstration)
  // More complex cases might require finding the closest fraction approximation
  const denominator = 100000; // Fixed denominator for simplicity, adjust as needed
  const numerator = Math.round(decimal * denominator);
  return `${numerator}/${denominator}`;
};

// Function to simplify and compare two fractions
const simplifyAndCompareFractions = (num1: number, denom1: number, num2: number, denom2: number): boolean => {
  const gcd = (a: number, b: number): number => b ? gcd(b, a % b) : a;
  const gcd1 = gcd(num1, denom1);
  const gcd2 = gcd(num2, denom2);
  return num1 / gcd1 === num2 / gcd2 && denom1 / gcd1 === denom2 / gcd2;
};

const checkAnswer = async () => {
  if (!mathProblem) {
    console.error('No math problem available');
    return;
  }

  let isCorrect = false;

  if (mathProblem.expectsFraction) {
    // Problem expects a fraction, so compare using the fraction input boxes
    const userNumerator = parseInt(inputNumerator, 10);
    const userDenominator = parseInt(inputDenominator, 10);
    const [correctNumerator, correctDenominator] = mathProblem.answer.fractional.split('/').map(Number);

    // Simplify both fractions and compare
    isCorrect = simplifyAndCompareFractions(userNumerator, userDenominator, correctNumerator, correctDenominator);
  } else {
    // Non-fraction problem, handle as before using the single input box for numerical answers
    const userAnswer = parseFloat(inputValue);
    const correctAnswer = typeof mathProblem.answer === 'object' ? mathProblem.answer.numerical : mathProblem.answer;
    isCorrect = Math.abs(userAnswer - correctAnswer) < 0.01; // Allow for some floating-point inaccuracy
  }

  if (isCorrect) {
    handleCorrectAnswer();
    if (soundOn) {
      correctAnswerSound.play().catch(err => console.error("Audio playback failed:", err));
    }
  } else {
    // Check if sound is enabled before playing the wrong answer sound
    if (soundOn) {
      wrongAnswerSound.play().catch(err => console.error("Audio playback failed:", err));
    }
  }
  setIsAnswerCorrect(isCorrect);
  setShowIndicator(true);

  setTimeout(() => {
    setShowIndicator(false);
    clearInput();
    handleNewProblem();
    if (mathProblem) {
      mathProblem.category.forEach(cat => {
        updateStatsByCategories(cat, isCorrect);
      });
      updateStatsByDifficulty(difficulty, isCorrect);
    }
  }, 500);
};



  function calculateNextMilestone(currentCorrect: number): number {
    // Find the next milestone that is greater than the currentCorrect
  const nextMilestone = milestones.find(milestone => milestone > currentCorrect);
  
  // If we find a next milestone, return it, otherwise return the last milestone
  return nextMilestone || milestones[milestones.length - 1];
}

  const handleCorrectAnswer = async () => {
    
    if (!user) return;
    
    // Update the correct answers state
    // Ensure newCorrectAnswers is a number
    const newCorrectAnswers = currentCorrectAnswers ? currentCorrectAnswers + 1 : 1;
    setCurrentCorrectAnswers(newCorrectAnswers);

    // Calculate the next milestone and update state
    const newNextMilestone = calculateNextMilestone(newCorrectAnswers);
    if (newNextMilestone !== undefined) {
      setNextMilestone(newNextMilestone);
    }

    await updateAchievementLevel(difficulty);
    // Fetch the latest achievement level from Firestore
    if (user) {
    await fetchBalanceInfo(); // Assume fetchUserData is a function that gets the latest user data from Firestore
    const idToken = await user.getIdToken(true);
    const getUser = httpsCallable<unknown, UserResponseData>(functions, 'getUser');
    const userResult = await getUser({ idToken });

    const userData = userResult.data.userData;
    const newAchievementLevel = userData.achievementsByDifficulty[difficulty]?.achievementLevel || 0;
    setAchievementLevel(newAchievementLevel);
    }

    const problemValueCents = dollarToCent(problemValue);
    let newEarnedCents = dollarToCent(userEarnings.earned) + problemValueCents;
  
    // Assuming you have an equivalent way to play sounds in your web app
    // This part will depend on how you implement audio in your web app
    // For example, you might use HTML audio elements or a JavaScript audio library
  
    if (centToDollar(newEarnedCents) > userEarnings.potential) {
      const remainingPotentialCents = dollarToCent(userEarnings.potential) - dollarToCent(userEarnings.earned) + dollarToCent(userEarnings.redeemed);
      if (remainingPotentialCents <= problemValueCents) {
        newEarnedCents = dollarToCent(userEarnings.potential);
        setIsEarningLimitReached(true);
      } else {
        // console.log('Earning potential reached. Continue playing for practice.');
        setIsEarningLimitReached(true);
        return;
      }
    } else {
      setIsEarningLimitReached(false);
    }
  
    const newEarnedDollars = centToDollar(newEarnedCents);
    const newRemaining = userEarnings.potential - newEarnedDollars;
  
    setUserEarnings({
      ...userEarnings,
      earned: newEarnedDollars,
      remaining: newRemaining > 0 ? newRemaining : 0,
    });
  
    try {
      if (user) { // Using user from AuthContext
        const userRef = doc(db, 'Users', user.uid);
        await updateDoc(userRef, {
          earned: newEarnedDollars,
          remaining: newRemaining > 0 ? newRemaining : 0,
        });
  
        // For UI animation, you might use CSS animations or JavaScript-based animations
        // Adapt `animateToNewEarned` as per your web app's implementation
        //animateToNewEarned(newEarnedDollars);
      }
    } catch (error) {
      console.error('Error updating earned amount in transaction:', error);
    }
  };
  

  const handleInputChange = (e: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
    // If it's a change event, update the input value
    if (e.type === 'change') {
      setInputValue((e as React.ChangeEvent<HTMLInputElement>).target.value);
    }
    
    // If Enter key is pressed, call the checkAnswer function
    if (e.type === 'keydown' && (e as React.KeyboardEvent<HTMLInputElement>).key === 'Enter') {
      checkAnswer();
    }
  };

  const handleNumeratorChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
    setInputNumerator((e as React.ChangeEvent<HTMLInputElement>).target.value);
  };
  
  const handleDenominatorChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
    setInputDenominator((e as React.ChangeEvent<HTMLInputElement>).target.value);
  };
  
// Function to handle clicks or focus on the numerator input
const handleNumeratorFocus = () => {
  setActiveFractionInput('numerator');
};

// Function to handle clicks or focus on the denominator input
const handleDenominatorFocus = () => {
  setActiveFractionInput('denominator');
};

  // Adjusted handleNumberPress function
const handleNumberPress = (label: string) => {
  if (mathProblem?.expectsFraction) {
    if (activeFractionInput === 'numerator') {
      setInputNumerator(prev => prev + label);
    } else if (activeFractionInput === 'denominator') {
      setInputDenominator(prev => prev + label);
    }
  } else {
    setInputValue(prev => prev + label);
  }
};
  
  
  

  const handleFunctionPress = (functionType: string) => {
    switch (functionType) {
      case 'C':
        clearInput();
        break;
      case 'Del':
        setInputValue(prev => prev.slice(0, -1));
        setInputNumerator(prev => prev.slice(0, -1))
        setInputDenominator(prev => prev.slice(0, -1))
        break;
      case '+/-':
        setInputValue(prev => {
          if (prev === '') {
            // If the input is empty, start with a negative sign
            return '-';
          } else if (prev === '-') {
            // If the input is just a negative sign, clear it
            return '';
          } else {
            // Toggle the sign of the existing number
            return prev.startsWith('-') ? prev.slice(1) : `-${prev}`;
          }
        });
        setInputNumerator(prev => {
          if (prev === '') {
            // If the input is empty, start with a negative sign
            return '-';
          } else if (prev === '-') {
            // If the input is just a negative sign, clear it
            return '';
          } else {
            // Toggle the sign of the existing number
            return prev.startsWith('-') ? prev.slice(1) : `-${prev}`;
          }
        })
        setInputDenominator(prev => {
          if (prev === '') {
            // If the input is empty, start with a negative sign
            return '-';
          } else if (prev === '-') {
            // If the input is just a negative sign, clear it
            return '';
          } else {
            // Toggle the sign of the existing number
            return prev.startsWith('-') ? prev.slice(1) : `-${prev}`;
          }
        })
        break;
      // Handle other cases as needed
      default:
        break;
    }
  };

  // Function to handle decimal point press
const handleDecimalPress = () => {
  if (mathProblem?.expectsFraction) {
    if (activeFractionInput === 'numerator') {
      setInputNumerator(prev => prev + '.');
    } else if (activeFractionInput === 'denominator') {
      setInputDenominator(prev => prev + '.');
    }
  } else {
    setInputValue(prev => prev + '.');
  }
};

// Function to handle negative sign press
const handleNegativePress = () => {
  if (mathProblem?.expectsFraction) {
    if (activeFractionInput === 'numerator' && !inputNumerator.startsWith('-')) {
      setInputNumerator(prev => '-' + prev);
    } else if (activeFractionInput === 'denominator' && !inputDenominator.startsWith('-')) {
      setInputDenominator(prev => '-' + prev);
    }
  } else if (!inputValue.startsWith('-')) {
    setInputValue(prev => '-' + prev);
  }
};

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const { key } = event;
      if (key >= '0' && key <= '9') { // Check if the key is a number
        handleNumberPress(key); // Handle number press
      } else if (key === 'Enter') { // Check if the key is Enter
        checkAnswer(); // Check the answer
      } else if (key === 'Backspace') { // Check if the key is Backspace
        handleFunctionPress('Del'); // Handle delete press
      } else if (key === 'Escape') { // Check if the key is Escape
        handleFunctionPress('C'); // Handle clear press
      } else if (key === '.') {
        // Handle decimal point press
        handleDecimalPress();
      } else if (key === '-') {
        // Handle negative sign press
        handleNegativePress();
      }
      // Add more conditions as needed for other keys
    };
  
    document.addEventListener('keydown', handleKeyPress); // Add the event listener
  
    return () => {
      document.removeEventListener('keydown', handleKeyPress); // Remove the event listener on component unmount
    };
  }, [handleNumberPress, checkAnswer, handleFunctionPress, handleDecimalPress, handleNegativePress]); // Add dependencies here
  
  const parseMathProblem = (problem: any) => {
    let parts = problem.split(' ');
    let number1 = parts[0];
    let operation = parts[1];
    let number2 = parts[2];
  
    // Replace the slash with the division symbol and asterisk with multiplication symbol for display
    if (operation === '/') {
      operation = '÷';
    } else if (operation === '*') {
      operation = '×';
    }
  
    return { number1, operation, number2 };
  };

  const parseMathProblemExponent = (problem: any) => {
    let parts = problem.split(' ');
    let number1 = parts[0];
    let operation = parts[1];
    let number2 = parts[2];
  
    // Replace the slash with the division symbol and asterisk with multiplication symbol for display
    if (operation === '/') {
      operation = '÷';
    } else if (operation === '*') {
      operation = '×';
    }
  
    return { number1, operation, number2 };
  };
  
  
  const badgeUrls: { [key: string]: string } = {
    '0': '/badge0.png',
    '1': '/badge1.png',
    '2': '/badge2.png',
    '3': '/badge3.png',
    '4': '/badge4.png',
    '5': '/badge5.png',
    '6': '/badge6.png',
    '7': '/badge7.png',
    '8': '/badge8.png',
    '9': '/badge9.png',
    '10': '/badge10.png',
    '11': '/badge11.png',
    '12': '/badge12.png',
    '13': '/badge13.png',
    '14': '/badge14.png',
    '15': '/badge15.png',
  };

  const badgeTitles: { [key: string]: string } = {
    '0': 'Newbie',
  '1': 'Pro Scholar',
  '2': 'Pro Virtuoso',
  '3': 'Pro Maestro',
  '4': 'Pro Champion',
  '5': 'Pro Legend',
  '6': 'Genius Scholar',
  '7': 'Genius Virtuoso',
  '8': 'Genius Maestro',
  '9': 'Genius Champion',
  '10': 'Genius Legend',
  '11': 'Master Scholar',
  '12': 'Master Virtuoso',
  '13': 'Master Maestro',
  '14': 'Master Champion',
  '15': 'Master Legend',
  };
  
  interface AchievementBadgeProps {
    level: string; // or string, if the level is not a number
  }

  interface ProgressBarProps {
    current: number; 
    nextMilestone: number;
  }
  function AchievementBadge({ level }: AchievementBadgeProps) {
    //const badgeUrl = badgeUrls[String(level)] || badgeUrls['0']; // Convert level to a string key
    return <img src={badgeImageUrl} alt={`Level ${level} Badge`} className="achievement-badge" />;
  }

function ProgressBar({ current, nextMilestone }: ProgressBarProps)  {
  const percentage = (current / nextMilestone) * 100;
  return (
    <div className="progress-bar-outer">
  <div className="progress-bar-inner" style={{ width: `${percentage}%` }}></div>
  <div className="progress-bar-text">
    {current} / {nextMilestone}
  </div>
</div>

  );
};

const algebraCategoriesToCheck1 = ['Rational'];
const algebraCategoriesToCheck2 = ['Systems'];
// const algebraCategoriesToCheck2 = ['Sequence'];
const isAlgebraProblem = mathProblem && 
(difficulty === '9' 
|| difficulty === '11' 
&& !algebraCategoriesToCheck1.some(category => mathProblem.topic.includes(category))
&& !algebraCategoriesToCheck2.some(category => mathProblem.topic.includes(category))
);

const isAlgebraRationalProblem = mathProblem && algebraCategoriesToCheck1.some(category => mathProblem.topic.includes(category));
const isAlgebraSystemsProblem = mathProblem && algebraCategoriesToCheck2.some(category => mathProblem.topic.includes(category));
// const isAlgebraSequenceProblem = mathProblem && algebraCategoriesToCheck2.some(category => mathProblem.topic.includes(category));

const isFractionProblem = mathProblem && (difficulty === '5' || difficulty === '6');

const is7thProblem = mathProblem && (difficulty === '7');

const is8thProblem = mathProblem && (difficulty === '8');

//const geometryCategoriesToCheck = ['Angle', 'Perimeter', 'Circumference', 'Area', 'Volume', 'Surface', 'Pythagorean'];
const isGeometryProblem = mathProblem && difficulty === '10';

//const calculusCategoriesToCheck = ['Evaluation', 'Simplification', 'Logarithmic', 'Exponential', 'Trigonometric', 'Limits', 'Derivatives', 'Integrals'];
const isCalculusProblem = mathProblem && difficulty === '12';


  
  return (
    <div className="earnGameContainer">
      <PageTracker />
      <Header></Header>
      {loading ? (
        <LoadingScreen/>
      ) : gameInitialized ? (
        
        <div className="game-container outer-game-spikes">
          
          {showConfetti && <Confetti />}
          <div className="game-earnings-container"> 
          
          <div>
            <img src={avatarURI} alt="Avatar" className="game-avatar" />
            <div className="game-username">{username}</div>
          </div>
          <div className="game-earnings-container-left">
          {(!subscriptionActive || isEarningLimitReached) && (
    <div className="limit-badge-container" onClick={toggleInfoBlurb}>
      <div className="limit-badge">!</div>
      {showInfoBlurb && (
        <div className="limit-info-blurb">
          {/* Show specific message based on subscription status and earning limit */}
          {subscriptionActive && isEarningLimitReached
            ? "You've reached your earning potential! Continue playing for practice."
            : "As a non-subscribed user, you cannot earn an allowance. You can continue to play for practice."
          }
        </div>
      )}
    </div>
  )}

              <EarningsDisplay
                earned={userEarnings.earned}
                potential={userEarnings.potential}
                redeemed={userEarnings.redeemed}
              />
            </div>
            
            <FontAwesomeIcon icon={faCog} className="faCogIcon" onClick={openSettingsModal} />

            {showMilestoneModal && (
              <MilestoneModal
                onClose={handleMilestoneAcknowledgement}
                achievementLevel={achievementLevel}
                badgeTitle={getBadgeTitle(achievementLevel)}
                badgeUrl={getBadgeImageUrl(achievementLevel)}
              />
            )}

            {isSettingsModalOpen && (
              <SettingsModal
                isOpen={isSettingsModalOpen}
                onClose={closeSettingsModal}
                difficulty={difficulty}
                
                soundOn={soundOn}
                
                userCategories = {selectedCategories}
                onToggleSound={handleToggleSound}
                setIsSettingsModalOpen={setIsSettingsModalOpen}
                handleUpdateSettings={handleUpdateSettings}
                clearCategoriesInFirestore={clearCategoriesInFirestore}
              />
            )}
          </div>
          <div className="game-achievement-container">
            <div className="achievement-title-container">
              <div className="game-badge-container">
                <img src={badgeImageUrl} alt={`Level ${achievementLevel} Badge`} className="game-achievement-badge" />
                <span className="game-badge-title">{badgeTitle}</span>
              </div>
                <span className="grade-level-text">Grade {difficulty}</span>
              <div className="game-badge-container">
                <img src={badgeImageUrl2} alt={`Level ${achievementLevel + 1} Badge`} className="game-achievement-badge" />
                <span className="game-badge-title2">{badgeTitle2}</span>
              </div>
            </div> 
          {/* <div className="achievement-container">
            <div className="achievement-title-container">
              <div className="game-badge-container">
                <img src={badgeImageUrl} alt={`Level ${achievementLevel} Badge`} className="achievement-badge" />
                <span className="game-badge-title">{badgeTitle}</span>
              </div>
                <span className="grade-level-text">{difficulty} Grade</span>
              <div className="game-badge-container">
                <img src={badgeImageUrl2} alt={`Level ${achievementLevel + 1} Badge`} className="achievement-badge" />
                <span className="game-badge-title">{badgeTitle2}</span>
              </div>
            </div>  */}
            {nextMilestone !== undefined && (
  <ProgressBar current={currentCorrectAnswers} nextMilestone={nextMilestone} />
)}
          </div>

          {showIndicator && (
            <div className="indicator">
          {isAnswerCorrect ? (
            <span className="correctIndicator">✓</span> // Green checkmark
          ) : (
            <span className="incorrectIndicator">✗</span> // Red X
         )}
          </div>
          )}

{mathProblem && (
  <div className="problemContainer game-spikes">
    <div className="problem-topic-container">
      <div className="problem-topic">{mathProblem.topic}</div>
      
    </div>
    {isGeometryProblem ? (
      // Render UI for Algebra problem
      
      <div className="geometryProblem">
        <span className="geometry-shape">{mathProblem.shapeComponent}</span>
        <div className="geometryLine">
          <MathJaxProblem problem={`${mathProblem.question}`} />
        </div>
      </div>
    ) : is7thProblem ? (
      <div className="seventhProblem">
          {/* <span className="seventh-problem-topic">{mathProblem.topic}</span> */}
        <div className="seventhLine">
        <MathJaxProblem problem={`${mathProblem.question}`} />
        </div>
      </div>
    ) : is8thProblem ? (
      <div className="eighthProblem">
    {/* <span className="eighth-problem-topic">{mathProblem.topic}</span> */}
    <div className="eighthLine">
      {/* <span>{parseMathProblem(mathProblem.question).number1}</span> */}
      <MathJaxProblem problem={`${mathProblem.question}`} />
      {/* <span>{parseMathProblem(mathProblem.question).number2}</span> */}
    </div>
  </div>
    ) : isFractionProblem ? (
      // Render UI for Algebra problem
      <div className="fractionProblem">
    {/* <span className="fraction-problem-topic">{mathProblem.topic}</span> */}
    <div className="fractionLine">
      {/* Use MathJaxProblem component to render the fraction */}
      <MathJaxProblem problem={`\(${mathProblem.question}\)`} />
    </div>
  </div>
    ) :
    isAlgebraProblem ? (
      // Render UI for Algebra problem
      <div className="algebraProblem">
          {/* <span className="algebra-problem-topic">{mathProblem.topic}</span> */}
        <div className="algebraLine">
        <MathJaxProblem problem={`${mathProblem.question}`} />
        </div>
      </div>
    ) : isAlgebraRationalProblem ? (
      // Render UI for Algebra problem
      <div className="algebraRationalProblem">
          {/* <span className="algebra-problem-topic">{mathProblem.topic}</span> */}
        <div className="algebraRationalLine">
        <MathJaxProblem problem={`${mathProblem.question}`} />
        </div>
      </div>
    ) : 
    isAlgebraSystemsProblem ? (
      // Render UI for Algebra problem
      <div className="algebraSystemsProblem">
          {/* <span className="algebra-problem-topic">{mathProblem.topic}</span> */}
        <div className="algebraSystemsLine">
        <MathJaxProblem problem={`${mathProblem.question}`} />
        </div>
      </div>
    ) :
    isCalculusProblem ? (
      
      <div className="calculusProblem">
        {/* <div className="calculus-problem-topic">{mathProblem.topic}</div> */}
        <div className="calculusLine">
          <MathJaxProblem problem={`${mathProblem.question}`} />
        </div>
      </div>
    ) :
    isCalculusProblem ? (
      
      <div className="calculusProblem">
        {/* <div className="calculus-problem-topic">{mathProblem.topic}</div> */}
        <div className="calculusLine">
          <MathJaxProblem problem={`${mathProblem.question}`} />
        </div>
      </div>
    ) : (
      // Render UI for non-geometry and non-algebra problems
      <div className="problem">
        {/* <span className="problem-topic">{mathProblem.topic}</span> */}
        <div className="problem-stacked">
        <div className="topLine">
          <span className="number1">{parseMathProblem(mathProblem.question).number1}</span>
        </div>
        <div className="bottomLine">
          <span>{parseMathProblem(mathProblem.question).operation}</span>
          <span className="number2">{parseMathProblem(mathProblem.question).number2}</span>
        </div>
        </div>
      </div>
    )}

    {/* Input field might be common to all types of problems */}
    <div className="input-container">
    {prefixText && (
    <span className="prefix-text-container">
      <MathJaxProblem problem={`\\(${prefixText}\\)`} />
    </span>
  )} 
      {mathProblem.expectsFraction ? (
      <div className="fraction-inputs">
      <input
        type="text"
        className="gameInput-fraction"
        value={inputNumerator}
        onChange={handleNumeratorChange}
        readOnly
        onFocus={handleNumeratorFocus} // Add this line
        placeholder="Numerator"
      />
      <div className="fraction-line"></div>
      <input
        type="text"
        className="gameInput-fraction"
        value={inputDenominator}
        onChange={handleDenominatorChange}
        readOnly
        onFocus={handleDenominatorFocus} // And this line
        placeholder="Denominator"
      />
    </div>
    ) : (
      
      <input
        type="text"
        className="gameInput"
        value={inputValue}
        onChange={handleInputChange}
        readOnly
        placeholder="Enter Answer"
      />
      
    )}
      {postfixText && (
    <span className="postfix-text-container">
      <MathJaxProblem problem={`\\(${postfixText}\\)`} />
    </span>
  )} 

      <div className="reveal-badge-container">
        <div className="reveal-badge" onClick={handleRevealClick}>Reveal</div>
        <div className="reveal-count">{reveals}</div>
        {showRevealModal && <RevealModal />}
        {showRevealBlurb && (
                  <div className="reveal-blurb">
                    <span>Answer: {correctAnswer}</span>
                  </div>
                )}
      </div>
    </div>

    
  </div>

  
)}


          <div className="numberPad">
            {['1', '2', '3', '4', '5', '6', '7', '8', '9',].map(label => (
            <NumberPadButton
              key={label}
              label={label}
              onPress={() => handleNumberPress(label)}
            />
            ))}
            <NumberPadButton
              label="+/-"
              onPress={() => handleFunctionPress('+/-')}
            />
            <NumberPadButton
              label="0"
              onPress={() => handleNumberPress('0')}
            />
            <NumberPadButton
              label="."
              onPress={() => handleNumberPress('.')}
            />
            <NumberPadButton
              label="Del"
              onPress={() => handleFunctionPress('Del')}
            />
            <NumberPadButton
              label="C"
              onPress={() => handleFunctionPress('C')}
            />
            
          </div>

          <div className="gameButtonContainer">
            {/* New Problem Button */}
            <button className="game-button" onClick={handleNewProblem}>New Problem</button>

            {/* Check Answer Button */}
            <button className="game-button" onClick={checkAnswer} disabled={loading}>Check Answer</button>
          </div>

          {/* Additional UI components and logic */}
        </div>
      ) : (
        <LoadingScreen/>
      )}
    </div>
  );
  
};

export default EarnGame;