// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore'; // Import Firestore
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyC0oS4hvgGYZJxcy6951kaPDQYeQR0vV8M",
    authDomain: "mathbucks-b58c7.firebaseapp.com",
    projectId: "mathbucks-b58c7",
    storageBucket: "mathbucks-b58c7.appspot.com",
    messagingSenderId: "5236695530",
    appId: "1:5236695530:web:d6f20691e128a955e71293",
    measurementId: "G-CYBSQ3XPLY"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app); // Get the initialized auth instance
const functions = getFunctions(app); // Initialize Firebase Functions
const db = getFirestore(app); // Initialize Firestore
const analytics = getAnalytics(app);

// Export the services you want to use elsewhere in your app
export { app, auth, functions, db };
