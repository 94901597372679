import './Header.css';
import React, { useState, useEffect, useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { AuthContext } from '../../contexts/AuthContext';
import {functions, db, auth} from '../../firebase'
import { doc, getDoc, updateDoc, runTransaction } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faGift, faUser, faLightbulb, faHeadset, faHouse, faQuestion, faGear, faRightFromBracket, faCircleInfo } from '@fortawesome/free-solid-svg-icons';


const Header: React.FC = () => {
  const [avatarURI, setAvatarURI] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(false); //TEST BETA

    const navigate = useNavigate();
    const { user } = useContext(AuthContext);

    const handleStateChange = (state: { isOpen: boolean }) => {
      setOpen(state.isOpen);
    };
  
    const closeMenu = () => {
      setOpen(false);
    };

    // const handleLogin = () => navigate('/login');
    const handleHomeClick = () => {
      navigate('/');

  };

  const fetchUserProfile = async (isMounted: () => boolean) => {
    if (!user) {
      setAvatarURI('');
      setUsername('');
      return;
    }
  
    try {
      const userRef = doc(db, 'Users', user.uid);
      const userProfileSnapshot = await getDoc(userRef);
  
      if (isMounted()) { // Check if the component is still mounted
        if (userProfileSnapshot.exists()) {
          const userProfileData = userProfileSnapshot.data();
          setAvatarURI(userProfileData.avatar);
          setUsername(userProfileData.username);
        } else {
          console.log("No such document!");
        }
      }
    } catch (error) {
      // Only log the error if the component is still mounted
      if (isMounted()) {
        console.error("Error fetching user profile:", error);
      }
    }
  };
  
  
  
  useEffect(() => {
    let isMounted = true;
  
    fetchUserProfile(() => isMounted); // Pass a function to check the mounted status
  
    return () => {
      isMounted = false;
    };
  }, [user]);
  
  
  
  const handleLogout = async () => {
    try {
        await signOut(auth);
        navigate('/'); // Adjust the path to your welcome or login screen
    } catch (error) {
        console.error('Error logging out:', error);
    }
};

  return (
    <header>
        <div className="header-left">
            <button onClick={handleHomeClick} className="logo-button">
                <img src="/MathBucksLogo2.png" alt="MathBucks Logo" className="logo-image" />
            </button>  
            {/* <h1>HEADER</h1> */}
        </div>
        <div className="header-right">
          {/* ///BETA/// */}
                    <button className="temp-button" onClick={() => setShowInfo(!showInfo)}>
                        <span className="temp-button-title">! Beta Test Info !</span>
                    </button>

                    {showInfo && (
                        <div className="temp-info-blurb">
                          <span className="temp-title">Information for Beta Test Users</span>
                          <div className="temp-list-special">Thank you to all testers!</div>
                          <div className="temp-title">Stripe</div>
                          <span className="temp-list">All financial transactions in the app are linked to Stripe in test/development environment. 
                          To test transactions and simulate having an allowance/balance for the app use the following card information:</span>
                          <ul>
                              <li className="temp-list">Card Number: 4242-4242-4242-4242</li>
                              <li className="temp-list">Card Exp: Use a valid future date, such as 12/34</li>
                              <li className="temp-list">Card CVC: Use any three-digit CVC</li>
                              <li className="temp-list">Other: Use any value you like for other form fields.</li>

                          </ul>
                          <div className="temp-title">Tango Card</div>
                          <p className="temp-list">All transactions related to Tango are in a test/development environment. Redemptions/codes for gift cards are not real and do not work.</p>
                          <p className="temp-list">If you have any questions or feedback, click the button below. Please tell us what device you are using and the model.</p>
                          <div className='temp-button-container'>
                            <button className='temp-blurb-button' onClick={() => navigate('/support')}>Send Feedback</button>
                          </div>
                          <div className='temp-button-container'>
                            <button className='temp-blurb-button' onClick={() => setShowInfo(false)}>Close</button>
                          </div>
                        </div>
                    )}
            {/* ///BETA//// */}
            {user ? (
                <Menu 
                right 
                isOpen={isOpen}
                onStateChange={handleStateChange}
                customCrossIcon={false} // Add this line if you want to hide the default cross icon
              >
                <div className="menu-header">
                  <img src={avatarURI} alt="Avatar" className="header-avatar" />
                  <span className="header-username">{username || 'Account'}</span>
                </div>
                
                <Link id="home" className="menu-item" to="/menu" onClick={closeMenu}><FontAwesomeIcon icon={faHouse} className="faGear"/>Home</Link>
                <Link id="settings" className="menu-item" to="/about" onClick={closeMenu}><FontAwesomeIcon icon={faCircleInfo} className="faGear"/>About</Link>
                <Link id="settings" className="menu-item" to="/settings" onClick={closeMenu}><FontAwesomeIcon icon={faGear} className="faGear"/>Settings</Link>
                <Link id="faq" className="menu-item" to="/faq" onClick={closeMenu}><FontAwesomeIcon icon={faQuestion} className="faQuestion"/>FAQ</Link>
                <Link id="support" className="menu-item" to="/support" onClick={closeMenu}><FontAwesomeIcon icon={faHeadset} className="faGear"/>Support</Link>
                <Link id="logout" className="menu-item" to="/" onClick={handleLogout}><FontAwesomeIcon icon={faRightFromBracket} className="faRightFromBracket"/>Logout</Link>
              </Menu>
            ) : (
              location.pathname !== '/login' && (
                <button className="header-button" onClick={() => navigate('/login')}>Login</button>
              )
            )}
        </div>
    </header>
);
};

export default Header;
