// Footer.tsx
import React from 'react';
import './Footer.css'; // Make sure to create a Footer.css file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

interface FooterProps {
  // Define any props you might want to pass to the Footer
  companyName?: string;
  year?: number;
}

const Footer: React.FC<FooterProps> = ({ companyName = 'MathBucks', year = new Date().getFullYear() }) => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>{companyName}</h4>
          <Link to="/about" className="link-color">About</Link>
        </div>
        <div className="footer-section">
          <h4>Contact Us</h4>
          {/* <p>(123) 456-7890</p> */}
          <div>
            <Link to="/support" className="link-color">Support</Link>
          </div>
          <div>           
            <Link to="/faq" className="link-color">FAQ</Link>
          </div>
        </div>
        <div className="footer-section">
          <h4>Follow Us</h4>
          <div className="social-links">
            {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <FontAwesomeIcon icon={faTwitter} />
            </a> */}
            <a href="https://www.instagram.com/mathbucks.app/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
          {/* Social media icons or links would go here */}
        </div>
        <div className="footer-section">
          <h4>Legal</h4>
          <div>
           <div>
            <Link to="/termsandprivacy" className="link-color">Terms of Service</Link>
           </div>
           <div>
            <Link to="/termsandprivacy" className="link-color">Privacy Policy</Link>
           </div>
          </div>
          {/* Social media icons or links would go here */}
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {year} {companyName}. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
