// currencyUtils.ts

// Convert dollars to cents, ensure it's a whole number
export const dollarToCent = (dollars: number): number => {
    return Math.round(dollars * 100);
  };
  
  // Convert cents to dollars, ensure it's a number with 2 decimal places
  export const centToDollar = (cents: number): number => {
    return parseFloat((cents / 100).toFixed(2));
  };
  