// FAQ.tsx
import React from 'react';
import './FAQ.css'; // Assuming you have a corresponding CSS file

interface FAQData {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQData> = ({ question, answer }) => {
  // State to manage toggle for showing/hiding answer
  const [showAnswer, setShowAnswer] = React.useState(false);

  return (
    <div className="faq-item">
      <h2 onClick={() => setShowAnswer(!showAnswer)} className="faq-question">
        {showAnswer ? "− " : "+ "} {question}
      </h2>
      {showAnswer && <p className="faq-answer">{answer}</p>}
    </div>
  );
};

const FAQ: React.FC = () => {
  const faqData: FAQData[] = [
    {
        question: 'What is MathBucks?',
        answer: 'MathBucks is an app that offers a fun and engaging way to practice and improve your math skills whil earning money as an allowance!',
      },
      {
        question: 'How does the Subscription work?',
        answer: 'We offer 2 different subscription plans for $10 and $15. You can use MathBucks to earn back the monthly subscription (minus fees) to spend on Gift Cards.',
      },
      {
        question: 'What are the fees?',
        answer: 'For each montlhy subcscription, we take out a flat fee of $1 to cover for app development/maintenance, and an additional transaction fee for payments through Stripe, our payment gateway.',
      },
      {
        question: 'How do I spend my earned money?',
        answer: 'You can navigate to thr Rewards page to see your earned balance that you can use to redeem for Gift Cards.',
      },
      {
        question: 'Do you allow refunds?',
        answer: 'We do not allow refunds, but you have the option to cancel anytime. If you have already been charged, you will still be able to earn balance back, but will not be charge again untill you renew.',
      },
      {
        question: 'What happens to money that I don\'t earn?',
        answer: 'Any money you don\'t earn for any given month, will roll over to the next month. This means you will always have the opportunity to earn your allowance!',
      },
      {
        question: 'How long does my earned balance last?',
        answer: 'Your earned balance lasts as long as you are active with the app, and you can spend it at any time. If your account is deemed inactive after 1 year, we will close your account and you will no longer have access to your earned balance.',
      },
      {
        question: 'How do Delete my account?',
        answer: 'You delete your account in the Settings page. Deleting an account removes all data, including payment information and any unclaimed balance, which cannot be recovered',
      },
      {
        question: 'How do I report a bug or get support?',
        answer: 'You can send an email to support@mathbucks.app',
      },
    // ... other FAQ data
  ];

  return (
    <div className="faq-container">
      <h1 className="faq-header">FAQ</h1>
      {faqData.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQ;
