import React, { useEffect } from 'react';

const MathJaxProblem = ({ problem }) => {
  useEffect(() => {
    if (window.MathJax) {
      // Typeset all math in the document
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
    }
  }, [problem]); // Rerender the component when 'problem' changes

  return (
    <div>
      <span dangerouslySetInnerHTML={{ __html: problem }} />
    </div>
  );
};

export default MathJaxProblem;
