import React, { useState, useEffect } from 'react';
import './TermsAndPrivacy.css'; // Assuming you have a corresponding CSS file
import { getAuth } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase'; // Update the import path as needed
import Header from '../../componnts/Header/Header';
import Footer from '../../componnts/Footer/Footer';
import PageTracker from '../../componnts/PageTracker/PageTracker';

const TermsAndPrivacy: React.FC = () => {
  return (
    <div className="terms-container">
      <PageTracker />
      <Header />

      <div className="content">
        <h1 className="terms-title">Terms of Service and Privacy Policy</h1>

        <section className="terms-of-service">
          <h2>Terms of Service</h2>
          <h3>1. Introduction</h3>
          <p>Welcome to our app, designed to help children earn their allowance by solving math problems, while giving parents a seamless way to manage and reward their efforts. By using our app, you agree to these Terms of Service.</p>

          <h3>2. Subscription and Fees</h3>
          <ul>
            <li className="terms-bullets">We offer two subscription tiers: $10 and $15 per month.</li>
            <li className="terms-bullets">A flat service fee of $1 is deducted from each subscription payment.</li>
            <li className="terms-bullets">Payment processing is handled by Stripe, which applies a transaction fee of 2.9% + $0.30.</li>
            <li className="terms-bullets">The remaining balance after fees is allocated for children to earn as an allowance.</li>
          </ul>

          <h3>3. Earning and Redeeming Allowance</h3>
          <ul>
            <li className="terms-bullets">Children earn allowance by successfully solving math problems within the app.</li>
            <li className="terms-bullets">Accumulated allowance can be redeemed for gift cards through Tango Card.</li>
          </ul>

          <h3>4. Account Inactivity and Termination</h3>
          <ul>
            <li className="terms-bullets">Accounts with no activity for 12 months are deemed inactive and subject to deletion. Any remaining balance becomes company revenue.</li>
            <li className="terms-bullets">Accounts inactive for over 9 months will receive a warning email prior to reaching the 12-month mark.</li>
            <li className="terms-bullets">Users have the option to disable (temporarily deactivate) or delete (permanently remove) their accounts.</li>
            <li className="terms-bullets">Disabling an account preserves data and balances, but the account may still be considered inactive.</li>
            <li className="terms-bullets">Deleting an account removes all data, including payment information and any unclaimed balance, which cannot be recovered.</li>
          </ul>

          <h3>5. User Responsibilities</h3>
          <ul>
            <li className="terms-bullets">Users are responsible for maintaining the confidentiality of their account information.</li>
            <li className="terms-bullets">Parents are responsible for monitoring their children's use of the app.</li>
          </ul>

          <h3>6. Modifications to Terms</h3>
          <ul>
            <li className="terms-bullets">We reserve the right to modify these terms at any time. Users will be notified of significant changes.</li>
          </ul>
        </section>

        <section className="privacy-policy">
          <h2>Privacy Policy</h2>
          <h3>1. Data Collection</h3>
          <ul>
            <li className="terms-bullets">We collect personal information necessary to create an account, such as name, email, and payment details.</li>
            <li className="terms-bullets">Children's accounts require parental consent and collect minimal data, focusing on progress and earnings within the app.</li>
          </ul>

          <h3>2. Data Use</h3>
          <ul>
            <li className="terms-bullets">Personal information is used to manage accounts, process payments, and communicate with users.</li>
            <li className="terms-bullets">We do not share personal information with third parties, except as necessary for payment processing and reward redemption.</li>
          </ul>

          <h3>3. Data Protection</h3>
          <ul>
            <li className="terms-bullets">We employ industry-standard security measures to protect user data.</li>
            <li className="terms-bullets">Users have the right to access, modify, or delete their personal information.</li>
          </ul>

          <h3>4. Third-Party Services</h3>
          <ul>
            <li className="terms-bullets">Our app integrates with Stripe for payment processing and Tango Card for reward redemption. We encourage users to review their privacy policies.</li>
          </ul>

          <h3>5. Changes to Privacy Policy</h3>
          <ul>
            <li className="terms-bullets">We may update our Privacy Policy to reflect changes in our practices. Users will be notified of significant changes.</li>
          </ul>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default TermsAndPrivacy;