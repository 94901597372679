import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import { getAuth, updateCurrentUser, onAuthStateChanged } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import {functions, db, auth} from '../../firebase'
import { doc, getDoc, updateDoc, runTransaction, LoadBundleTask } from 'firebase/firestore';
import { AuthContext } from '../../contexts/AuthContext';
import './ProfileScreen.css'; // Ensure correct path for CSS
import Header from '../../componnts/Header/Header';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { PieChart, Pie, Cell, Legend, Tooltip, Text } from 'recharts';
import PageTracker from '../../componnts/PageTracker/PageTracker';



interface UserStat {
  correctAnswers: number;
  totalAttempts: number;
}
interface UserAchievement {
  achievementLevel: number;
}
interface StatsByDifficulty {
  [grade: string]: UserStat;
}

interface AchievementsByDifficulty {
  [grade: string]: UserAchievement;
}
interface UserProfile {
  firstName?: string;
  lastName?: string;
  email?: string;
  avatar?: string;
  username?: string;
  statsByDifficulty: StatsByDifficulty;
  achievementsByDifficulty: AchievementsByDifficulty;
}

interface GetUserResponse {
  userData: UserProfile;
}


interface OverallStats extends UserStat {} // If it's the same structure, extend UserStat

interface StatsResponse {
  grandTotalStats: OverallStats;
  difficultyStats?: UserStat;
}



const ProfileScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserProfile | null>(null);
  const [overallStats, setOverallStats] = useState<OverallStats>({ correctAnswers: 0, totalAttempts: 0 });
  // const [specificStats, setSpecificStats] = useState<UserStat | null>(null);
  // const [selectedDifficulty, setSelectedDifficulty] = useState<string>('1');
  const [avatarURI, setAvatarURI] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [statsByDifficulty, setStatsByDifficulty] = useState<StatsByDifficulty>({});
  const [achievementsByDifficulty, setAchievementsByDifficulty] = useState<AchievementsByDifficulty>({});
  const { user: authUser, initializing } = useContext(AuthContext) ?? { user: null, initializing: true };
  const isVerified = authUser?.emailVerified || false; // Directly checking if authUser is verified
  const navigate = useNavigate();
  const auth = getAuth(); // get Firebase auth instance
  
  const badgeUrls: { [key: string]: string } = {
    '0': '/badge0.png',
    '1': '/badge1.png',
    '2': '/badge2.png',
    '3': '/badge3.png',
    '4': '/badge4.png',
    '5': '/badge5.png',
  };

  const badgeTitles: { [key: string]: string } = {
    '0': 'Newbie',
    '1': 'Genius',
    '2': 'Master',
    '3': 'Superstar',
    '4': 'Legend',
    '5': 'Immortal',
  };

  const handleAvatarChangeClick = () => {
    if (!isVerified) {
      // Redirect to verify screen or show a message that the user needs to verify their email
      alert('You need to verify your email before changing your avatar.');
      navigate('/verify'); // Assuming '/verify' is your verification screen's route
      return;
    }
    navigateTo('/accountupdate', 'change_avatar');
  };

  // Fetch user profile and stats together
  const fetchData = async () => {
    setLoading(true); // Start loading

    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdToken();

        // Fetch user profile
        const getUser = httpsCallable<unknown, GetUserResponse>(functions, 'getUser');
        const userResult = await getUser({ idToken });
        const userData = userResult.data.userData;
        setUser(userData);
        setStatsByDifficulty(userData.statsByDifficulty);
        setAchievementsByDifficulty(userData.achievementsByDifficulty);

        // Fetch grand total stats
        const getUserGrandTotalStats = httpsCallable<{ idToken: string }, { grandTotalStats: OverallStats }>(
          functions,
          'getUserGrandTotalStats'
        );
        const grandTotalStatsResponse = await getUserGrandTotalStats({ idToken });
        setOverallStats(grandTotalStatsResponse.data.grandTotalStats);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Stop loading after all data is fetched
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser:any) => {
      if (!currentUser) {
        console.error("You must be logged in to view the Profile");
        navigate('/login'); // Redirect to login page if not signed in
      } else {
        fetchData(); // Only fetch data if the user is signed in
      }
    });

    return () => unsubscribe(); // Cleanup subscription
  }, [navigate]);

  // useEffect(() => {
  //   if (selectedDifficulty) {
  //     fetchUserStatsByDifficulty(selectedDifficulty);
  //   }
  // }, [selectedDifficulty]);

  const navigateTo = (path: string, actionType: string) => {
    navigate(path, { state: { actionType } }); // Pass actionType in the state
};

interface GradeProps {
  correctAnswers: number;
  totalAttempts: number;
}

const GradePieChart = ({ correctAnswers, totalAttempts }: GradeProps) => {
  // Check if totalAttempts is zero to handle "No Data" case
  const hasData = totalAttempts > 0;
  const percentage = hasData ? (correctAnswers / totalAttempts) * 100 : 0;

  const data = hasData
    ? [
        { name: `${correctAnswers} Solved`, value: correctAnswers },
        { name: `${totalAttempts - correctAnswers} Incorrect`, value: totalAttempts - correctAnswers },
      ]
    : [
        { name: 'No Data', value: 1 }, // Placeholder data for no attempts
      ];
  
  const COLORS = hasData ? ['#3D9970', '#8AB4F8'] : ['#D3D3D3'];

  return (
    <PieChart width={220} height={200}>
      <Pie
        data={data}
        cx={110}
        cy={100}
        startAngle={180}
        endAngle={0}
        innerRadius={70}
        outerRadius={90}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <text
                x={115} // Center horizontally
                y={60} // Adjust this to position the text inside the arch
                textAnchor="middle" 
                dominantBaseline="hanging" // Aligns text to the top
                className="progress-text" // Use this class for styling
              >
                {hasData ? `${percentage.toFixed(0)}%` : 'No'}
              </text>
              <text
                x={115} // Center horizontally
                y={90} // Adjust this to position the text inside the arch
                textAnchor="middle" 
                dominantBaseline="hanging" // Aligns text to the top
                className="progress-text" // Use this class for styling
              >
                {hasData ? `Accuracy` : 'Attempts'}
              </text>
      <Legend />
    </PieChart>
  );
};

const hasOverallData = overallStats.totalAttempts > 0;
const overallPercentage = hasOverallData ? (overallStats.correctAnswers / overallStats.totalAttempts) * 100 : 0;

const overallData = hasOverallData
  ? [
      { name: `${overallStats.correctAnswers} Solved`, value: overallStats.correctAnswers },
      { name: `${overallStats.totalAttempts - overallStats.correctAnswers} Incorrect`, value: overallStats.totalAttempts - overallStats.correctAnswers },
    ]
  : [{ name: 'No Data', value: 1 }]; // Placeholder data for no attempts

const overallColors = hasOverallData ? ['#3D9970', '#8AB4F8'] : ['#D3D3D3'];

  if (loading) {
    return <LoadingScreen/>; // Replace with your loader component
  }

  return (
    <div className="profile-container">
      <PageTracker />
      <Header></Header>
      <div className="profile-top-container">
        <h1 className="profile-title">Profile</h1> 
        <div className="profile-highlights">
          <img src={user?.avatar} alt="Avatar" className="profile-avatar" />
          <button className="settings-link" onClick={handleAvatarChangeClick}>
                        <span>Change Avatar</span>
                    </button> 
          <span className="profile-username">{user?.username}</span>
          <div className="main-stats-container">
            <span className="main-stats-title">Total Stats</span>
            {/* <span className="main-stats">Total Problems Solved: <div>{overallStats.correctAnswers}</div></span>
            <span className="main-stats">Total Problems Attempted: <div>{overallStats.totalAttempts}</div></span> */}
            {/* <span className="main-stats">Accuracy: <div>{((overallStats.correctAnswers / overallStats.totalAttempts) * 100).toFixed(0)}%</div></span> */}
            {/* <CircularProgressbar value={percentage} text={`${percentage.toFixed(0)}%`} /> */}
            <PieChart width={220} height={200}>
              <Pie
                data={overallData}
                cx={100}
                cy={100}
                startAngle={180}
                endAngle={0}
                innerRadius={70}
                outerRadius={90}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {overallData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={overallColors[index % overallColors.length]} />
        ))}
              </Pie>
              <text
                x={105} // Center horizontally
                y={60} // Adjust this to position the text inside the arch
                textAnchor="middle" 
                dominantBaseline="hanging" // Aligns text to the top
                className="progress-text" // Use this class for styling
              >
                {hasOverallData ? `${overallPercentage.toFixed(0)}%` : 'No'}
              </text>
              <text
                x={105} // Center horizontally
                y={90} // Adjust this to position the text inside the arch
                textAnchor="middle" 
                dominantBaseline="hanging" // Aligns text to the top
                className="progress-text" // Use this class for styling
              >
                {hasOverallData ? `Accuracy` : 'Attempts'} 
              </text>
              <Tooltip />
              <Legend />
            </PieChart>

          </div>
        </div>

      </div>

      
     <div className="profile-bottom-container">
  <h1 className="profile-title">Stats by Grade</h1> 
  <div className="profile-difficulty-stats">
    {statsByDifficulty && Object.entries(statsByDifficulty).map(([grade, stats]) => (
      <div key={`grade-${grade}`} className="profile-grade-stats">
        <h3 className="profile-grade-title">Grade {grade}</h3>
       
        {achievementsByDifficulty[grade] && (
          <div className="achievement-display">
                <img src={badgeUrls[achievementsByDifficulty[grade].achievementLevel.toString()] || badgeUrls['0']} 
                     alt={badgeTitles[achievementsByDifficulty[grade].achievementLevel.toString()] || badgeTitles['0']} 
                     className="achievement-badge" />
                <span>{badgeTitles[achievementsByDifficulty[grade].achievementLevel.toString()] || badgeTitles['0']}</span>
              </div>
              
              )}
              <GradePieChart correctAnswers={stats.correctAnswers} totalAttempts={stats.totalAttempts} />
      </div>
    ))}
  </div>
</div>



    </div>
  );
};

export default ProfileScreen;