import './SubscriptionScreen.css';
import React, { useContext, useState, useEffect, useCallback  } from 'react';
import { useStripe, useElements, CardElement, PaymentElement  } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase'; // Adjust the import according to your setup
import { AuthContext } from '../../contexts/AuthContext'; // Adjust the import according to your setup
import { useNavigate } from 'react-router-dom';

import LoadingScreen from '../LoadingScreen/LoadingScreen';
import Header from '../../componnts/Header/Header';
import PageTracker from '../../componnts/PageTracker/PageTracker';

const stripePromise = loadStripe('your_stripe_public_key_here');

const SubscriptionScreen: React.FC = () => {
    const { user, initializing } = useContext(AuthContext) ?? { user: null, initializing: true };
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionActive, setSubscriptionActive] = useState<boolean>(false);
  const [defaultPaymentMethodActive, setDefaultPaymentMethodActive] = useState<boolean>(false);
  const [isAddingPayment, setIsAddingPayment] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanKey | null>(null);
  const [currentPlan, setCurrentPlan] = useState<PlanKey>('free');
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);
  


  type PlanKey = 'free' | 'casual' | 'max';

  const mapPlanNameToKey = (planName: string): PlanKey => {
    switch (planName.toLowerCase()) {
        case 'free':
            return 'free';
        case 'casual':
            return 'casual';
        case 'max':
            return 'max';
        default:
            return 'free'; // Default to 'free' or handle the error as needed
    }
};

  type Plan = {
    title: string;
    price: string;
    allowance: string;
    priceId: string | null;
  };
  
  type Plans = Record<PlanKey, Plan>;
  
  const plans: Plans = {
    free: {
      title: "Free",
      price: "Free",
      allowance: "No Allowance",
      priceId: null,
    },
    casual: {
      title: "Casual",
      price: "$10/Month",
      allowance: "Allowance after fees: $8.41",
      priceId: "price_1OcxNTKvTLpZISR2aFILWMKj",
    },
    max: {
      title: "Max",
      price: "$15/Month",
      allowance: "Allowance after fees: $13.27",
      priceId: "price_1OF7vZKvTLpZISR2wfY04gel",
    },
  };

  const getBorderColor = (planKey:any) => {
    switch (planKey) {
      case 'free':
        return '#3d9970'; // Color for the Free plan
      case 'casual':
        return '#233565'; // Color for the Casual plan
      case 'max':
        return '#df5959'; // Color for the Max plan
      default:
        return 'transparent';
    }
  };
  
  // ...other state hooks

  // Replace with your own implementation
  interface PaymentLinkResponse {
    url: string;
  }
  interface PaymentMethodResponseData {
    paymentMethodId: string;
    stripeCustomerId: string;
  }
  
  interface SetupIntentResponse {
    clientSecret: string;
  }

  interface SubscriptionResponse {
    subscriptionId: string;
  }

  interface FetchStatusResponseData {
    defaultPaymentMethodActive: boolean;
    subscriptionActive: boolean;
    planName: PlanKey;
  }

  const fetchUserStatus = useCallback(async () => {
    if (!user) return;

    try {
      setLoading(true);
      const idToken = await user.getIdToken(true);
      const fetchStatus = httpsCallable<unknown, FetchStatusResponseData>(functions, 'fetchStatus');
      const statusResult = await fetchStatus({ idToken });
      setDefaultPaymentMethodActive(statusResult.data.defaultPaymentMethodActive);
      setSubscriptionActive(statusResult.data.subscriptionActive);
      setCurrentPlan(mapPlanNameToKey(statusResult.data.planName || 'free'));
    } catch (error) {
      console.error('Error fetching user status:', error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUserStatus();
    
  }, [fetchUserStatus]);

  const handleConfirmSubscription = async () => {
    if (!user || selectedPlan === null) {
      console.log('User must be logged in and a plan must be selected to confirm a subscription.');
      return;
    }
    setLoading(true);
    // Logic to handle confirming a subscription
    // This could involve calling a cloud function to create a subscription
    // For example, using a cloud function to create a subscription
    try {
      const idToken = await user.getIdToken(true);
  
      if (selectedPlan === 'free') {
        // Logic for switching to the Free plan
        if (subscriptionActive) {
          const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
          await cancelSubscription({ idToken });
          console.log('Subscription cancelled, switched to Free plan');
          setSubscriptionActive(false);
        }
        setCurrentPlan('free');
      } else {
        // Logic for subscribing to a paid plan
        const createStripePaymentLink = httpsCallable<unknown, PaymentLinkResponse>(functions, 'createStripePaymentLink');
        const linkResult = await createStripePaymentLink({
          idToken,
          email: user.email,
          name: user.displayName,
          priceId: plans[selectedPlan]?.priceId,
          planName: plans[selectedPlan]?.title
        });
        window.location.href = linkResult.data.url;
        setCurrentPlan(selectedPlan);
      }
    } catch (error) {
      console.error('Error creating payment link:', error);
      // ... handle errors
    } finally {
      updatePaymentMethod();
      setLoading(false);
    }
  };

  const updatePaymentMethod = async () => {
    if (!user) {
      console.log('User must be logged in to confirm a subscription.');
      return;
    }
    setLoading(true);
    // Logic to handle confirming a subscription
    // This could involve calling a cloud function to create a subscription
    // For example, using a cloud function to create a subscription
    try {
      const idToken = await user.getIdToken(true);
      const getCustomerAndPaymentMethod = httpsCallable<
            unknown,
            PaymentMethodResponseData
          >(functions, 'getCustomerAndPaymentMethod');
          const paymentMethodResponse = await getCustomerAndPaymentMethod({
            idToken,
          });
  
          const paymentMethodId = paymentMethodResponse.data.paymentMethodId;
          const customerId = paymentMethodResponse.data.stripeCustomerId;
  
          // Now call setDefaultPaymentMethod
          const setDefaultPaymentMethod = httpsCallable(
            functions,
            'setDefaultPaymentMethod',
          );
          await setDefaultPaymentMethod({customerId, paymentMethodId});
    } catch (error) {
      console.error('Error creating payment link:', error);
      // ... handle errors
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!user) {
      console.log('User must be logged in to cancel a subscription.');
      return;
    }
    setLoading(true);
    // Logic to handle cancelling a subscription
    // This could involve calling a cloud function to cancel a subscription
    // For example, using a cloud function to cancel a subscription
    try {
      const idToken = await user.getIdToken(true);
      const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
      await cancelSubscription({ idToken });
      // console.log('Subscription cancelled');
      setSubscriptionActive(false);
      // ... update state and UI
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      // ... handle errors
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPlan = (planKey: PlanKey) => {
    setSelectedPlan(planKey);
  };
  

  if (loading) {
    return <LoadingScreen/>; // Replace with a loading spinner if desired
  }

  return (
    <div className="subscription-screen-container">
      <PageTracker />
      <Header/>

      <div className="spikes welcome-subscription">

      <div 
          className={`subscription-info-container ${selectedPlan === 'free' ? 'selected-plan' : ''} ${currentPlan === 'free' ? 'active-plan' : ''}`}
          onClick={() => currentPlan !== 'free' && handleSelectPlan('free')}
          style={{ 
            border: selectedPlan === 'free' || currentPlan === 'free' ? `2px solid ${getBorderColor('free')}` : ''
          }}
        >
          <p className="subscription-info-title-free">Free</p>
          
          <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Play on any device</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Unlimited Math Problems</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>10 Free Answer Reveals (one-time)</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Purchase Additional Reveals</p>
                            <p className="welcome-subscription-info-label1"><span className="red-checkmark">✗</span>No Monthly Allowance</p>
                            <p className="welcome-subscription-info-label1"><span className="red-checkmark">✗</span>Cannot Spend Earnings</p>
          <p className="subscription-info-price">Free</p>
          <p className="subscription-info-label3">(Free will cancel any existing plan)</p>
          
          <div className="subscription-button-container">
          <button 
            className={`subscription-button-free ${selectedPlan === 'free' || currentPlan === 'free' ? 'selected-button' : ''}`}
            style={{ backgroundColor: selectedPlan === 'free' || currentPlan === 'free' ? getBorderColor('free') : 'white', color: selectedPlan === 'free' || currentPlan === 'free' ? 'white' : 'initial' }}
            disabled={currentPlan === 'free'}
          >
            {currentPlan === 'free' ? '✓ Active' : selectedPlan === 'free' ? '✓ Selected' : 'Select'}
          </button>


          </div>
        </div>

        <div 
          className={`subscription-info-container ${selectedPlan === 'casual' ? 'selected-plan' : ''} ${currentPlan === 'casual' ? 'active-plan' : ''}`}
          onClick={() => currentPlan !== 'casual' && handleSelectPlan('casual')}
          style={{ 
            border: selectedPlan === 'casual' || currentPlan === 'casual' ? `2px solid ${getBorderColor('casual')}` : ''
          }}
        >
          <p className="subscription-info-title-casual">Casual</p>
          
          <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Play on any device</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Unlimited Math Problems</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>10 Daily Free Answer Reveals</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Purchase Additional Reveals</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Monthly Allowance</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Spend Earnings</p>
          <p className="subscription-info-price">$10/Month</p>
          <p className="subscription-info-label2"><span className="green-checkmark">✓</span>Allowance after fees:<span className="green-price">$8.41</span></p>
          
          <div className="subscription-button-container">
          <button 
            className={`subscription-button-casual ${selectedPlan === 'casual' || currentPlan === 'casual' ? 'selected-button' : ''}`}
            style={{ backgroundColor: selectedPlan === 'casual' || currentPlan === 'casual' ? getBorderColor('casual') : 'white', color: selectedPlan === 'casual' || currentPlan === 'casual' ? 'white' : 'initial' }}
            disabled={currentPlan === 'casual'}
          >
            {currentPlan === 'casual' ? '✓ Active' : selectedPlan === 'casual' ? '✓ Selected' : 'Select'}
          </button>




          </div>
        </div>

        <div 
          className={`subscription-info-container ${selectedPlan === 'max' ? 'selected-plan' : ''} ${currentPlan === 'max' ? 'active-plan' : ''}`}
          onClick={() => currentPlan !== 'max' && handleSelectPlan('max')}
          style={{ 
            border: selectedPlan === 'max' || currentPlan === 'max' ? `2px solid ${getBorderColor('max')}` : ''
          }}
        >
          <p className="subscription-info-title-max">Max</p>
          
          <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Play on any device</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Unlimited Math Problems</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>10 Daily Free Answer Reveals</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Purchase Additional Reveals</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Monthly Allowance</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Spend Earnings</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Best Value</p>
            <p className="subscription-info-price">$15/Month</p>
            <p className="subscription-info-label2"><span className="green-checkmark">✓</span>Allowance after fees:<span className="green-price">$13.27</span></p>
        
          <div className="subscription-button-container">
          <button 
            className={`subscription-button-max ${selectedPlan === 'max' || currentPlan === 'max' ? 'selected-button' : ''}`}
            style={{ backgroundColor: selectedPlan === 'max' || currentPlan === 'max' ? getBorderColor('max') : 'white', color: selectedPlan === 'max' || currentPlan === 'max' ? 'white' : 'initial' }}
            disabled={currentPlan === 'max'}
          >
            {currentPlan === 'max' ? '✓ Active' : selectedPlan === 'max' ? '✓ Selected' : 'Select'}
          </button>



          </div>
        </div>
        
      </div>
        <button 
          className="subscription-submit-button"
          onClick={handleConfirmSubscription} 
          disabled={loading || !selectedPlan}
        >
          Confirm
        </button>
    </div>
  );
};

export default SubscriptionScreen;

{/* <h1>$15/Month</h1>
      <p>Subscription Active: {subscriptionActive ? 'Yes' : 'No'}</p>
      {!subscriptionActive && (
        <button onClick={handleConfirmSubscription} disabled={loading}>
          Subscribe
        </button>
      )}
      {subscriptionActive && (
        <button onClick={handleCancelSubscription} disabled={loading}>
          Cancel Subscription
        </button>
      )} */}