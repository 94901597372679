import React, { useState, useEffect, useContext } from 'react';
import { db, auth } from '../../firebase'; // Ensure these are correctly imported
import { doc, getDoc } from 'firebase/firestore';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import {functions} from '../../firebase'
import './InsightsScreen.css';
import Header from '../../componnts/Header/Header';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip as ChartJsTooltip, Legend as ChartJsLegend, ArcElement } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { PieChart, Pie as RechartsPie, Cell, Legend as RechartsLegend, Tooltip as RechartsTooltip, Text } from 'recharts';
import PageTracker from '../../componnts/PageTracker/PageTracker';


interface GradeProps {
  correctAnswers: number;
  totalAttempts: number;
}

const GradePieChart = ({ correctAnswers, totalAttempts }: GradeProps) => {
  // Check if totalAttempts is zero to handle "No Data" case
  const hasData = totalAttempts > 0;
  const percentage = hasData ? (correctAnswers / totalAttempts) * 100 : 0;

  const data = hasData
    ? [
        { name: `${correctAnswers} Solved`, value: correctAnswers },
        { name: `${totalAttempts - correctAnswers} Incorrect`, value: totalAttempts - correctAnswers },
      ]
    : [
        { name: 'No Data', value: 1 }, // Placeholder data for no attempts
      ];
  
  const COLORS = hasData ? ['#3D9970', '#8AB4F8'] : ['#D3D3D3'];

  return (
    <PieChart width={220} height={200}>
      <RechartsPie
        data={data}
        cx={110}
        cy={100}
        startAngle={180}
        endAngle={0}
        innerRadius={70}
        outerRadius={90}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </RechartsPie>
      <text
                x={115} // Center horizontally
                y={60} // Adjust this to position the text inside the arch
                textAnchor="middle" 
                dominantBaseline="hanging" // Aligns text to the top
                className="progress-text" // Use this class for styling
              >
                {hasData ? `${percentage.toFixed(0)}%` : 'No'}
              </text>
              <text
                x={115} // Center horizontally
                y={90} // Adjust this to position the text inside the arch
                textAnchor="middle" 
                dominantBaseline="hanging" // Aligns text to the top
                className="progress-text" // Use this class for styling
              >
                {hasData ? `Accuracy` : 'Attempts'}
              </text>
      <RechartsLegend />
    </PieChart>
  );
};


// Register Chart.js components
Chart.register(CategoryScale, LinearScale, BarElement, Title, ChartJsTooltip, ChartJsLegend, ArcElement);

// Interfaces
interface ProgressBarProps {
  progress: number;
  label: string;
}

interface ChartDataStructure {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor?: string;
    borderWidth?: number;
  }[];
}

interface PieChartData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
  }[];
}

interface Mastery {
  [level: string]: number;
}

interface CategoriesByDifficulty {
  [key: string]: string[];
}

interface UserData {
  statsByCategory: {
    [category: string]: {
      correctAnswers: number;
      totalAttempts: number;
    };
  };
  statsByDifficulty?: {
    [level: string]: {
      correctAnswers: number;
      totalAttempts: number;
    };
  };
}

// ProgressBar component
const ProgressBar: React.FC<ProgressBarProps> = ({ progress, label }) => (
  <div className="progress-container">
    <div className="progress-label">{label}</div>
    <div className="progress-bar-outer">
      <div className="progress-bar-inner" style={{ width: `${progress}%` }}>
        {progress.toFixed(0)}%
      </div>
    </div>
  </div>
);


interface UserStat {
  correctAnswers: number;
  totalAttempts: number;
}
interface OverallStats extends UserStat {}



// InsightsScreen component
const InsightsScreen: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedGrade, setSelectedGrade] = useState<string>('1');
  const [userData, setUserData] = useState<UserData | null>(null);
  const [overallStats, setOverallStats] = useState<OverallStats>({ correctAnswers: 0, totalAttempts: 0 });
  const [overallCorrect, setOverallCorrect] = useState<number>(0);
  const [overallAttempts, setOverallAttempts] = useState<number>(0);
  const [overallAccuracy, setOverallAccuracy] = useState<number>(0);
  const [overallStrengths, setOverallStrengths] = useState<string[]>([]);
  const [overallWeaknesses, setOverallWeaknesses] = useState<string[]>([]);
  const [statsByDifficulty, setStatsByDifficulty] = useState({ correctAnswers: 0, totalAttempts: 0 });
  const [gradeData, setGradeData] = useState<{
    correct: number;
    attempts: number;
    accuracy: number;
    strengths: string[];
    weaknesses: string[];
  } | null>(null);
  const [chartsData, setChartsData] = useState<ChartDataStructure | null>(null);
  const [favoriteCategoryData, setFavoriteCategoryData] = useState<PieChartData | null>(null);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const categoriesByDifficulty: CategoriesByDifficulty = {
    '1': ['1st Grade Addition', '1st Grade Subtraction'],
    '2': ['2nd Grade Addition', '2nd Grade Subtraction', '2nd Grade Multiplication'],
    '3': ['3rd Grade Addition', '3rd Grade Subtraction', '3rd Grade Multiplication', '3rd Grade Division'],
    '4': ['4th Grade Addition', '4th Grade Subtraction', '4th Grade Multiplication', '4th Grade Division', 'Decimals'],
    '5': ['Fraction Addition', 'Fraction Subtraction',],
    '6': ['Fraction Addition', 'Fraction Subtraction', 'Fraction Multiplication', 'Fraction Division' ],
    '7': ['Percentages', 'Mean', 'Median', 'Probability'],
    '8': ['Exponents', 'Multiply Powers', 'Divide Powers', 'Square Roots'],
    '9': ['Basic Linear','Basic Quadratic','Basic Inequality','Basic System',],
    '10': ['Angle of Triangle', 'Perimeter', 'Area', 'Volume', 'Pythagorean Theorem', 'Surface Area', 'Circumference'],
    '11': ['Complex Linear','Complex Quadratic','Complex Inequality','Complex System','Complex SimplifyExpression','Complex Function Evaluation','Complex Polynomial Operations','Complex Rational','Complex LogFunction','Complex SequenceAndSeries'],
    '12': ["Function Evaluation", "Exponential Functions", "Trigonometric Identities", "Limits", "Derivatives", "Integrals"],
  };

  const PIE_CHART_COLORS = [
    '#FF6384', // Red
    '#36A2EB', // Blue
    '#FFCE56', // Yellow
    '#4BC0C0', // Teal
    '#9966FF', // Purple
    '#FF9F40', // Orange
    '#C9CBCF', // Grey
    '#EA80FC', // Light Purple
    '#00BFA5', // Sea Green
    '#1DE9B6', // Aqua Green
    '#00B0FF', // Sky Blue
    '#2962FF', // Bright Blue
    '#6200EA', // Indigo
    '#D500F9', // Vivid Purple
    '#FF6D00', // Bright Orange
    '#DD2C00', // Deep Orange
    '#64DD17', // Lime Green
    '#AEEA00', // Bright Lime
    '#FFD600', // Amber
    '#FFAB00', // Golden
    '#6D4C41', // Brown
    '#546E7A', // Blue Grey
    '#455A64', // Dark Blue Grey
    '#37474F', // Charcoal
    '#263238', // Dark Charcoal
    '#212121', // Blackish Grey
    '#F5F5F5', // Light Grey
    '#BDBDBD', // Medium Grey
    '#757575', // Grey
    '#616161', // Dark Grey
    '#FF1744', // Bright Red
    '#F50057', // Pink
    '#D81B60', // Magenta
    '#8E24AA', // Purple Magenta
    '#5E35B1', // Deep Purple
    '#3949AB', // Dark Blue
    '#1E88E5', // Cobalt Blue
    '#039BE5', // Light Blue
    '#00ACC1', // Cyan
    '#00897B', // Teal Green
  ];
  
  

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);
  

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userRef = doc(db, 'Users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data() as UserData;
          setUserData(data);
          setLoading(false);
        }
        const currentUser = auth.currentUser;
        if (currentUser) {
          const idToken = await currentUser.getIdToken();

          // Fetch grand total stats
          const getUserGrandTotalStats = httpsCallable<{ idToken: string }, { grandTotalStats: OverallStats }>(
            functions,
            'getUserGrandTotalStats'
          );
          const grandTotalStatsResponse = await getUserGrandTotalStats({ idToken });
          setOverallStats(grandTotalStatsResponse.data.grandTotalStats);
        }
      }
    };
    fetchUserData();
  }, [user]);

  useEffect(() => {
    if (userData) {
      let totalCorrect = 0;
      let totalAttempts = 0;
      let categoryDetails: { [key: string]: { correctAnswers: number; totalAttempts: number; accuracy: number } } = {};
  
      Object.entries(userData.statsByCategory).forEach(([category, stats]) => {
        totalCorrect += stats.correctAnswers;
        totalAttempts += stats.totalAttempts;
        const accuracy = stats.totalAttempts > 0 ? (stats.correctAnswers / stats.totalAttempts) * 100 : 0;
        categoryDetails[category] = { correctAnswers: stats.correctAnswers, totalAttempts: stats.totalAttempts, accuracy };
      });
  
      setOverallCorrect(totalCorrect);
      setOverallAttempts(totalAttempts);
      setOverallAccuracy(totalAttempts > 0 ? (totalCorrect / totalAttempts) * 100 : 0);
  
      // Define an accuracy threshold
    const accuracyThreshold = 50;

    // Determine strengths and weaknesses based on the threshold
    const strengths:any = [];
    const weaknesses:any = [];

    // Iterate over each category to classify it as a strength or weakness based on the threshold
    Object.entries(categoryDetails).forEach(([category, { accuracy }]) => {
      if (accuracy >= accuracyThreshold) {
        strengths.push(category);
      } else if (accuracy > 0) { // Only consider categories with attempts
        weaknesses.push(category);
      }
    });

    // Sort weaknesses by accuracy to get the worst at the top
    strengths.sort((a:any, b:any) => categoryDetails[b].accuracy - categoryDetails[a].accuracy);
    weaknesses.sort((a:any, b:any) => categoryDetails[a].accuracy - categoryDetails[b].accuracy);

    // Slice to only include worst three weaknesses
    const topStrengths = strengths.slice(0, 3);
    const worstWeaknesses = weaknesses.slice(0, 3);

    // Update state with strengths and worst three weaknesses
    setOverallStrengths(topStrengths);
    setOverallWeaknesses(worstWeaknesses);
  }
  }, [userData]);
  
  interface CategoryDetail {
    correctAnswers: number;
    totalAttempts: number;
    accuracy: number;
  }
  
  interface CategoryDetails {
    [category: string]: CategoryDetail;
  }

  useEffect(() => {
    if (userData && selectedGrade) {
      const categories = categoriesByDifficulty[selectedGrade] || [];
      let gradeCorrect = 0;
      let gradeAttempts = 0;
      let categoryDetails: CategoryDetails = {};
  
      // Compute correct answers and accuracy for each category
      categories.forEach(category => {
        const stats = userData.statsByCategory[category];
        if (stats) {
          gradeCorrect += stats.correctAnswers;
          gradeAttempts += stats.totalAttempts;
          const accuracy = stats.totalAttempts > 0 ? (stats.correctAnswers / stats.totalAttempts) * 100 : 0;
          categoryDetails[category] = { correctAnswers: stats.correctAnswers, totalAttempts: stats.totalAttempts, accuracy };
        }
      });
  
      const gradeAccuracy = gradeAttempts > 0 ? (gradeCorrect / gradeAttempts) * 100 : 0;
  
      // Define an accuracy threshold
    const accuracyThreshold = 50; // Example threshold

    // Sort categories by accuracy for strengths and weaknesses
    const sortedCategories = Object.entries(categoryDetails).sort((a, b) => b[1].accuracy - a[1].accuracy);

    // Get top three strengths
    const strengths = sortedCategories
      .filter(([_, details]) => details.accuracy >= accuracyThreshold)
      .slice(0, 3) // Only take top 3 categories
      .map(([category]) => category);

    // Get worst three weaknesses
    const weaknesses = sortedCategories
      .filter(([_, details]) => details.accuracy < accuracyThreshold)
      .slice(-3) // Only take bottom 3 categories
      .map(([category]) => category);

      // Check if statsByDifficulty exists and the selectedGrade is a key within it
    if (userData.statsByDifficulty && userData.statsByDifficulty[selectedGrade]) {
      const { correctAnswers, totalAttempts } = userData.statsByDifficulty[selectedGrade];
      
      // Update the state with stats by difficulty for the selected grade
      setStatsByDifficulty({
        correctAnswers: correctAnswers,
        totalAttempts: totalAttempts,
      });
    } else {
      // If there is no data for the selected grade, reset the stats
      setStatsByDifficulty({ correctAnswers: 0, totalAttempts: 0 });
    }

      
      setGradeData({
        correct: gradeCorrect,
        attempts: gradeAttempts,
        accuracy: gradeAccuracy,
        strengths: strengths,
        weaknesses: weaknesses,
      });
  
      // Update chart data
      const barChartData: ChartDataStructure = {
        labels: categories,
        datasets: [{
          label: 'Accuracy % by Category',
          data: categories.map(category => categoryDetails[category]?.accuracy || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        }],
      };
      setChartsData(barChartData);
  
      const pieChartData: PieChartData = {
        labels: categories,
        datasets: [{
          data: categories.map(category => categoryDetails[category]?.totalAttempts || 0),
          backgroundColor: categories.map((_, index) => PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]),
        }],
      };
      setFavoriteCategoryData(pieChartData);
    }
   
  }, [userData, selectedGrade]);
  

  const handleGradeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGrade(event.target.value);
  };

  if (loading) {
    return <div className="loader">Loading...</div>;
  }

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Add this to ignore the default aspect ratio
    aspectRatio: 1,
    scales: {
      y: {
        beginAtZero: true,
        type: 'linear' as const, // Ensuring type correctness
        title: {
          display: true,
          text: 'Accuracy %',
          color: '#D3E3FD'
        },
        ticks: {
          color: '#D3E3FD', // Change this to the desired font color for Y axis numbers
        },
        grid: {
          color: '#4F647E', // Y axis grid lines color
        },
        max: 100,
      },
      x: {
        type: 'category' as const, // Ensuring type correctness
        title: {
          display: true,
          text: 'Categories',
          color: '#D3E3FD'
        },
        ticks: {
          color: '#D3E3FD', // Change this to the desired font color for Y axis numbers
        },
        grid: {
          color: '#4F647E', // Y axis grid lines color
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top' as const, // Using 'as const' to ensure type correctness
        labels: {
          color: '#D3E3FD',
        },
      },
      title: {
        display: true,
        text: `Grade ${selectedGrade} Category Accuracy`,
        font: {
          size: 20, // Set the font size
          family: 'Arial', // Set the font family
        },
        color: '#D3E3FD', // Set the font color
        padding: 20,
      },
    },
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Add this to ignore the default aspect ratio
  aspectRatio: 1,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
        labels: {
          color: '#D3E3FD',
        },
      },
      title: {
        display: true,
        text: `Your Favorite Categories in Grade ${selectedGrade}`,
        font: {
          size: 20, // Set the font size
          family: 'Arial', // Set the font family
        },
        color: '#D3E3FD', // Set the font color
        padding: 20,
      },
    },
  };

  return (
    <div className="insights-container">
      <PageTracker />
      <Header />

      {/* Overall Performance Review */}
      <div className="overall-performance-container insights-spikes">
        <div className="insights-border-top">
        <h2 className="insights-title">Overall Performance Overview</h2>
        {/* <div className="stats-container">
          <span>Total Correct Answers: <div>{overallCorrect}</div></span>
          <span>Total Attempts: <div>{overallAttempts}</div></span>
          <span>Overall Accuracy: <div>{overallAccuracy.toFixed(2)}%</div></span>
        </div> */}
        <div className="progress-bar-container">
        <GradePieChart key={selectedGrade} correctAnswers={overallCorrect} totalAttempts={overallAttempts} />
          </div>
        <div className="strengths-weaknesses">
          <div className="strengths">
            <h3 className="insights-strengths-title">Overall Strengths</h3>
            <ul>{overallStrengths.map((strength) => <li key={strength}>{strength}</li>)}</ul>
          </div>
          <div className="weaknesses">
            <h3 className="insights-weaknesses-title">Overall Weaknesses</h3>
            <ul>{overallWeaknesses.map((weakness) => <li key={weakness}>{weakness}</li>)}</ul>
          </div>
        </div>
        <div className="insights-note">
        Note: Strengths reflect accuracies above 50%; weaknesses fall below this threshold.
        </div>
        </div>
      </div>

      

      <div className="grade-performance-container">
        <div className="insights-border-bottom">

      

      {/* Grade Specific Details */}
      {gradeData && (
        <div className="grade-specific-details">
          
        {/* Grade Selector */}
        <div className="grade-selector-container">
          <label className="insights-selector" htmlFor="grade-selector">Select Grade:</label>
          <select className="grade-selector" id="grade-selector" value={selectedGrade} onChange={handleGradeChange}>
            {Object.keys(categoriesByDifficulty).map((grade) => (
              <option key={grade} value={grade}>Grade {grade}</option>
            ))}
          </select>
        </div>

          <h2 className="insights-title">Grade {selectedGrade} Overview</h2>
          
          {/* <div className="stats-container">
            <span>Total Correct: <div>{gradeData.correct}</div></span>
            <span>Total Attempts: <div>{gradeData.attempts}</div></span>
            
            <span>Grade {selectedGrade} Accuracy: <div>{gradeData.accuracy.toFixed(2)}%</div></span>
          </div> */}
          <div className="progress-bar-container">
          <GradePieChart correctAnswers={statsByDifficulty.correctAnswers} totalAttempts={statsByDifficulty.totalAttempts} />
        </div>
          <div className="strengths-weaknesses">
            <div className="strengths">
              <h3 className="insights-strengths-title">Strengths</h3>
              <ul>{gradeData.strengths.map((strength) => <li key={strength}>{strength}</li>)}</ul>
            </div>
            <div className="weaknesses">
              <h3 className="insights-weaknesses-title">Weaknesses</h3>
              <ul>{gradeData.weaknesses.map((weakness) => <li key={weakness}>{weakness}</li>)}</ul>
            </div>
          </div>
          <div className="insights-note">
          Note: Strengths reflect accuracies above 50%; weaknesses fall below this threshold.
        </div>
          
        </div>
      )}

      {/* Charts */}
      {favoriteCategoryData && (
        <div className="pie-chart-container">
          
          <Pie data={favoriteCategoryData} options={pieChartOptions}  height={400} width={600}  />
          
        </div>
      )}
      {chartsData && (
        <div className="bar-chart-container">
          
          <Bar data={chartsData} options={barChartOptions} height={400} width={600}  />
          
        </div>
      )}
      
      </div>
      </div>
    </div>
  );
};

export default InsightsScreen;
