import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getAnalytics, logEvent } from "firebase/analytics";

function PageTracker() {
    const location = useLocation();
    const analytics = getAnalytics();

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_path: location.pathname,
            page_title: document.title,
            page_location: window.location.href
        });
    }, [location]);

    return null; // This component doesn't render anything
}

export default PageTracker;