import React, { useState, useEffect } from 'react';
import { createAvatar } from '@dicebear/core';
import { bottts } from '@dicebear/collection';
import './AvatarSelection.css';

type AvatarSelectionProps = {
  onAvatarSelect: (avatar: string) => void;
};

const AvatarSelection: React.FC<AvatarSelectionProps> = ({ onAvatarSelect }) => {
  const [avatarOptions, setAvatarOptions] = useState<string[]>([]);
  const [selectedAvatar, setSelectedAvatar] = useState<string>('');

  const generateRandomString = () => Math.random().toString(36).substring(2, 15);
  
  const generateRandomAvatar = (seed: string) => {
    return createAvatar(bottts, { seed }).toDataUriSync();
  };

  const generateAvatars = () => {
    return Array.from({ length: 12 }, () => generateRandomAvatar(generateRandomString()));
  };

  useEffect(() => {
    setAvatarOptions(generateAvatars());
  }, []); // Empty dependency array to ensure this runs only once on mount

  const handleGenerate = () => {
    setAvatarOptions(generateAvatars());
  };

  const handleSelect = (avatarUri: string) => {
    setSelectedAvatar(avatarUri); // Update local state
    onAvatarSelect(avatarUri); // Notify parent component
  };
  

  const isSelected = (avatarUri: string) => {
    return avatarUri === selectedAvatar;
  };

  return (
    <div className="change-avatar-container">
      <button className="generate-button" onClick={() => setAvatarOptions(generateAvatars())}>Generate</button>
      <div className="change-avatar-generated">
        {avatarOptions.map((avatarUri, index) => (
          <button 
            key={index} 
            className={`change-avatar-option ${isSelected(avatarUri) ? 'selected-avatar' : ''}`}
            onClick={() => handleSelect(avatarUri)}
          >
            <img src={avatarUri} className="change-avatar-image" alt="Avatar" />
          </button>
        ))}
      </div>
    </div>
  );
};

export default AvatarSelection;
