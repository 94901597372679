import React from 'react';
import { useState, useEffect } from 'react';
import './EarningsDisplay.css'; // Import the CSS file

const useAnimatedValue = (targetValue: any, duration = 500) => {
  const [displayValue, setDisplayValue] = useState(0); // Start from 0

  useEffect(() => {
    const startValue = displayValue;
    const endValue = targetValue;

    // No need to animate if the start and end values are the same
    if (startValue === endValue) return;

    const increment = (endValue - startValue) / (duration / 10); // Update every 10 ms
    let currentValue = startValue;

    const intervalId = setInterval(() => {
      currentValue += increment;
      if ((increment >= 0 && currentValue >= endValue) || (increment < 0 && currentValue <= endValue)) {
        currentValue = endValue; // Ensure we end exactly at endValue
        clearInterval(intervalId);
      }
      setDisplayValue(currentValue);
    }, 10);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [targetValue, duration]);

  return displayValue;
};


interface EarningsDisplayProps {
  earned: number;
  potential: number;
  redeemed: number;
}

const EarningsDisplay: React.FC<EarningsDisplayProps> = ({
  earned,
  potential,
  redeemed,
}) => {
  const animatedEarned = useAnimatedValue(earned);
  const animatedPotential = useAnimatedValue(potential);
  const animatedRemaining = useAnimatedValue(potential - earned);// Use the animated value for the earned balance
  const [isPulsing, setIsPulsing] = useState(false);

  useEffect(() => {
    setIsPulsing(true); // Start pulsing when earned value changes
    const timer = setTimeout(() => setIsPulsing(false), 1000); // Stop pulsing after 1 second
    return () => clearTimeout(timer);
  }, [earned]); // Depend on earned to trigger the effect

  return (
    <div className="balanceContainer">
      <span className="balanceText">Potential: ${animatedPotential.toFixed(2)}</span>
      <span className={`balanceText ${isPulsing ? 'pulsing' : ''}`}>
        Earned: ${animatedEarned.toFixed(2)}
      </span>
      <span className="balanceText">Remaining: ${animatedRemaining.toFixed(2)}</span>
    </div>
  );
};

export default EarningsDisplay;
