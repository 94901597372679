const TriangleSVG = () => (
  <div className="svg-container">
  <svg width="60" height="60" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <polygon points="50,15 100,100 0,100" style={{ fill: 'lime', stroke: 'purple', strokeWidth: 1 }} />
  </svg>
  </div>
);

const RectangleSVG = () => (
  <div className="svg-container">
  <svg width="120" height="80" viewBox="0 0 120 80" xmlns="http://www.w3.org/2000/svg">
      <rect width="120" height="80" style={{ fill: 'yellow', stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div>
);

const CircleSVG = () => (
  <div className="svg-container">
  <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="40" style={{ fill: 'blue', stroke: 'red', strokeWidth: 2 }} />
  </svg>
  </div>
);

const RectangularPrismSVG = () => (
  <div className="svg-container">
  <svg width="140" height="100" viewBox="0 0 140 100" xmlns="http://www.w3.org/2000/svg">
      <rect x="10" y="10" width="80" height="60" style={{ fill: 'none', stroke: 'green', strokeWidth: 2 }} />
      <line x1="10" y1="10" x2="40" y2="40" style={{ stroke: 'green', strokeWidth: 2 }} />
      <line x1="90" y1="10" x2="120" y2="40" style={{ stroke: 'green', strokeWidth: 2 }} />
      <rect x="40" y="40" width="80" height="60" style={{ fill: 'none', stroke: 'green', strokeWidth: 2 }} />
      <line x1="10" y1="70" x2="40" y2="100" style={{ stroke: 'green', strokeWidth: 2 }} />
      <line x1="90" y1="70" x2="120" y2="100" style={{ stroke: 'green', strokeWidth: 2 }} />
  </svg>
  </div>
);

const CubeSVG = () => (
  <div className="svg-container">
  <svg width="100%" height="100%" viewBox="0 0 75 75" xmlns="http://www.w3.org/2000/svg">
    <rect x="10" y="10" width="50" height="50" style={{ fill: 'none', stroke: 'orange', strokeWidth: 2 }} />
    <rect x="25" y="25" width="50" height="50" style={{ fill: 'none', stroke: 'orange', strokeWidth: 2 }} />
    <line x1="10" y1="10" x2="25" y2="25" style={{ stroke: 'orange', strokeWidth: 2 }} />
    <line x1="60" y1="10" x2="75" y2="25" style={{ stroke: 'orange', strokeWidth: 2 }} />
    <line x1="10" y1="60" x2="25" y2="75" style={{ stroke: 'orange', strokeWidth: 2 }} />
    <line x1="60" y1="60" x2="75" y2="75" style={{ stroke: 'orange', strokeWidth: 2 }} />
</svg>

  </div>
);

const RightAngledTriangleSVG = () => (
  <div className="svg-container">
  <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <polygon points="10,90 90,90 90,10" style={{ fill: 'lightblue', stroke: 'brown', strokeWidth: 2 }} />
  </svg>
  </div>
);

const CylinderSVG = () => (
  <div className="svg-container">
  <svg width="80" height="100" viewBox="0 0 100 120" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="50" cy="20" rx="40" ry="20" style={{ fill: 'none', stroke: 'violet', strokeWidth: 2 }} />
      <rect x="10" y="20" width="80" height="80" style={{ fill: 'none', stroke: 'violet', strokeWidth: 2 }} />
      <ellipse cx="50" cy="100" rx="40" ry="20" style={{ fill: 'none', stroke: 'violet', strokeWidth: 2 }} />
  </svg>
  </div>
);

const TrapezoidSVG = () => (
  <div className="svg-container">
  <svg width="120" height="80" viewBox="0 0 120 80" xmlns="http://www.w3.org/2000/svg">
      <polygon points="20,70 100,70 120,20 0,20" style={{ fill: 'lightgreen', stroke: 'darkblue', strokeWidth: 2 }} />
  </svg>
  </div>
);

const SphereSVG = () => (
  <div className="svg-container">
  <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <radialGradient id="sphereGradient" cx="50%" cy="50%" r="50%" fx="30%" fy="30%">
      <stop offset="0%" style={{stopColor: 'white', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: 'pink', stopOpacity: 1}} />
    </radialGradient>
  </defs>
  <circle cx="50" cy="50" r="40" fill="url(#sphereGradient)" stroke="pink" strokeWidth="2" />
</svg>

  </div>
);

export interface MathProblemAnswer {
  numerical: number;
  fractional: string;
}

export interface MathProblem {
  topic: string;
  question: string;
  question1: string;
  question2: string;
  answer: any;
  expectsFraction: boolean;
  inequalityOperation: string; 
  xValue: any;
  category: string[];
  shapeComponent: React.ReactNode;
}

const operationsByCategory: any = {
  '1st Grade Addition': ['+'],
  '1st Grade Subtraction': ['-'],
  '2nd Grade Addition': ['+'],
  '2nd Grade Subtraction': ['-'],
  '2nd Grade Multiplication': ['*'],
  '3rd Grade Addition': ['+'],
  '3rd Grade Subtraction': ['-'],
  '3rd Grade Multiplication': ['*'],
  '3rd Grade Division': ['/'],
  '4th Grade Addition': ['+'],
  '4th Grade Subtraction': ['-'],
  '4th Grade Multiplication': ['*'],
  '4th Grade Division': ['/'],
  // Addition: ['+'],
  // Subtraction: ['-'],
  // Multiplication: ['*'],
  // Division: ['/'],
  // Addition: ['+'],
  // Subtraction: ['-'],
  // Multiplication: ['*'],
  // Division: ['/'],
  // Fractions: ['/', '+', '-'], // Example operations for Fractions
  Decimals: ['+', '-', '*', '/'], // Example operations for Decimals
  ////////////////////////////////////////////////////////////////////////////////////////
  'Fraction Addition': ['fractionAddition'],
  'Fraction Subtraction': ['fractionSubtraction'],
  'Fraction Multiplication': ['fractionMultiplication'],
  'Fraction Division': ['fractionDivision'],
  ////////////////////////////////////////////////////////////////////////////////////////////
  'Percentages': ['percentages', 'whole', 'part'],
  'Mean': ['mean'],
  'Median': ['median'],
  'Probability': ['probability'],
  'Exponents': ['exponents'],
  'Multiply Powers': ['multiplyPowers'],
  'Divide Powers': ['dividePowers'],
  'Square Roots': ['squareRoots'],
  ///////////////////////////////////////////////////////////////////////////////////////////
  'Basic Linear': ['basicLinear'], // Previously under Algebra1
  'Basic Quadratic': ['basicQuadratic'], // Algebra1 and Algebra2
  'Basic Inequality': ['basicInequality'], // Algebra1
  'Basic SimplifyExpression': ['basicSimplifyExpression'], // Algebra1
  'Basic Systems of Equations': ['basicSystem'], // Algebra1
  'Basic Function': ['basicFunction'], // Algebra1
  'Basic Polynomial': ['basicPolynomial'], // Algebra2
  'Basic Rational': ['basicRational'], // Algebra2
  'Basic LogFunction': ['basicLogFunction'], // Algebra2
  'Basic SequenceAndSeries': ['basicSequenceAndSeries'], // Algebra2
  'Basic QuadraticInequality': ['basicQuadraticInequality'],
  'Complex Linear': ['complexLinear'], // Previously under Algebra1
  'Complex Quadratic': ['complexQuadratic'], // Algebra1 and Algebra2
  'Complex Inequality': ['complexInequality'], // Algebra1
  'Complex SimplifyExpression': ['complexSimplifyExpression'], // Algebra1
  'Complex Systems of Equations': ['complexSystem'], // Algebra1
  'Complex Function Evaluation': ['complexFunction'], // Algebra1
  'Complex Polynomial Operations': ['combinedPolyOperations'], // Algebra2
  'Complex Rational': ['complexRational'], // Algebra2
  'Complex LogFunction': ['complexLogFunction'], // Algebra2
  'Complex SequenceAndSeries': ['complexSequenceAndSeries'], // Algebra2
  //////////////////////////////////////////////////////////////////////////////////////////////
  'Angle of Triangle': ['angleOfTriangle'],
  'Perimeter': ['perimeterOfRectangle'],
  'Circumference': ['circumferenceOfCircle'],
  'Area': ['areaOfRectangle', 'areaOfCircle', 'areaOfTriangle', 'areaOfTrapezoid'],
  'Volume': ['volumeOfRectangularPrism', 'volumeOfCylinder', 'volumeOfSphere'],
  'Surface Area': ['surfaceAreaOfCube', 'surfaceAreaOfSphere'],
  'Pythagorean Theorem': ['pythagoreanTheorem'],
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  "Function Evaluation": ['functionEvaluation'],
    // "Polynomial Simplification": ['polynomialSimplification'],
    // "Logarithmic Functions": ['logarithmicFunctions'],
    "Exponential Functions": ['exponentialFunctions'],
    "Trigonometric Identities": ['trigonometricIdentities'],
    "Limits": ['limits'],
    "Derivatives": ['derivatives'],
    "Integrals": ['integrals'],
};

export const generateMathProblem = (
  // problemValue: number,
  difficulty: string,
  categories: string[]
): MathProblem => {
  let topic: string = '';
  let operation = '';
  let number1: number = 0;
  let number2: number = 0;
  let number3: number = 0;
  let number4: number = 0;
  let question = '';
  let question1 = '';
  let question2 = '';
  let answer: any;
  let shapeComponent: React.ReactNode;
  let xValue: any;
  let whole: any;
  let part: any;
  let percentage: any;
  let numbers: any;
  let base: any;
  let exponent: any;
  let exponent1: any;
  let exponent2: any;
  let perfectSquare: any;
  let nonPerfectSquare: any;
  let expectsFraction: boolean = false;
  let inequalityOperation: string = '';
  
  
  

  // Determine if decimals should be used
  const useDecimals = categories.includes('Decimals');
  const filteredCategories = categories.filter(cat => cat !== 'Decimals'); // Exclude 'Decimals' from category selection

  // Function to generate a random number (integer or decimal)
  const randomNumber = (min: number, max: number, allowDecimals: boolean) => {
    let number = Math.random() * (max - min) + min;
    return allowDecimals ? Math.round(number * 100) / 100 : Math.floor(number);
  };

  // Function to generate a simplified fraction
const generateFraction = () => {
  const numerator = randomNumber(1, 9, false); // Keep the numerator small
  const denominator = randomNumber(2, 9, false); // Avoid a denominator of 1 for non-trivial fractions
  return { numerator, denominator };
};


// Function to simplify a fraction
const simplifyFraction = (numerator: number, denominator: number): { numerator: number, denominator: number } => {
  const gcd = (a: number, b: number): number => b ? gcd(b, a % b) : a;
  const divisor = gcd(numerator, denominator);
  return {
    numerator: numerator / divisor,
    denominator: denominator / divisor
  };
};



  // Select a random category (excluding 'Decimals')
  let selectedCategory = filteredCategories[Math.floor(Math.random() * filteredCategories.length)];

  // Ensure selected category exists in operationsByCategory
  if (!operationsByCategory[selectedCategory]) {
    // console.error(`Category '${selectedCategory}' not found in operations`);
    selectedCategory = '1st Grade Addition'; // Default to 'Addition' if category is not found
  }

  // Select a random operation based on the selected category
  operation = operationsByCategory[selectedCategory][Math.floor(Math.random() * operationsByCategory[selectedCategory].length)];

  // Logic for number generation and operations
  switch (difficulty) {
    case '1':
      number1 = randomNumber(1, 10, useDecimals);
      number2 = randomNumber(1, 10, useDecimals);
      break;
    case '2':
      number1 = randomNumber(1, 20, useDecimals);
      number2 = randomNumber(1, 10, useDecimals);
      break;
    case '3':
      number1 = randomNumber(10, 100, useDecimals);
      number2 = randomNumber(10, 20, useDecimals);
      break;
    case '4':
      number1 = randomNumber(10, 1000, useDecimals);
      number2 = randomNumber(10, 100, useDecimals);
      break;
    case '5':
    case '6':
        number1 = randomNumber(10, 50, useDecimals);
        number2 = randomNumber(10, 50, useDecimals);
      break;
    case '7':
    case '8':
        number1 = randomNumber(100, 500, useDecimals);
        number2 = randomNumber(100, 500, useDecimals);
      break;
    case '9':  // For 9th grade
      number1 = randomNumber(1, 10, false);
      number2 = randomNumber(1, 20, false);
      break;
  case '10': // For 10th grade
      number1 = randomNumber(500, 1000, useDecimals);
      number2 = randomNumber(500, 1000, useDecimals);
      break;
  case '11': // For 11th grade
      number1 = randomNumber(1, 10, false);
      number2 = randomNumber(1, 20, false);
      number3 = randomNumber(1, 10, useDecimals);
      number4 = randomNumber(1, 10, useDecimals);
      break;
    case '12':
      number1 = randomNumber(1000, 5000, useDecimals);
      number2 = randomNumber(1000, 5000, useDecimals);
      break;
    default:
      throw new Error(`Unsupported difficultylevel: ${difficulty}`);
  }
    
// Generating the question and answer based on the operation
switch (operation) {
  case '+':
    topic = 'Addition';
    question = `${number1} + ${number2}`;
    answer = number1 + number2;
    break;
  case '-':
    topic = 'Subtraction';
    question = `${number1} - ${number2}`;
    answer = number1 - number2;
    break;
  case '*':
    topic = 'Multiplication';
    question = `${number1} * ${number2}`;
    answer = number1 * number2;
    break;
    case '/':
      topic = 'Division';
      number2 = Math.ceil(Math.random() * 9) + 1; // choose a divisor from 1 to 10
      const multiplier = Math.ceil(Math.random() * 10); // choose a multiplier to ensure a whole number
      number1 = number2 * multiplier; // number1 is now a multiple of number2
      question = `${number1} / ${number2}`;
      answer = number1 / number2; // this will always be a whole number
      break;
    
    case 'fractionAddition': {
      const fraction1Add = generateFraction();
      const fraction2Add = generateFraction();
      const commonDenominatorAdd = fraction1Add.denominator * fraction2Add.denominator;
      const sumNumerator = fraction1Add.numerator * (commonDenominatorAdd / fraction1Add.denominator) + fraction2Add.numerator * (commonDenominatorAdd / fraction2Add.denominator);
      const simplifiedAnswerAdd = simplifyFraction(sumNumerator, commonDenominatorAdd);
      answer = {
        fractional: `${simplifiedAnswerAdd.numerator}/${simplifiedAnswerAdd.denominator}`
      };
      question = `\\(\\frac{${fraction1Add.numerator}}{${fraction1Add.denominator}} + \\frac{${fraction2Add.numerator}}{${fraction2Add.denominator}}\\)`;
      topic = 'Fraction Addition';
      expectsFraction = true;
      
      break;
    }
    
    // Fraction Subtraction
case 'fractionSubtraction': {
  const fraction1Sub = generateFraction();
  const fraction2Sub = generateFraction();
  const commonDenominatorSub = fraction1Sub.denominator * fraction2Sub.denominator;
  const diffNumerator = fraction1Sub.numerator * (commonDenominatorSub / fraction1Sub.denominator) - fraction2Sub.numerator * (commonDenominatorSub / fraction2Sub.denominator);
  const simplifiedAnswerSub = simplifyFraction(diffNumerator, commonDenominatorSub);
  answer = {
    fractional: `${simplifiedAnswerSub.numerator}/${simplifiedAnswerSub.denominator}`
  };
  question = `\\(\\frac{${fraction1Sub.numerator}}{${fraction1Sub.denominator}} - \\frac{${fraction2Sub.numerator}}{${fraction2Sub.denominator}}\\)`;
  topic = 'Fraction Subtraction';
  expectsFraction = true;
  
  break;
}

// Fraction Multiplication
case 'fractionMultiplication': {
  const fraction1Mul = generateFraction();
  const fraction2Mul = generateFraction();
  const productNumerator = fraction1Mul.numerator * fraction2Mul.numerator;
  const productDenominator = fraction1Mul.denominator * fraction2Mul.denominator;
  const simplifiedAnswerMul = simplifyFraction(productNumerator, productDenominator);
  answer = {
    fractional: `${simplifiedAnswerMul.numerator}/${simplifiedAnswerMul.denominator}`
  };
  question = `\\(\\frac{${fraction1Mul.numerator}}{${fraction1Mul.denominator}} \\times \\frac{${fraction2Mul.numerator}}{${fraction2Mul.denominator}}\\)`;
  topic = 'Fraction Multiplication';
  expectsFraction = true;
  
  break;
}

// Fraction Division
case 'fractionDivision': {
  const fraction1Div = generateFraction();
  const fraction2Div = generateFraction();
  const quotientNumerator = fraction1Div.numerator * fraction2Div.denominator;
  const quotientDenominator = fraction1Div.denominator * fraction2Div.numerator;
  const simplifiedAnswerDiv = simplifyFraction(quotientNumerator, quotientDenominator);
  answer = {
    fractional: `${simplifiedAnswerDiv.numerator}/${simplifiedAnswerDiv.denominator}`
  };
  question = `\\(\\frac{${fraction1Div.numerator}}{${fraction1Div.denominator}} \\div \\frac{${fraction2Div.numerator}}{${fraction2Div.denominator}}\\)`;
  topic = 'Fraction Division';
  expectsFraction = true;
  
  break;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   
case 'percentages':
  topic = 'Find Percentage';
whole = Math.floor(Math.random() * 200) + 100; // Range from 100 to 300 for more variety
part = Math.floor(Math.random() * whole);
question = `Percentage of ${part} in ${whole}?`;
answer = (part / whole) * 100; // Calculate the percentage
break;  
case 'whole':
  topic = 'Find Whole';
// Case: Finding the Whole
percentage = Math.floor(Math.random() * 90) + 10; // Avoid 100% for more interesting problems, range 10% to 99%
part = Math.floor(Math.random() * 100) + 50; // Range from 50 to 150
question = `Find whole if ${part} is ${percentage}% of it.`;
answer = (part / percentage) * 100; // Calculate the whole

break; 
case 'part':
  topic = 'Find Part';
// Case: Finding the Part
whole = Math.floor(Math.random() * 500) + 200; // Larger whole for more complex problems, range 200 to 700
percentage = Math.floor(Math.random() * 90) + 10; // 10% to 99%
question = `What is ${percentage}\% of ${whole}?`;
answer = (whole * percentage) / 100; // Calculate the part

break; 
case 'mean':
  topic = 'Mean';
// Case: Mean Calculation
numbers = Array.from({ length: 5 }, () => Math.floor(Math.random() * 20) + 1); // 5 numbers, 1 to 20
question = `Mean of: ${numbers.join(', ')}`;
answer = numbers.reduce((acc:any, curr:any) => acc + curr, 0) / numbers.length; // Calculate mean

break; 
case 'median':
  topic = 'Median';
// Case: Median Finding
numbers = Array.from({ length: 5 }, () => Math.floor(Math.random() * 20) + 1).sort((a, b) => a - b); // 5 sorted numbers
let middleIndex = Math.floor(numbers.length / 2);
question = `Median of: ${numbers.join(', ')}`;
answer = numbers.length % 2 !== 0 ? numbers[middleIndex] : (numbers[middleIndex - 1] + numbers[middleIndex]) / 2; // Calculate median

break;  
// Probability
case 'probability': {
  const totalOutcomes = 6; // For a six-sided die
  const favorableOutcomes = 1; // Only one side is favorable for each outcome
  const rollOutcome = Math.floor(Math.random() * 6) + 1;
  answer = {
    fractional: `${favorableOutcomes}/${totalOutcomes}`
  };
  question = `Probability of rolling a ${rollOutcome} on a six-sided die?`;
  topic = 'Probability';
  expectsFraction = false; // Probability can be considered a numerical answer
  break;
}
  
case 'exponents':
  topic = 'Exponents';
// Case: Simple Probability
// Case: Simple Exponents
base = Math.floor(Math.random() * 5) + 2; // 2 to 6
const exponent = Math.floor(Math.random() * 4) + 1; // 1 to 4 for manageable numbers
question = `\\(${base}^{${exponent}}\\)`;
answer = Math.pow(base, exponent); // Calculate power



break; 
case 'multiplyPowers':
  topic = 'Multiply Powers';
// Case: Simple Probability
// Case: Multiplying Powers with the Same Base
base = Math.floor(Math.random() * 4) + 2; // 2 to 5
exponent1 = Math.floor(Math.random() * 3) + 1; // 1 to 3
exponent2 = Math.floor(Math.random() * 3) + 1; // 1 to 3
question = `\\(${base}^{${exponent1}} \\times ${base}^{${exponent2}}\\)`;
// question = `\\(${base}^{${exponent}}\\)`;
answer = Math.pow(base, exponent1 + exponent2); // Add exponents when multiplying


break; 
case 'dividePowers':
  topic = 'Divide Powers';
// Case: Simple Probability
// Case: Dividing Powers with the Same Base
base = Math.floor(Math.random() * 4) + 2; // 2 to 5
exponent1 = Math.floor(Math.random() * 5) + 3; // 3 to 7
exponent2 = Math.floor(Math.random() * 3) + 1; // 1 to 3, ensuring exponent1 > exponent2
question = `\\(${base}^{${exponent1}} \\div ${base}^{${exponent2}}\\)`;
answer = Math.pow(base, exponent1 - exponent2); // Subtract exponents when dividing


break; 
case 'squareRoots':
  topic = 'Square Root';
// Case: Finding Square Roots
perfectSquare = Math.pow(Math.floor(Math.random() * 10) + 1, 2); // Squares of 1 to 10
question = `\\(\\sqrt{${perfectSquare}}\\)`;
answer = Math.sqrt(perfectSquare); // Calculate square root

break; 
 

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  case 'basicLinear':
    const basicLinearOperation = Math.random() < 0.5 ? '+' : '-';
    topic = 'Basic Linear Algebra';
    question = `\\(x ${basicLinearOperation} ${number1} = ${number2}\\)`;
    answer = basicLinearOperation === '+' ? number2 - number1 : number2 + number1;
    break;

    case 'complexLinear':
      const complexLinearOperation = Math.random() < 0.5 ? '+' : '-';
      topic = 'Complex Linear Algebra';
      let result;

      if (complexLinearOperation === '+') {
          // Create an equation where the answer is not fixed
          result = number1 * 2 + number2; // Example operation
          question = `\\(${number1}x ${complexLinearOperation} ${number2} = ${result}\\)`;
          answer = (result - number2) / number1; // Solving for x
      } else {
          // Create a different equation for subtraction
          result = number1 * 2 - number2; // Example operation
          // question = `${number1}x - ${number2} = ${result}`;
          question = `\\(${number1}x - ${number2} = ${result}\\)`;
          answer = (result + number2) / number1; // Solving for x
      }
      break;

      case 'basicQuadratic':
        // Ensure at least one integer root for the quadratic equation x^2 + bx + c = 0
        const root1 = randomNumber(-10, 10, false); // An integer root
        const c1 = root1 * root1 + root1 * number1; // Construct c such that x^2 + number1*x + c = 0 has root1 as a root
        topic = 'Basic Quadratic Equations';
        question = `\\(x^{2} + ${number1}x + ${c1} = 0\\)`;
        answer = root1; // One of the roots
        
        break;
    
    case 'complexQuadratic':
        // Construct a more complex quadratic equation with an integer root
        const root2 = randomNumber(-10, 10, false); // An integer root
        const c2 = root2 * root2 + root2 * number1; // Construct c for a more complex quadratic equation
        topic = 'Complex Quadratic Equations';
        question = `\\(x^{2} + ${number1}x + ${c2} = 0\\)`;
        answer = root2; // One of the roots
        
        break;
    
    

        case 'basicInequality':
          inequalityOperation = Math.random() < 0.5 ? '>' : '<';
          topic = 'Basic Inequalities';
          if (inequalityOperation === '>') {
            question = `\\(x + ${number1} > ${number2}\\)`;
              // question = `\\(${base}^{${exponent1}} \\times ${base}^{${exponent2}}\\)`;
              answer = number2 - number1;
          } else {
            question = `\\(x + ${number1} < ${number2}\\)`;
              answer = number2 - number1;
          }
          
          break;
      
      case 'complexInequality':
          inequalityOperation = Math.random() < 0.5 ? '>' : '<';
          const complexInequalityMultiplier = Math.floor(Math.random() * 5) + 1; // Random multiplier from 1 to 5
          topic = 'Complex Inequalities';
          if (inequalityOperation === '>') {
            question = `\\(${complexInequalityMultiplier}x > ${number2}\\)`;
              answer = number2 / complexInequalityMultiplier;
          } else {
            question = `\\(${complexInequalityMultiplier}x < ${number2}\\)`;
              answer = number2 / complexInequalityMultiplier;
          }
          break;
      

  case 'basicSimplifyExpression':
      topic = 'Basic Simplify Expressions';
      question = `\\(${number1}x + ${number2}x\\)`;
      answer = number1 + number2;
      break;

  case 'complexSimplifyExpression':
      topic = 'Complex Simplify Expressions';
      question = `\\(${number1}x^2 + ${number2}x^2\\)`;
      answer = number1 + number2;
      break;

  case 'basicSystem':
      topic = 'Basic Systems of Equations';
      question = `\\[ \\begin{cases} x + y = ${number1} \\\\ x - y = ${number2} \\end{cases} \\]`;
      answer = (number1 + number2) / 2; // Simplified solution for x
      break;

  case 'complexSystem':
      topic = 'Complex Systems of Equations';
      question = `\\[ \\begin{cases} 2x + 3 = ${number1} \\\\ 4x - 5 = ${number2} \\end{cases} \\]`;
      answer = (number1 - 3) / 2; // Simplified solution for x
      break;

  // case 'basicFunction':
  //     topic = 'Function Evaluation';
  //     question = `\\(x=${number2} \\mid f(x) = ${number1}x\\)`;
  //     answer = number1 * number2;
  //     break;

    case 'complexFunction':
        topic = 'Complex Function Evaluation';
        question = `\\(x=${number2} \\mid g(x) = ${number1}x^2\\)`;
        answer = number1 * Math.pow(number2, 2);
        
        break;

      //   case 'basicPolynomial':
      //     const basicPolyPower = Math.floor(Math.random() * 3) + 2; // Random power between 2 and 4
      //     topic = 'Polynomial Operations';
      //     question = `\\(${number1}x^${basicPolyPower} + ${number2}x^${basicPolyPower}\\)`;
      //     answer = number1 + number2; // Coefficients add up as powers are the same
      //     break;
      
      // Combined Multiplication and Division of Polynomials
      case 'combinedPolyOperations':
  // Define powers for the polynomials
  const power1 = Math.floor(Math.random() * 2) + 1; // Power for the first term of the first polynomial
  const power2 = Math.floor(Math.random() * 2) + 1; // Power for the second term of the first polynomial
  const power3 = Math.floor(Math.random() * 1) + 1; // Power for the first term of the second polynomial (keeping it simpler)
  xValue = Math.floor(Math.random() * 5) + 1; // Randomize the value of x between 1 and 5

  // Define the polynomials
  const poly1 = `(${number1}x^${power1} + ${number2}x^${power2})`;
  const poly2 = `(${number3}x^${power3} + ${number4})`;

  topic = 'Polynomial Operations';
  question = `\\(x = ${xValue} \\\\ ${poly1} \\times ${poly2} \\)`;



  // Expand the polynomials by multiplying out the terms
  const expandedPoly1 = number1 * Math.pow(xValue, power1) + number2 * Math.pow(xValue, power2);
  const expandedPoly2 = number3 * Math.pow(xValue, power3) + number4;

  // Calculate the product of the expanded polynomial expressions
  answer = expandedPoly1 * expandedPoly2;

  break;


    // case 'basicRational':
    //     topic = 'Rational Expressions';
    //     question = `\\(\\frac{${number1}x + ${number2}}{{${number2}x + ${number1}}}\\)`;
    //     answer = (number1 + number2) / (number2 + number1);
    //     break;

    case 'complexRational':
        topic = 'Rational Expressions';
        //question = `\\(\\frac{${fraction1Sub.numerator}}{${fraction1Sub.denominator}} - \\frac{${fraction2Sub.numerator}}{${fraction2Sub.denominator}}\\)`;
        question = `\\(\\frac{${number1}x^2\\ +\\ ${number2}} {${number2}x\\ +\\ ${number1}}\\)`;
        answer = (Math.pow(number1, 2) + number2) / (number2 + number1);
        break;

    // case 'basicLogFunction':
    //     topic = 'Logarithmic Functions';
    //     question = `\\(e^x = ${number1}\\)`;
    //     answer = Math.log(number1).toFixed(2);
    //     break;

    case 'complexLogFunction':
        topic = 'Complex Logarithmic Functions';
        
        question = `\\(\\log_{${number1}}(x) = ${number2}\\)`;
        
        answer = Math.pow(number1, number2);

        
        break;

    // case 'basicSequenceAndSeries':
    //     topic = 'Sequences and Series';
    //     question = `\\(a_1=${number1},\\ d=2,\\ n=${number2}\\)`;
    //     answer = number1 + (number2 - 1) * 2;
    //     break;

    case 'complexSequenceAndSeries':
        topic = 'Sequences and Series';
        // question = `\\(x = ${xValue} \\\\ ${poly1} \\times ${poly2} \\)`;
        question = `\\(a_1 = ${number1} \\mid d = 3 \\\\ S_{${number2}} = \\frac{${number2}}{2} \\left(2a_1 + (${number2} - 1)d\\right) \\)`;
        // question = `\\[S_{${number2}} \\text{ (where } a_1 = ${number1} \\text{ and } d = 3)\\]`;

        
        answer = (number1 + (number1 + (number2 - 1) * 3)) * number2 / 2;
        break;

    //////////////////////////////////////////////////////////////////////////////////////////////////

  case 'angleOfTriangle':
    // Geometry problem: Calculate the third angle of a triangle
    shapeComponent = <TriangleSVG />;
    topic = 'Angle of Triangle';
    number1 = randomNumber(20, 120, false); // First angle
    number2 = randomNumber(20, 120, false); // Second angle
    question = `\\(\\text{Third angle with } ${number1}^\\circ \\text{ and } ${number2}^\\circ\\)`;
    answer = 180 - number1 - number2; // Sum of angles in a triangle is 180°
    break;
  case 'perimeterOfRectangle':
    // Geometry problem: Calculate the perimeter of a rectangle
    shapeComponent = <RectangleSVG />;
    topic = 'Perimeter of a Rectangle';
    number1 = randomNumber(10, 30, useDecimals); // Length
    number2 = randomNumber(10, 30, useDecimals); // Width
    question = `\\(\\text{P: } l=${number1}, h=${number2}\\)`;
    answer = 2 * (number1 + number2); // Perimeter formula for rectangle
    break;
  case 'areaOfRectangle':
    // Geometry problem: Calculate the area of a rectangle
    shapeComponent = <RectangleSVG />;
    topic = 'Area of a Rectangle';
    number1 = randomNumber(10, 30, useDecimals); // Length
    number2 = randomNumber(10, 30, useDecimals); // Width
    question = `\\(A = ${number1} \\times ${number2}\\)`; 
    answer = number1 * number2; // Area formula for rectangle
    break;

    case 'circumferenceOfCircle':
    shapeComponent = <CircleSVG />;
    topic = 'Circumference of a Circle';
    number1 = randomNumber(1, 10, useDecimals); // Radius
    question = `\\(\\text{C: } r=${number1}\\)`;
    answer = parseFloat((2 * Math.PI * number1).toFixed(2)); // Circumference formula
    break;

case 'areaOfCircle':
    shapeComponent = <CircleSVG />;
    topic = 'Area of a Circle';
    number1 = randomNumber(1, 10, useDecimals); // Radius
    question = `\\(A = \\pi \\times ${number1}^2\\)`;
    answer = parseFloat((Math.PI * number1 * number1).toFixed(2)); // Area formula
    break;

case 'volumeOfRectangularPrism':
    shapeComponent = <RectangularPrismSVG />;
    topic = 'Volume of a Rectangular Prism';
    number1 = randomNumber(1, 10, useDecimals); // Length
    number2 = randomNumber(1, 10, useDecimals); // Width
    number3 = randomNumber(1, 10, useDecimals); // Height
    question = `\\(\\text{V: } w=${number1}, h=${number2}, l=${number3}\\)`; // Using V for Volume
    answer = parseFloat((number1 * number2 * number3).toFixed(2)); // Volume formula
    break;

case 'surfaceAreaOfCube':
    shapeComponent = <CubeSVG />;
    topic = 'Surface Area of a Cube';
    number1 = randomNumber(1, 10, useDecimals); // Side length
    question = `\\(\\text{Surface area: } side=${number1}\\)`;
    answer = parseFloat((6 * number1 * number1).toFixed(2)); // Surface area formula
    break;

case 'pythagoreanTheorem':
    shapeComponent = <RightAngledTriangleSVG />;
    topic = 'Pythagorean Theorem';
    number1 = randomNumber(1, 10, useDecimals); // Side a
    number2 = randomNumber(1, 10, useDecimals); // Side b
    question = `\\(\\text{Hypotenuse: } a=${number1}, b=${number2}\\)`;
    answer = parseFloat(Math.sqrt(number1 * number1 + number2 * number2).toFixed(2)); // Pythagorean theorem
    
    break;

case 'areaOfTriangle':
    shapeComponent = <TriangleSVG />;
    topic = 'Area of a Triangle';
    number1 = randomNumber(1, 10, useDecimals); // Base
    number2 = randomNumber(1, 10, useDecimals); // Height
    question = `\\(\\text{A: } b=${number1}, h=${number2}\\)`;
    answer = parseFloat((0.5 * number1 * number2).toFixed(2)); // Area formula
    break;

case 'volumeOfCylinder':
    shapeComponent = <CylinderSVG />;
    topic = 'Volume of a Cylinder';
    number1 = randomNumber(1, 10, useDecimals); // Radius
    number2 = randomNumber(1, 10, useDecimals); // Height
    question = `\\(\\text{V: } r=${number1}, h=${number2}\\)`;
    answer = parseFloat((Math.PI * number1 * number1 * number2).toFixed(2)); // Volume formula
    break;

case 'areaOfTrapezoid':
    shapeComponent = <TrapezoidSVG />;
    topic = 'Area of a Trapezoid';
    // Generate the first base
    number1 = randomNumber(1, 10, useDecimals); 

    // Generate the second base, ensuring it's not the same as the first
    do {
        number2 = randomNumber(1, 10, useDecimals);
    } while (number1 === number2);

    // Generate the height
    number3 = randomNumber(1, 10, useDecimals);

    question = `\\(\\text{A: } b_1=${number1}, b_2=${number2}, h=${number3}\\)`;
    answer = parseFloat(((number1 + number2) * number3 / 2).toFixed(2)); // Area formula
    break;

case 'volumeOfSphere':
    shapeComponent = <SphereSVG />;
    topic = 'Volume of a Sphere';
    number1 = randomNumber(1, 10, useDecimals); // Radius
    question = `\\(\\text{V: } r=${number1}\\)`;
    answer = parseFloat((4 / 3 * Math.PI * number1 * number1 * number1).toFixed(2)); // Volume formula
    break;

case 'surfaceAreaOfSphere':
    shapeComponent = <SphereSVG />;
    topic = 'Surface Area of a Sphere';
    number1 = randomNumber(1, 10, useDecimals); // Radius
    question = `\\(\\text{Surface area: } r=${number1}\\)`;
    answer = parseFloat((4 * Math.PI * number1 * number1).toFixed(2)); // Surface area formula
    break;
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
    case "functionEvaluation":
      topic = 'Function Evaluation';
      const coef1 = randomNumber(-10, 10, false);
      const coef2 = randomNumber(-10, 10, false);
      const coef3 = randomNumber(-10, 10, false);
      xValue = randomNumber(1, 10, false);
      question = `\\(x=${xValue} \\mid \\\\ f(x) = ${coef1}x^{2} ${coef2 >= 0 ? '+' : '-'} ${Math.abs(coef2)}x ${coef3 >= 0 ? '+' : '-'} ${Math.abs(coef3)}\\)`;
      answer = parseFloat((coef1 * xValue ** 2 + coef2 * xValue + coef3).toFixed(2));
      break;
    // case "polynomialSimplification":
    //   topic = 'Polynomial Simplification';
    //   const coef4 = randomNumber(-10, 10, false);
    // const coef5 = randomNumber(-10, 10, false);
    // const coef6 = randomNumber(-10, 10, false);
    // const coef7 = randomNumber(-10, 10, false);
    // question = `\\((${coef4}x^{3} - ${coef5}x^{2}) + (${coef6}x^{3} + ${coef7}x^{2})\\)`;
    // // Assuming the simplification is to combine like terms.
    // answer = `${coef4 + coef6}x^{3} ${coef5 + coef7 >= 0 ? '+' : '-'} ${Math.abs(coef5 + coef7)}x^{2}`;
    //   break;
      // case "logarithmicFunctions":
      //   topic = 'Logarithmic Functions';
      //   const baseLog = randomNumber(2, 5, false); // Random base between 2 and 5
      //   const resultLog = randomNumber(2, 4, false); // Random result between 2 and 4
      //   question = `\\(\\log_${baseLog}(x) = ${resultLog}\\)`;
      //   answer = Math.pow(baseLog, resultLog);
      //   break;
    
      case "exponentialFunctions":
        topic = 'Exponential Functions';
        const resultExp = randomNumber(15, 25, false); // Random result between 15 and 25
        question = `\\(e^{x} = ${resultExp}\\)`;
        answer = parseFloat(Math.log(resultExp).toFixed(2));
        break;
    
        case "trigonometricIdentities":
          topic = 'Trigonometric Identities';
          let angle = randomNumber(0, 360, false); // Random angle between 0 and 360
          
          // Randomly select one of the trigonometric functions
          const trigFunctions = ["sin", "cos", "tan"];
          const trigFunction = trigFunctions[Math.floor(Math.random() * trigFunctions.length)];
          // Generate the question based on the selected trigonometric function
          switch (trigFunction) {
            case "sin":
              // Generate a random value for sine function
              const sinValue = Math.sin(angle * Math.PI / 180); // Convert angle to radians for sin function
              question = `\\(sin(${angle}^\\circ) = ${sinValue.toFixed(2)} \\\\ find \\ \\cos(${angle}^\\circ) \\)`;
              answer = Math.cos(angle * Math.PI / 180);
              break;
          case "cos":
              // Generate a random value for cosine function
              const cosValue = Math.cos(angle * Math.PI / 180); // Convert angle to radians for cos function
              question = `\\(cos(${angle}^\\circ) = ${cosValue.toFixed(2)} \\\\ find \\ \\sin(${angle}^\\circ) \\)`;
              answer = Math.sin(angle * Math.PI / 180);
              break;
          case "tan":
              // Generate a random value for tangent function
              const tanValue = Math.tan(angle * Math.PI / 180); // Convert angle to radians for tan function
              question = `\\(tan(${angle}^\\circ) = ${tanValue.toFixed(2)} \\\\ find \\ \\sin(${angle}^\\circ) \\)`;
              // question = `\\(x = ${xValue} \\mid \\\\ ${poly1} \\times ${poly2} \\)`;
              answer = Math.sin(angle * Math.PI / 180);
              break;
          }
          break;
      
    
      case "limits":
        topic = 'Limits';
        const approachLimit = randomNumber(1, 5, false); // Random approach value between 1 and 5
        question = `\\(\\lim_{x \\to ${approachLimit}} \\frac{x^{2} - ${approachLimit ** 2}}{x - ${approachLimit}}\\)`;
        answer = 2 * approachLimit; // Limit as x approaches the value is 2 times that value
        break;
    
      case "derivatives":
        topic = 'Derivatives';
        const powerDeriv = randomNumber(2, 4, false); // Random power for x between 2 and 4
        xValue = randomNumber(1, 5, false); // Random x value between 1 and 5
        question = `\\(f'(x) \\text{ at } x=${xValue}: f(x) = x^{${powerDeriv}}\\)`;
        answer = parseFloat((powerDeriv * Math.pow(xValue, powerDeriv - 1)).toFixed(2)); // powerDeriv * xValue^(powerDeriv-1)
        break;
    
      case "integrals":
        topic = 'Integrals';
        let a = randomNumber(0, 3, false); // Random start point for integral between 0 and 3
        let b = randomNumber(a + 1, a + 5, false); // Random end point between a+1 and a+5
        const coefficientInt = randomNumber(1, 5, false); // Random coefficient for x
        question = `\\(\\int_{${a}}^{${b}} ${coefficientInt}x \\, dx\\)`;
        answer = parseFloat(((coefficientInt * (b ** 2) / 2) - (coefficientInt * (a ** 2) / 2)).toFixed(2)); // (coefficientInt * b^2 / 2) - (coefficientInt * a^2 / 2)
        break;
  // ... additional cases for other operations
  default:
  throw new Error(`No operations defined for category: ${selectedCategory}`);
}

// ... Existing code ...

// Determine if the problem involves decimals
const isDecimalProblem = useDecimals && (operation === '+' || operation === '-' || operation === '*' || operation === '/');

// Set categories for the problem
const problemCategories = [selectedCategory];
if (isDecimalProblem) {
  problemCategories.push('Decimals');
}



// Calculate the value of solving each problem
// const problemValue = 0.10

return {
  topic,
  expectsFraction,
  inequalityOperation,
  xValue,
  question,
  question1,
  question2,
  answer,
  // value: problemValue,
  category: problemCategories, // Now an array of categories
  shapeComponent,
};

};