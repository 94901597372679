import './WelcomeScreen.css';
import Header from '../../componnts/Header/Header';
import React, { useState, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import FAQ from '../../componnts/FAQ/FAQ';
import Footer from '../../componnts/Footer/Footer';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import testimonialData from '../../utils/testimonials';
import PageTracker from '../../componnts/PageTracker/PageTracker';
// import MathJaxProblem from '../../componnts/MathJaxProblem/MathJaxProblem';
// import Swiper core and required modules
// import { Navigation, Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper from 'swiper';
import { Navigation, Pagination, Parallax, Autoplay } from 'swiper/modules';
// Import Swiper styles
// import 'swiper-bundle.css'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation';
import 'swiper/css/pagination';




const WelcomeScreen: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const auth = getAuth();
    const navigate = useNavigate();
    const { user, initializing } = useContext(AuthContext) ?? { user: null, initializing: true };
    const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState<number>(0);
const [prevTestimonialIndex, setPrevTestimonialIndex] = useState<number | null>(null);
  

    useEffect(() => {
        if (!initializing && user) {
            
            navigate('/menu'); // Navigate to the Menu screen
        } else {
            navigate('/');
        }
        setTimeout(() => setLoading(false), 1000);
    }, [user, initializing, navigate]);

    useEffect(() => {
        const interval = setInterval(() => {
          setPrevTestimonialIndex(currentTestimonialIndex); // This is now valid
          setCurrentTestimonialIndex(currentIndex => (currentIndex + 1) % testimonialData.length);
        }, 7000); // Change testimonial every 10 seconds
      
        return () => clearInterval(interval);
      }, [currentTestimonialIndex]);
      
    //   useEffect(() => {
    //     // Swiper initialization with modules
    //     Swiper.use([Navigation, Pagination]);
        
    //     // Initialize Swiper instances when the component mounts
    //     const parentsSwiper = new Swiper('.parentsSwiper', {
    //       modules: [Navigation, Pagination],
    //       pagination: { el: '.swiper-pagination', clickable: true },
    //       navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
    //     });
    
    //     const kidsSwiper = new Swiper('.kidsSwiper', {
    //       modules: [Navigation, Pagination],
    //       pagination: { el: '.swiper-pagination', clickable: true },
    //       navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
    //     });
    
    //     // Cleanup function to destroy Swiper instances when the component unmounts
    //     return () => {
    //       parentsSwiper.destroy();
    //       kidsSwiper.destroy();
    //     };
    //   }, []);

    if (loading) {
        return <LoadingScreen/>;
      }

    // Define the navigation functions
    const handleSignUp = () => navigate('/register');
    //const handleLogin = () => navigate('/login');
    const handleSkip = () => navigate('/menu');

    return (
        <div className="welcome-container">
            <PageTracker />
            <Header />
            <div className="welcome-main-container">
                <div className="welcome-top-container">

                    <div className="welcome-top-symbols">
                        <div className="addition-symbol spin"><span>+</span></div>
                        <div className="multiplication-symbol spin-reverse">×</div>
                        <div className="integral-symbol spin">∫</div>
                    </div>
                    <div className="welcome-top-symbols">
                        <div className="sum-symbol spin-reverse">∑</div>
                        <div className="percent-symbol spin">%</div>
                    </div>
                    <div className="welcome-top-symbols">                  
                        <div className="infinity-symbol spin-reverse">∞</div>                      
                    </div>

                    <div className="welcome-registration-container content">
                        <p className="welcome-registration-title content">Master Math, Earn Allowances, Unlock Rewards</p>
                        <p className="welcome-registration-subtitle content">Learn and Earn: A Fun Way to Master Math</p>
                        <div className="welcome-button-container content">
                            {/* <p className="label">Get Started</p> */}
                            <button className="welcome-button content" onClick={handleSignUp}>Get Started</button>
                            
                            {/* <button className="button" onClick={handleSkip}>Skip</button> */}
                        </div>
                        <div className="welcome-subscription-container content">
                        
                            <p className="welcome-subscription-title content">Invest in Education, Reap Rewards</p>
                            
                            <p className="welcome-subscription-label content">Subscribe for Success: Empower Learning for as Little as $10/Month</p>

                            {/* <a href="/" className="welcome-settings-link content">
                                <p>Learn More</p>
                            </a> */}
                                
                        </div>
                    </div>

                    <div className="welcome-bottom-symbols">
                        <div className="subtraction-symbol spin">-</div>
                    </div> 
                    <div className="welcome-bottom-symbols">
                        <div className="pound-symbol spin-reverse">#</div>
                        <div className="function-symbol spin">ƒ</div>
                    </div> 
                    <div className="welcome-bottom-symbols">
                        <div className="equals-symbol spin-reverse">=</div>
                        <div className="division-symbol spin">÷</div>
                        <div className="squareroot-symbol spin-reverse">√</div>
                    </div> 
                    
                    

                    
                </div>

               
     <div className="spikes parents-kids-container">
        <div className="parents-information">
          <div className="parents-info-title">Parents</div>
          <Swiper
          style={{
            '--swiper-navigation-color': '#D3E3FD',
            '--swiper-pagination-color': '#D3E3FD',
          }}
          speed={600}
          parallax={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Parallax, Pagination, Navigation]}
          className="mySwiper"
        >
            <div
          slot="container-start"
          className="parallax-bg"
        //   style={{
        //     'background-image':
        //       'url(https://swiperjs.com/demos/images/nature-1.jpg)',
        //   }}
          data-swiper-parallax="-23%"
        ></div>
            <SwiperSlide>
                <div className="slide-container">
                    <div className="slide-content">
                        <div className="slide-text-content">
                    <div className="title" data-swiper-parallax="-300">
                        Empower Your Child's Learning Journey
                    </div>
                            <div className="subtitle" data-swiper-parallax="-200">
                                -
                            </div>
                            <div className="text" data-swiper-parallax="-100">
                                <p>Our unique learn-and-earn model offers unlimited, grade-level math challenges and teaches financial skills by rewarding achievements with allowances, blending education with practical finance.</p>
                            </div>
                        </div>
                        <div className="image-container">
                            <img className="parentscardimage1" src='/parentcard1.png'></img>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slide-container">
                    <div className="slide-content">
                        <div className="slide-text-content">
                    <div className="title" data-swiper-parallax="-300">
                        Parental Engagement
                    </div>
                            <div className="subtitle" data-swiper-parallax="-200">
                                -
                            </div>
                            <div className="text" data-swiper-parallax="-100">
                                <p>Easily track your child's progress, manage subscriptions, and monitor earnings through our parent dashboard. Discover their strengths and weaknesses to better support their learning journey.</p>
                            </div>
                        </div>
                        <div className="image-container">
                            <img className="parentscardimage2" src='/parentcard2.png'></img>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slide-container">
                    <div className="slide-content">
                        <div className="slide-text-content">
                    <div className="title" data-swiper-parallax="-300">
                        Educational Outcomes Beyond Math
                    </div>
                            <div className="subtitle" data-swiper-parallax="-200">
                                -
                            </div>
                            <div className="text" data-swiper-parallax="-100">
                                <p>While mastering math, your child also learns valuable life skills like problem-solving, critical thinking, and time management. Our platform nurtures a growth mindset, preparing them for future challenges.</p>
                            </div>
                        </div>
                        <div className="image-container">
                            <img className="parentscardimage3" src='/parentcard3.png'></img>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slide-container">
                    <div className="slide-content">
                        <div className="slide-text-content">
                    <div className="title" data-swiper-parallax="-300">
                        Safety and Privacy
                    </div>
                            <div className="subtitle" data-swiper-parallax="-200">
                                -
                            </div>
                            <div className="text" data-swiper-parallax="-100">
                                <p>A Safe Space for Learning: We prioritize your child's safety and privacy. Our platform is designed with strict safety measures, ensuring a secure environment where your child can focus on learning and earning.</p>
                            </div>
                        </div>
                        <div className="image-container">
                            <img className="parentscardimage4" src='/parentcard4.png'></img>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

          {/* Additional slides */}
        </Swiper>
        </div>

        {/* <div className="welcome-divider"></div> */}

        <div className="kids-information">
          <div className="kids-info-title">Kids</div>
          <Swiper
          style={{
            '--swiper-navigation-color': '#D3E3FD',
            '--swiper-pagination-color': '#D3E3FD',
          }}
          speed={600}
          parallax={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Parallax, Pagination, Navigation]}
          className="mySwiper"
        >
            <div
          slot="container-start"
          className="parallax-bg"
        //   style={{
        //     'background-image':
        //       'url(https://swiperjs.com/demos/images/nature-1.jpg)',
        //   }}
          data-swiper-parallax="-23%"
        ></div>
            <SwiperSlide>
                <div className="slide-container">
                    <div className="slide-content">
                        <div className="slide-text-content">
                    <div className="title" data-swiper-parallax="-300">
                        Math Just Got More Exciting
                    </div>
                            <div className="subtitle" data-swiper-parallax="-200">
                                -
                            </div>
                            <div className="text" data-swiper-parallax="-100">
                                <p>Earn your allowance by tackling math problems! Each solved challenge boosts your rewards, turning you into a math superhero where every correct answer powers up both your skills and wallet!</p>
                            </div>
                        </div>
                        <div className="image-container">
                            <img className="kidscardimage1" src='/kidcard1.png'></img>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slide-container">
                    <div className="slide-content">
                        <div className="slide-text-content">
                    <div className="title" data-swiper-parallax="-300">
                        Social and Community Features
                    </div>
                            <div className="subtitle" data-swiper-parallax="-200">
                                -
                            </div>
                            <div className="text" data-swiper-parallax="-100">
                                <p>Reach new achievements, earn badges, and see how others are doing. Be inspired by othersand become a math hero among your peers.</p>
                            </div>
                        </div>
                        <div className="image-container">
                            <img className="kidscardimage2" src='/kidcard2.png'></img>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slide-container">
                    <div className="slide-content">
                        <div className="slide-text-content">
                    <div className="title" data-swiper-parallax="-300">
                        Engagement and Fun
                    </div>
                            <div className="subtitle" data-swiper-parallax="-200">
                                -
                            </div>
                            <div className="text" data-swiper-parallax="-100">
                                <p>Turn Math into an Adventure - Each math problem you solve brings you closer to your next reward. Watch your progress in real time, level up like in your favorite games, and celebrate your achievements with every milestone reached.</p>
                            </div>
                        </div>
                        <div className="image-container">
                            <img className="kidscardimage3" src='/kidcard3.png'></img>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slide-container">
                    <div className="slide-content">
                        <div className="slide-text-content">
                    <div className="title" data-swiper-parallax="-300">
                        Testimonials and Success Stories
                    </div>
                            <div className="subtitle" data-swiper-parallax="-200">
                                -
                            </div>
                            <div className="text" data-swiper-parallax="-100">
                                <p>Hear from Other Families: Read stories from parents and kids who have transformed their learning and financial habits through our platform. See the real impact of gamified learning on children's motivation and success.</p>
                            </div>
                        </div>
                        <div className="image-container">
                            <img className="kidscardimage4" src='/kidcard4.png'></img>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
          {/* Additional slides */}
        </Swiper>
        </div>
    </div>

    <div className="welcome-steps">
       
        <div className="welcome-steps-container">

            <div className="welcome-steps-title-container">
                <div className="welcome-steps-title">
                    How <span className="welcome-steps-subtitle">MathBucks</span> Works
                </div>
            </div>

            <p className="welcome-steps-text">
                <span className="steps-number1">1.</span>Sign up for free and start solving math problems.
            </p>
            <p className="welcome-steps-text">
                <span className="steps-number2">2.</span>Subscribe - <b>Your subscription (minus fees) is sent right back into your account as an allowance.</b>
            </p>
            <p className="welcome-steps-text">
                <span className="steps-number3">3.</span>Earn your allowance by solving problems.
            </p>
            <p className="welcome-steps-text">
                <span className="steps-number4">4.</span>Redeem your hard earned allowance for gift cards!
            </p>

            <div className="welcome-steps-button-container">
            <button className="settings-link-welcome" onClick={() => navigate('/rewardpreview')}>
                <span>See our Rewards Catalog Here</span>
              </button>
            </div>

            

            
        </div>
    </div>

    <div className="testimonials-container">
        <div className ="testimonials-title">Testimonials</div>
    <Swiper
        style={{
            '--swiper-navigation-color': '#D3E3FD',
            '--swiper-pagination-color': '#D3E3FD',
        }}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
            delay: 5000,
            disableOnInteraction: false,
        }}
        pagination={{
            clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper1"
        >
        {testimonialData.map((testimonial, index) => (
            <SwiperSlide key={testimonial.id}>
            <div className="user-testimonial-card">
                <p className="user-testimonial-title">{testimonial.name}</p>
                <div className="user-testimonial-comment">
                <p><strong>{testimonial.testimonial}</strong></p>
                <p className="signature">- {testimonial.relationship}</p>
                </div>
                <div className="rating">★★★★★</div>
            </div>
            </SwiperSlide>
        ))}
        </Swiper>
        </div>


                <div className="welcome-subscription">

                <div className="welcome-subscription-info-container1">
                        <p className="welcome-subscription-info-title1">Free</p>
                        <div className="welcome-subscription-label-container">
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Play on any device</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Unlimited Math Problems</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>10 Free Answer Reveals (one-time)</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Purchase Additional Reveals</p>
                            <p className="welcome-subscription-info-label1"><span className="red-checkmark">✗</span>No Montlhy Allowance</p>
                            <p className="welcome-subscription-info-label1"><span className="red-checkmark">✗</span>Cannot Spend Earnings</p>
                        </div>
                        <p className="welcome-subscription-info-price">Free</p>
                        <p className="welcome-subscription-info-label2"><span className="red-checkmark">✗</span>No Allowance: <span className="red-price">$0.00</span></p>
                        
                        {/* <a href="/" className="welcome-subscription-settings-link">
                            <p>Learn More</p>
                        </a> */}
                    </div>

                    <div className="welcome-subscription-info-container2">
                        <p className="welcome-subscription-info-title2">Casual</p>
                        <div className="welcome-subscription-label-container">
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Play on any device</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Unlimited Math Problems</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>10 Daily Free Answer Reveals</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Purchase Additional Reveals</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Monthly Allowance</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Spend Earnings</p>
                        </div>
                        <p className="welcome-subscription-info-price">$10/Month</p>
                        <p className="welcome-subscription-info-label2"><span className="green-checkmark">✓</span>Earn Up to<span className="green-price">$8.41</span> Monthly!</p>
                        
                        {/* <a href="/" className="welcome-subscription-settings-link">
                            <p>Learn More</p>
                        </a> */}
                    </div>
                    <div className="welcome-subscription-info-container3">
                        <p className="welcome-subscription-info-title3">Max</p>
                        <div className="welcome-subscription-label-container">
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Play on any device</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Unlimited Math Problems</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>10 Daily Free Answer Reveals</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Purchase Additional Reveals</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Monthly Allowance</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Spend Earnings</p>
                            <p className="welcome-subscription-info-label1"><span className="green-checkmark">✓</span>Best Value</p>
                        </div>
                        <p className="welcome-subscription-info-price">$15/Month</p>
                        <p className="welcome-subscription-info-label2"><span className="green-checkmark">✓</span>Earn Up to<span className="green-price">$13.27</span> Monthly!</p>
                        
                        {/* <a href="/" className="welcome-subscription-settings-link">
                            <p>Learn More</p>
                        </a> */}
                    </div>
                        
                </div>

                <div className="spikes2">

                    <img src="/devices.png" alt="Devices Picture" className="image" />

                    <div className="info-container">
                        <p className="welcome-info-title2">Play Anywhere</p>
                        
                        <p className="welcome-info-label2">Play on any Computer, Laptop, Tablet, or Phone</p>

                        {/* <a href="/" className="welcome-settings-link">
                            <p>Learn More</p>
                        </a> */}
                    </div>
                        
                </div>

                <div className="spikes-reverse">
                    <img src="/blackboard.png" alt="Anywhere Picture" className="image" />
                    <div className="info-container">
                        <p className="welcome-info-title1">Mastering Math</p>
                        
                        <p className="welcome-info-label1">Solve Unlimited Math Problems for Grades 1-12</p>

                        {/* <a href="/" className="welcome-settings-link">
                            <p>Learn More</p>
                        </a> */}
                    </div>
                        
                </div>

                <div className="spikes2">
                    <img src="/rewards.png" alt="Rewards Picture" className="image" />
                    <div className="info-container">
                        <p className="welcome-info-title3">Earn Rewards</p>
                        
                        <p className="welcome-info-label3">Spend your Rewards through Gift Cards</p>

                        {/* <a href="/" className="welcome-settings-link">
                            <p>Learn More</p>
                        </a> */}
                    </div>
                        
                </div>

                <div className="welcome-faq-container">
                    
                        <FAQ></FAQ>             
                        
                </div>
                
            </div>
            <Footer></Footer>
        </div>
    );
};

export default WelcomeScreen;