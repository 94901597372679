import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './VerifyScreen.css'; // Assuming you have a corresponding CSS file
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { AuthContext } from '../../contexts/AuthContext';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase'; // Update the import path as needed
import Header from '../../componnts/Header/Header';
import PageTracker from '../../componnts/PageTracker/PageTracker';

const VerifyScreen: React.FC = () => {
  const [userEmail, setUserEmail] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationEmailSent, setVerificationEmailSent] = useState<boolean>(false);
  const [verificationEmailError, setVerificationEmailError] = useState<string>('');
  const { user, initializing } = useContext(AuthContext) ?? { user: null, initializing: true };
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    // Redirect to login if no user is logged in
    if (!user && !initializing) {
      navigate('/login');
    }

    // Update the userEmail state if the user object has the email
    if (user?.email) {
      setUserEmail(user.email);
    }
  }, [user, initializing, navigate]);

  const handleSendEmailVerification = async () => {
    if (!user) {
      console.error('No user is signed in.');
      setError('No user is signed in. Please sign in and try again.');
      return;
    }

    setIsLoading(true);
    setError('');
    setVerificationEmailSent(false);

    try {
      await sendEmailVerification(user);
      // console.log('Email verification sent to:', user.email);
      setVerificationEmailSent(true);
      setSuccess(`Another email verification sent to: ${user.email}`)
      setError('');
    } catch (error) {
      console.error('Error sending email verification:', error);
      setError('Failed to send verification email. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToSupport = () => {
    navigate('/support');
  };

  return (
    <div className="verify-screen-container">
      <PageTracker />
      <Header />
      <div className="verify-screen-main-container">
        <h1 className="verify-screen-title">Verify Your Email</h1>
        <p className="verify-screen-description">
          To ensure the security of your account, please verify your email address.
        </p>
        {userEmail && (
          <p className="verify-screen-email">A verification email has been sent to: <strong>{userEmail}</strong></p>
        )}
        <p className="verify-screen-suggestion">
          Didn't receive the email? Click below to send another one.
        </p>

        {isLoading ? (
          <p className="verify-screen-loading">Sending email...</p>
        ) : (
          <button className="verify-button" onClick={handleSendEmailVerification} disabled={verificationEmailSent}>
            {verificationEmailSent ? '✓ Email Sent' : 'Send Another Email'}
          </button>
        )}

        {verificationEmailSent && (
          <p className="verify-screen-success">✓ {success}</p>
        )}

        {error && (
          <p className="verify-screen-error">{error}</p>
        )}

        <p className="verify-screen-support">
          If you need assistance, or no longer have access to this email, please get in touch with our support team.
        </p>
        <button className="settings-link" onClick={navigateToSupport}>
          Contact Support
        </button>
      </div>
    </div>
  );
};

export default VerifyScreen;