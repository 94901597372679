import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import LoginScreen from './screens/LoginScreen/LoginScreen'; // Make sure the path is correct
import WelcomeScreen from './screens/WelcomeScreen/WelcomeScreen';
import MenuScreen from './screens/MenuScreen/MenuScreen';
import SettingsScreen from './screens/SettingsScreen/SettingsScreen';
import RegistrationScreen from './screens/RegistrationScreen/RegistrationScreen';
import SubscriptionScreen from './screens/SubscriptionScreen/SubscriptionScreen';
import RewardScreen from './screens/RewardScreen/RewardScreen';
import EarnGame from './screens/EarnGame/EarnGame';
import SupportScreen from './screens/SupportScreen/SupportScreen';
import LeaderboardScreen from './screens/LeaderboardScreen/LeaderboardScreen';
import { Provider as ReduxProvider } from 'react-redux';
//import store from './store';
//import WebAppNavigator from './components/WebAppNavigator'; // Adapted for web
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getAnalytics, logEvent } from "firebase/analytics";
import LoadingScreen from './screens/LoadingScreen/LoadingScreen';
import ProfileScreen from './screens/ProfileScreen/ProfileScreen';
import InsightsScreen from './screens/InsightsScreen/InsightsScreen';
// import AchievementsScreen from './screens/AchievementsScreen/AchievementsScreen';
import AccountUpdateScreen from './screens/AccountUpdateScreen/AccountUpdateScreen';
import FAQScreen from './screens/FAQScreen/FAQScreen';
import VerifyScreen from './screens/VerifyScreen/VerifyScreen';
import TermsAndPrivacy from './screens/TermsAndPrivacy/TermsAndPrivacy';
import NoConnectionScreen from './screens/NoConnectionScreen/NoConnectionScreen';
import About from './screens/About/About';
import RewardPreviewScreen from './screens/RewardPreviewScreen/RewardPreviewScreen';

const stripePromise = loadStripe('pk_test_51OEeFKKvTLpZISR2JghJpVi7F9EnpZsFfUaarnqusIWwvbeqnRB00oMPdM6sB1kFWsdNwTu0fK7wXK3udPcmkhnV00drvYEFtt');

function App() {

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  

  const handleRetry = () => {
    if (navigator.onLine) {
      // If online, redirect back to the previous page or home page
      window.location.reload(); // This will reload the current page
    } else {
      // Optionally show a message or simply stay on the NoConnectionScreen
      alert("Still offline. Please check your connection.");
    }
  };

  if (!isOnline) {
    return <NoConnectionScreen onRetry={handleRetry} />;
  }

  return (
    
      <Elements stripe={stripePromise}>
        <AuthProvider>
          <Router>
          <Routes>
            <Route path="/" element={<WelcomeScreen />} />
            <Route path="/about" element={<About />} />
            <Route path="/register" element={<RegistrationScreen />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/accountupdate" element={<AccountUpdateScreen />} />
            <Route path="/menu" element={<MenuScreen />} />
            <Route path="/play" element={<EarnGame />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/leaderboard" element={<LeaderboardScreen />} />
            <Route path="/insights" element={<InsightsScreen />} />
            {/* <Route path="/achievements" element={<AchievementsScreen />} /> */}
            <Route path="/settings" element={<SettingsScreen />} />
            <Route path="/subscription" element={<SubscriptionScreen />} />
            <Route path="/rewards" element={<RewardScreen />} />
            <Route path="/faq" element={<FAQScreen />} />
            <Route path="/support" element={<SupportScreen />} />
            <Route path="/loading" element={<LoadingScreen />} />
            <Route path="/verify" element={<VerifyScreen />} />
            <Route path="/rewardpreview" element={<RewardPreviewScreen />} />
            <Route path="/termsandprivacy" element={<TermsAndPrivacy />} />
            {/* ...other routes */}
          </Routes>
          </Router>
        </AuthProvider>
      </Elements>
    
  );
}

export default App;