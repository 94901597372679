import './RegistrationScreen.css';
import Header from '../../componnts/Header/Header';
import React, { useEffect, useContext, useState, FormEvent } from 'react';
import { getAuth, sendEmailVerification, createUserWithEmailAndPassword, updateProfile  } from 'firebase/auth';
import {functions} from '../../firebase';
import {httpsCallable} from 'firebase/functions';
import { createAvatar } from '@dicebear/core';
import { bottts } from '@dicebear/collection';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Footer from '../../componnts/Footer/Footer';
import PageTracker from '../../componnts/PageTracker/PageTracker';
var Filter = require('bad-words'),
    filter = new Filter();

    // Add custom profane words to the filter list
filter.addWords('gay', 'g4y', 'homo', 'h0mo', 'hom0', 'h0m0', 'fag', 'f4g', 'faggot', 'f4ggot', 'fagg0t', 'f4gg0t', 'b1tch', 'b1tches', 'queer', 'qu33r', 'que3r', 'qu3er', 'a$s', 'as$', 'a$$', 'dick', 'd1ck', 'penis', 'p3nis', 'pen1s', 'p3n1s', 'peni5', 'p3ni5', 'pen15', 'p3n15', 'butt');

interface IUserRegistrationData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  username: string;
  avatarURI: any;
  dateOfBirth: string;
}

interface IRegisterUserResponse {
  result: string;
  customToken: string;
}

const RegistrationScreen: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date());
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const authContext = useContext(AuthContext);
    const { setUser } = useContext(AuthContext);
    const { user, initializing } = useContext(AuthContext) ?? { user: null, initializing: true };
    const [avatarURI, setAvatarURI] = useState<string | null>(null);
    const [outgoingStep, setOutgoingStep] = useState(0);
    const [error, setError] = useState<string>('');
    

    const auth = getAuth();
    const navigate = useNavigate();

    interface ValidationResult {
      isValid: boolean;
      message: string;
    }

    // Function to validate inputs
    const validateInputs = () => {
      const validationResult = isUsernameValid(username);

        if (!email || !isEmailValid(email)) {
            setError("Please enter a valid email.");
            return false;
        }
        if (!firstName || !isNameValid(firstName)) {
            setError("Please enter your first name.");
            return false;
        }
        if (!lastName || !isNameValid(lastName)) {
            setError("Please enter your last name.");
            return false;
        }

        if (currentStep === 2 && !validationResult.isValid) {
          setError(validationResult.message);
          return false;
        }
        if (currentStep === 3) {
          if (!password || !confirmPassword) {
              setError("Password fields cannot be empty.");
              return false;
          }
          if (password !== confirmPassword) {
              setError("Passwords do not match.");
              return false;
          }
          if (!isPasswordStrong(password)) {
              setError("Password must be at least 8 characters long, and contain at least one upper case letter and one symbol.");
              return false;
          }
      }
        setError('');
        return true;
    };

    const goToNextStep = async () => {
        setIsLoading(true);
        if (!validateInputs()) {
            setIsLoading(false);
            return;
        }

        if (currentStep === 1) {
            try {
                generateRandomAvatar(email);
            } catch (error) {
                console.error("Error generating avatar:", error);
            }
            if (!await checkEmail(email)) {
                setIsLoading(false);
                return;
            }
        } else if (currentStep === 2) {
            if (!await checkUsername(username)) {
                setIsLoading(false);
                return;
            }
        } else if (currentStep === 3) {
            await handleRegister();
        }

        if (currentStep < 4) {
            setCurrentStep(currentStep + 1);
        }
        setIsLoading(false);
    };

  
    const handleRegister = async () => {
      const auth = getAuth();
      try {
        // Create user with email and password
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);

        if (userCredential.user) {
          await updateProfile(userCredential.user, {
            displayName: `${firstName} ${lastName}`,
            //photoURL: `${avatarURI}`, // Construct displayName from first and last name
          });
        }
    
        // Check if the currentUser exists and is not null
        if (auth.currentUser) {
          const uid = auth.currentUser.uid; // Get the UID of the current user
    
          // Call your Cloud Function with user data including the UID
          const registerUserCallable = httpsCallable(functions, 'registerUser');
          await registerUserCallable({
            email,
            password,
            firstName,
            lastName,
            username,
            avatarURI,
            dateOfBirth: dateOfBirth.toISOString(),
            uid, // Include the UID here
          });
    
          await sendEmailVerification(auth.currentUser)
            .then(() => {
              setCurrentStep(4); // Move to the next step in the UI
              setUser(userCredential.user); // Set the user in context
            })
            .catch((error) => {
              console.error('Error sending email verification:', error);
            });
        } else {
          throw new Error('User registration failed.');
        }
      } catch (error) {
        console.error('Error registering user:', error);
        setError('Registration failed. Please try again.');
        setIsLoading(false);
      }
    };
    

    
    
    const isPasswordStrong = (password: string) => {
      // Check for minimum length of 8 characters
      const minLength = password.length >= 8;
      // Check for at least one uppercase letter
      const hasUpperCase = /[A-Z]/.test(password);
      // Check for at least one symbol (non-alphanumeric character)
      const hasSymbol = /[^A-Za-z0-9]/.test(password);
    
      // Password is strong if it meets all the above criteria
      return minLength && hasUpperCase && hasSymbol;
    };
    
    
      const isEmailValid = (email: string) => {
        return email.includes('@');
      };
    
      const isNameValid = (name: string) => {
        return name.length > 0;
      };

      // Add "gay" to the custom list of profane words


      const isUsernameValid = (username: string): ValidationResult => {
        // Check for spaces in the username
        if (username.includes(' ')) {
          return { isValid: false, message: "Username cannot contain spaces." };
        }
        // Basic length check
        if (username.length < 2 || username.length > 15) {
          return { isValid: false, message: "Username must be between 2 and 15 characters long." };
        }
      
        // Convert username to lowercase for case-insensitive comparison
        const lowerUsername = username.toLowerCase();
      
        // Iterate over the list of prohibited words
        for (let word of filter.list) {
          if (lowerUsername.includes(word)) {
            return { isValid: false, message: "Username contains inappropriate content." };
          }
        }
      
        // If all checks pass, the username is considered valid
        return { isValid: true, message: "" };
      };
      
    
      interface UniqueCheckResponse {
        unique: boolean;
      }
      // Function to check email uniqueness
    const checkEmail = async (email: string) => {
    
      if (!isEmailValid(email)) {
        setError("Please enter in a valid email.");
        return;
      }
    
      if (!isNameValid(firstName)) {
        setError("Please enter in your First Name");
        return;
      }
    
      if (!isNameValid(lastName)) {
        setError("Please enter in your Last Name");
        return;
      }
    
      const checkEmailUnique = httpsCallable(functions, 'checkEmailUnique');
      try {
        const result = await checkEmailUnique({ email });
        // Assert the type of result.data
        const response = result.data as UniqueCheckResponse;
        if (!response.unique) {
          setError("This email is already in use. Please use a different email.");
          return false;
        }
        return true;
      } catch (error) {
        console.error("Error checking email uniqueness:", error);
        setError("Unable to check email uniqueness. Please try again later.");
        return false;
      }
    
    };
    
    const checkUsername = async (username: string) => {
      // ... your existing validation logic ...
      if (!isUsernameValid(username)) {
        setError("Username must be between 2 and 20 characters long.");
        return false;
    }
      const checkUsernameUnique = httpsCallable(functions, 'checkUsernameUnique');
      try {
        const result = await checkUsernameUnique({ username });
        // Assert the type of result.data
        const response = result.data as UniqueCheckResponse;
        if (!response.unique) {
          setError("This username is already in use. Please choose a different username.");
          return false;
        }
        return true;
      } catch (error) {
        console.error("Error checking username uniqueness:", error);
        setError("Unable to check username uniqueness. Please try again later.");
        return false;
      }
    };
    
    const onDateChange = (selectedDate: Date) => {
      setDateOfBirth(selectedDate);
  };
  
  // You don't need a separate function to show the date picker
  // as react-datepicker will handle the display
  
    
  const generateRandomAvatar = (seed: string) => {
    const avatar = createAvatar(bottts, { seed });
    const encodedDataUri = avatar.toDataUriSync();
    setAvatarURI(encodedDataUri);
    // Store this `encodedDataUri` in your database
  };
    
    useEffect(() => {
    }, [avatarURI]);
    
    const navigateTo = (path: string) => {
      navigate(path); // Replace with appropriate navigation logic
  };
    
    const goToPreviousStep = async () => {
      if (currentStep > 1) {
        setOutgoingStep(currentStep);
        await new Promise(resolve => setTimeout(resolve, 500)); // Wait for the animation to complete
        setCurrentStep(currentStep - 1);
        setOutgoingStep(0); // Reset outgoing step
      }
  }
    

  
  

    // Render functions for each step
    const renderStepOne = () => (
      <div className={`contentContainer ${getStepClass(1)}`}>
        <div className="container">
          <h2 className="registration-title">Step 1 of 3</h2>
          <h2 className="registration-subtitle">Just a few more steps and you're done. </h2>
          
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="registration-input"
          />
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="registration-input"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="registration-input"
          />
          <div className="terms-and-privacy-links">
            By signing up, you agree to our 
            <Link to="/termsandprivacy" className="document-link"> Terms of Service</Link> and
            <Link to="/termsandprivacy" className="document-link"> Privacy Policy</Link>.
          </div>
    
          {/* <button className="registration-button" onClick={() => setShowDatePicker(true)}>Select Birthday</button>
          {showDatePicker && (
            <DatePicker
              selected={dateOfBirth}
              onChange={(date: Date) => setDateOfBirth(date)}
              maxDate={new Date()}
              className="datePicker"
            />
          )} */}
           
            {isLoading ? (
              <div className="spinner"></div>
            ) : (
              <div className="registration-button-container">
                <button className="registration-button" onClick={() => navigateTo('/')} disabled={isLoading}>Back</button>
                <button className="registration-button" onClick={goToNextStep} disabled={isLoading}>Next</button>
              </div>
              
            )}
          
          {error && <div className="error-message">{error}</div>}

        </div>
       
      </div>
    );
    

    const renderStepTwo = () => (
      <div className={`contentContainer ${getStepClass(2)}`}>
        <div className="container">
          <h2 className="registration-title">Step 2 of 3</h2>
          <h2 className="registration-subtitle">Create a Username for your Profile. </h2>
          
          {avatarURI ? (
      <div className="registration-avatar">
        <img src={avatarURI} alt="Avatar" />
      </div>
    ) : (
      <p>Loading Avatar...</p>
    )}
    
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="registration-input"
          />

          <div className="terms-and-privacy-links">
            By signing up, you agree to our 
            <Link to="/termsandprivacy" className="document-link"> Terms of Service</Link> and
            <Link to="/termsandprivacy" className="document-link"> Privacy Policy</Link>.
          </div>
            {isLoading ? (
              <div className="spinner"></div>
            ) : (
              <div className="registration-button-container">
                <button className="registration-button" onClick={goToPreviousStep} disabled={isLoading}>Back</button>
                <button className="registration-button" onClick={goToNextStep} disabled={isLoading}>Next</button>
              </div>
            )}
        
          {error && <div className="error-message">{error}</div>}
        </div>
        
      </div>
    );
    

    const renderStepThree = () => (
      <div className={`contentContainer ${getStepClass(3)}`}>
        <div className="container">
        <h2 className="registration-title">Step 3 of 3</h2>
          <h2 className="registration-subtitle">Create a password to finish creating your account! </h2>
          
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="registration-input"
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="registration-input"
          />
           <div className="terms-and-privacy-links">
            By signing up, you agree to our 
            <Link to="/termsandprivacy" className="document-link"> Terms of Service</Link> and
            <Link to="/termsandprivacy" className="document-link"> Privacy Policy</Link>.
          </div>
            {isLoading ? (
              <div className="spinner"></div>
            ) : (
              <div className="registration-button-container">
                <button className="registration-button" onClick={goToPreviousStep} disabled={isLoading}>Back</button>
                <button className="registration-button" onClick={goToNextStep} disabled={isLoading}>Next</button>
              </div>
            )}
          
          {error && <div className="error-message">{error}</div>}
        </div>
      </div>
    );

    const renderStepFour = () => (
      <div className={`contentContainer ${getStepClass(4)}`}>
        <div className="container">
        {/* <h2 className="registration-title">Step 3 of 3</h2> */}
          <h2 className="registration-subtitle">Please check your inbox for a verifcation email. </h2>
          
          
           
          {isLoading ? (
              <div className="spinner"></div>
            ) : (
              <div className="registration-button-container-dashboard">
                <button className="registration-button-dashboard" onClick={() => navigate('/menu')} disabled={isLoading}>Go to Dashboard</button>
              </div>
            )}    
          
          {error && <div className="error-message">{error}</div>}
        </div>
      </div>
    );

    const getStepClass = (stepNumber:any) => {
      if (stepNumber === currentStep) return 'active';
      if (stepNumber === outgoingStep) return 'outgoing';
      return '';
    };
    

    return (
      <div className="registration-container">
        <PageTracker />
       <Header />

       <div className="steps-container">
        {renderStepOne()}
              {renderStepTwo()}
              {renderStepThree()}
              {renderStepFour()}
      </div>
            
            {/* <Footer></Footer> */}
    </div>
    );
};

export default RegistrationScreen;
