import React, { useState, useEffect } from 'react';
import './SupportScreen.css'; // Assuming you have a corresponding CSS file
import { getAuth } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase'; // Update the import path as needed
import Header from '../../componnts/Header/Header';
import Footer from '../../componnts/Footer/Footer';
import PageTracker from '../../componnts/PageTracker/PageTracker';

const SupportScreen: React.FC = () => {
  const [subject, setSubject] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');


  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser && currentUser.email && currentUser.displayName) {
      setUserEmail(currentUser.email); // Pre-fill with user's email
      setDisplayName(currentUser.displayName);
    }
  }, []);

  const handleFormSubmit = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert('Error: You must be logged in to send feedback.');
      return;
    }

    try {
      const idToken = await currentUser.getIdToken(true);
      const sendFeedback = httpsCallable(functions, 'sendFeedbackEmail');
      await sendFeedback({ idToken, subject, description, userEmail, displayName });

      alert('Success: Feedback sent successfully.');
      // Optionally navigate back or clear the form
    } catch (error: any) {
      console.error('Error sending feedback:', error);
      alert('Error: Failed to send feedback.');
    }

    try {
      const idToken = await currentUser.getIdToken(true);
      const sendCustomerFeedbackEmail = httpsCallable(functions, 'sendCustomerFeedbackEmail');
      await sendCustomerFeedbackEmail({ idToken, subject, description, userEmail, displayName });

      // console.log('Success: Confirmation sent tp Customer successfully.');
      // Optionally navigate back or clear the form
    } catch (error: any) {
      console.error('Error sending Confirmation:', error);
      alert('Error: Failed to send Confirmation.');
    }
  };

  return (
    <div className="support-container">
      <PageTracker />
            <Header></Header>
        <div className="support-form-container">
            <h1 className="support-header">Contact Us</h1>
            <p className="support-description">
                Please use the following form to contact us for support, give feedback, or report a bug.
            </p>
            <div>
                <label className="support-field-label">Email</label>
                <input
                className="support-input-subject"
                type="email"
                placeholder="Your Email"
                value={userEmail}
                onChange={e => setUserEmail(e.target.value)}
                />
            </div>
            <div>
                <label className="support-field-label">Subject</label>
                <input
                className="support-input-subject"
                type="text"
                placeholder="Subject"
                value={subject}
                onChange={e => setSubject(e.target.value)}
                />
            </div>
            <div>
                <label className="support-field-label">Description</label>
                <textarea
                className="support-input-description"
                placeholder="Description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                />
            </div>
            <button className="support-button" onClick={handleFormSubmit}>
                Submit
            </button>
        </div>
        <Footer></Footer>
    </div>
  );
};

export default SupportScreen;
