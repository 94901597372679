import './RewardPreviewScreen.css';
import Header from '../../componnts/Header/Header';
import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
//import { tangoCardFetch, placeTangoOrder } from '../../tangoCardApi'
import { httpsCallable } from 'firebase/functions';
import {functions} from '../../firebase'
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import Footer from '../../componnts/Footer/Footer';
import { FiChevronDown, FiChevronUp } from "react-icons/fi"; // Import Chevron icons from react-icons
import PageTracker from '../../componnts/PageTracker/PageTracker';
  
  interface TangoCardFetchResponse {
    brands: Brand[];
  }
  
  interface Brand {
    brandKey: string;
    brandName: string;
    description: string;
    items: Item[];
    imageUrls: {
      [key: string]: string; // This allows for dynamic keys with string values
    };
  }
  
  
  interface Item {
    utid: string;
    rewardName: string;
    redemptionInstructions: string;
    valueType: 'FIXED_VALUE' | 'VARIABLE_VALUE'; // Add valueType
    faceValue?: number;  // Optional, as it may not be present for VARIABLE_VALUE types
    minValue?: number;   // Optional, as it may not be present for FIXED_VALUE types
    maxValue?: number;   // Optional, as it may not be present for FIXED_VALUE types
  }
  

  const RewardPreviewScreen: React.FC = () => {
 
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBrands, setFilteredBrands] = useState<Brand[]>([]);
  const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({});
  
  const [loading, setLoading] = useState<boolean>(true);
  const [brands, setBrands] = useState<Brand[]>([]);
  const endpoint = "/catalogs?utid=U453114";
 

  

  const fetchCatalogs = async () => {
   
  
      try {
        
        const tangoCardFetchPromise = httpsCallable<unknown, TangoCardFetchResponse>(
          functions,
          'tangoCardFetch'
        )();
  
        // Wait for both promises to resolve
        const [tangoCardFetchResult] = await Promise.all([
          tangoCardFetchPromise,
        ]);
  
        setBrands(tangoCardFetchResult.data.brands);
        // console.log(tangoCardFetchResult.data.brands)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false regardless of the result
      }
    
  };

  
  
  useEffect(() => {
    if (!searchQuery) {
      setFilteredBrands(brands);
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const filtered = brands.filter((brand) =>
        brand.brandName.toLowerCase().includes(lowerCaseQuery)
      );
      setFilteredBrands(filtered);
    }
  }, [brands, searchQuery]);
  

  // Make sure to call this function without awaiting it directly in the useEffect hook
  useEffect(() => {
    fetchCatalogs();
  }, ); // Depend on user so it will re-run the effect when the user changes


  


  

// Toggle function for expanding and collapsing the faceValues of a brand
const handleToggleFaceValues = (brandKey: string) => {
  setExpandedItems(prevExpandedItems => ({
    ...prevExpandedItems,
    [brandKey]: !prevExpandedItems[brandKey]
  }));
};

// Render a brand with its items
const renderBrand = (brand: Brand) => {
  const hasMultipleValues = brand.items.length > 1;
  const isExpanded = expandedItems[brand.brandKey];

  return (
    <div key={brand.brandKey} className="brand">
      <h2 className="brand-name">{brand.brandName}</h2>
      <img className="Brand-img" src={brand.imageUrls['300w-326ppi']} alt={brand.brandName} />
      {hasMultipleValues ? (
        <>
          <div className="values-toggle" onClick={() => handleToggleFaceValues(brand.brandKey)}>
            {isExpanded ? <FiChevronUp /> : <FiChevronDown />} Multiple
          </div>
          {isExpanded && (
            <ul className="values-list">
              {brand.items.map(item => (
                <li className="values" key={item.utid}>${item.faceValue}</li>
              ))}
            </ul>
          )}
        </>
      ) : (
        <div className="values">${brand.items[0].minValue} - ${brand.items[0].maxValue}
          </div>
        
      )}
    </div>
  );
};

  

  if (loading) {
    return <LoadingScreen/>;
  }



// Main component return
return (
  <div className="rewards-container">
    <PageTracker />
    <Header />
    <h1 className="rewards-title">Rewards</h1>
    <div className="rewards-info-container">
     

    </div>

    <div className="search-container">
    <p className="rewards-info1">* The is a preview of the rewards catalog and does not show the entire list of gift cards *</p>
      <div className="search-text">Search:</div>
  <input
    type="text"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    placeholder="Search for brands..."
    className="search-input"
  />
</div>


    {/* Brands Grid */}
    <div className="brands-grid">
        {filteredBrands.map(renderBrand)}
      </div>
    <Footer></Footer>
  </div>
);


};

export default RewardPreviewScreen;