import React, { useState, useEffect } from 'react';
import './About.css'; // Assuming you have a corresponding CSS file
import { getAuth } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase'; // Update the import path as needed
import Header from '../../componnts/Header/Header';
import Footer from '../../componnts/Footer/Footer';
import PageTracker from '../../componnts/PageTracker/PageTracker';

const About: React.FC = () => {
    return (
      <div className="about-container">
        <PageTracker />
        <Header />
        <div className="about-content">
          <h1 className="about-title">About Our App</h1>
          <section className="about-section">
            <h2 className="about-section-title">Empowering Education Through Engagement</h2>
            <p>
              Our app is designed to bridge the gap between learning and real-world application by offering a unique learn-and-earn platform. Parents can subscribe for just $x per month, of which $x is allocated as a potential allowance for their children. By solving math problems, children earn a portion of this allowance, fostering both educational engagement and financial literacy.
            </p>
          </section>
          <section className="about-section">
            <h2 className="about-section-title">For Parents: Invest in Your Child's Future</h2>
            <p>
              As a parent, you are always looking for ways to invest in your child's education. Our app provides an avenue for you to do just that. Your subscription not only supports your child's mathematical prowess but also teaches them the value of money. With each problem solved, children earn toward their allowance, which they can redeem for gift cards through Tango Card, making learning both rewarding and fun.
            </p>
          </section>
          <section className="about-section">
            <h2 className="about-section-title">For Kids: Learn, Earn, and Have Fun!</h2>
            <p>
              For kids, our app turns math problems into a challenging adventure. Each correct answer brings them one step closer to earning their allowance. It's a gratifying way to learn math – by earning rewards, children are motivated to keep improving their skills. And the best part? They can redeem their earnings for exciting gift cards from a wide range of brands, giving them the freedom to choose their rewards.
            </p>
          </section>
          <section className="about-section">
            <h2 className="about-section-title">Safe, Secure, and Kid-Friendly</h2>
            <p>
              Safety and privacy are paramount. We've built our platform with these principles at the forefront, ensuring a secure environment where children can concentrate on learning without distractions. For parents, we offer peace of mind with features that allow you to track progress and manage account settings easily.
            </p>
          </section>
          <section className="about-section">
            <h2 className="about-section-title">Ready to Get Started?</h2>
            <p>
              Join the families who have already discovered the joy of learning with our app. Get started today and watch your child thrive both academically and financially. It's more than just an app – it's an investment in your child's future.
            </p>
          </section>
        </div>
        <Footer />
      </div>
    );
  };
  
  export default About;
  
