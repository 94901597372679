import React, { useState, useEffect, useContext } from 'react';
import './LeaderboardScreen.css'; // CSS file for styling
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase'; // Update the import path as needed
import Header from '../../componnts/Header/Header';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext'; // Adjust the import path as needed
import PageTracker from '../../componnts/PageTracker/PageTracker';


interface UserStat {
  correctAnswers: number;
  totalAttempts: number;
}

interface LeaderboardEntry {
  displayName: string;
  username: string;
  correctAnswers: number;
  totalAttempts: number;
  achievementLevel: number;
  avatar: string;
}

interface LeaderboardResponse {
  leaderboard: LeaderboardEntry[];
}

const LeaderboardScreen: React.FC = () => {
  const { user, initializing } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<LeaderboardEntry[]>([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState<string>('1');

  const badgeUrls: { [key: string]: string } = {
    '0': '/badge0.png',
    '1': '/badge1.png',
    '2': '/badge2.png',
    '3': '/badge3.png',
    '4': '/badge4.png',
    '5': '/badge5.png',
    '6': '/badge6.png',
    '7': '/badge7.png',
    '8': '/badge8.png',
    '9': '/badge9.png',
    '10': '/badge10.png',
    '11': '/badge11.png',
    '12': '/badge12.png',
    '13': '/badge13.png',
    '14': '/badge14.png',
    '15': '/badge15.png',
  };

  const badgeTitles: { [key: string]: string } = {
    '0': 'Newbie',
  '1': 'Pro Scholar',
  '2': 'Pro Virtuoso',
  '3': 'Pro Maestro',
  '4': 'Pro Champion',
  '5': 'Pro Legend',
  '6': 'Genius Scholar',
  '7': 'Genius Virtuoso',
  '8': 'Genius Maestro',
  '9': 'Genius Champion',
  '10': 'Genius Legend',
  '11': 'Master Scholar',
  '12': 'Master Virtuoso',
  '13': 'Master Maestro',
  '14': 'Master Champion',
  '15': 'Master Legend',
  };

  useEffect(() => {
    if (!user && !initializing) {
      navigate('/login');
    }
  }, [user, initializing, navigate]);
  

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const getLeaderboard = httpsCallable<unknown, LeaderboardResponse>(functions, 'getLeaderboard');
        const response = await getLeaderboard({ difficultyLevel: selectedDifficulty });

        if (response.data.leaderboard && Array.isArray(response.data.leaderboard)) {
          setUsers(response.data.leaderboard);
        } else {
          throw new Error('Invalid leaderboard data received from the server.');
        }
      } catch (error) {
        alert('Error: Failed to fetch leaderboard data.');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, [selectedDifficulty]);

  if (loading) {
    return <LoadingScreen/>;
  }

  const Podium = () => {
    // Handle cases where there are less than 3 users
    const first = users[0] || {};
    const second = users[1] || {};
    const third = users[2] || {};
  
    return (
      <div className="podiumContainer">
        <div className="podiumPlaces">

          <div className="podiumSecond">
            <span className="podiumPosition">2nd</span>
            <img src={second.avatar} alt="Avatar" className="avatarSecond" />
            <span className="usernamePodium">{second.username || 'N/A'}</span>
            


          </div>
          <div className="podiumFirst">
            <span className="podiumPosition">1st</span>
            <img src={first.avatar} alt="Avatar" className="avatarFirst" />
            <span className="usernamePodium">{first.username || 'N/A'}</span>
            

          </div>
          <div className="podiumThird">
            <span className="podiumPosition">3rd</span>
            <img src={third.avatar} alt="Avatar" className="avatarThird" />
            <span className="usernamePodium">{third.username || 'N/A'}</span>
            

          </div>

        </div>
        <img className="podium-image" src="/podium.png" alt="podium-image"></img>
      </div>
    );
  };
  

  const renderItem = (item: LeaderboardEntry, index: number) => {
    const animationDelay = `${index * 0.1}s`;

    const badgeUrl = badgeUrls[item.achievementLevel.toString()] || badgeUrls['0'];
  const badgeTitle = badgeTitles[item.achievementLevel.toString()] || badgeTitles['0'];

    return (
      <div 
      className="rankContainer" 
      key={index}
      style={{
        animation: `cascadeEntry 0.8s ease-out forwards ${animationDelay}`,
        opacity: 0 // Start as invisible
      }}
      >
        <div className="itemContainer">
            <div className="item-primary-container">
              <div className="rank-container">
                  <span className="rank">{index + 1}</span>
              </div>
              <div className="avatar-container">
                  <img src={item.avatar} alt="Avatar" className="rank-avatar" />
              </div>
            </div>
            <div className="item-secondary-container">
              <div className="item-info-container">
                <div className="username-container">
                    <span className="rank-username">{item.username} </span>
                </div>
                <div className="score-container">
                    <span className="score">{item.correctAnswers} Solved</span>
                </div>
              </div>
              <div className="leaderboard-achievement-container">
                <img src={badgeUrl} alt={badgeTitle} title={badgeTitle} className="leaderboard-achievement-badge" />
                <span className="achievement">{badgeTitle}</span>
              </div>
           </div>
        </div>
      </div>
    );
  };

  return (
    <div className="leaderboard-main-container">
      <PageTracker />
        <Header></Header>
        <div className="leaderboardContainer">
            <h1 className="leaderboard-title">Leaderboard</h1>
            <Podium />
            <div className="rank-board">
            <h2 className="rankTitle">Rankings</h2>
            <select
                value={selectedDifficulty}
                onChange={(e) => setSelectedDifficulty(e.target.value)}
                className="difficultySelector"
            >
                {Array.from({ length: 12 }, (_, i) => `${i + 1}`).map((level) => (
                <option key={level} value={level}>{`Grade ${level}`}</option>
                ))}
            </select>

                {users.map((user, index) => renderItem(user, index))}
            </div>
        </div>
    </div>
  );
};

export default LeaderboardScreen;
