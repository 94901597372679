import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';

type AuthContextType = {
    user: User | null;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    initializing: boolean; // Add this line
};


const defaultContextValue: AuthContextType = {
    user: null,
    setUser: () => {},
    initializing: true,
  };
  
  export const AuthContext = createContext<AuthContextType>(defaultContextValue);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

type AuthProviderProps = {
    children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [initializing, setInitializing] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);

    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setInitializing(false);
            setLoading(false);
            
        });


        return unsubscribe;
    }, []);

    // useEffect(() => {
    //     return ;
    // }, []);

    const value = {
        user,
        setUser,
        initializing,
        // Add additional authentication functions here
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
