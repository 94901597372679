// NumberPadButton.tsx

import React from 'react';
import './NumberPadButton.css'; // Import the CSS file

interface NumberPadButtonProps {
  label: string;
  onPress: () => void;
}

const NumberPadButton: React.FC<NumberPadButtonProps> = ({ label, onPress }) => {
  return (
    <button className="button" onClick={onPress}>
      {/* <div className="gradient"> */}
        <span className="buttonText">{label}</span>
      {/* </div> */}
    </button>
  );
};

export default NumberPadButton;
