// NoConnectionScreen.tsx

import React from 'react';
import PageTracker from '../../componnts/PageTracker/PageTracker';

interface NoConnectionScreenProps {
  onRetry: () => void; // This defines the onRetry prop as a function that returns void
}

const NoConnectionScreen: React.FC<NoConnectionScreenProps> = ({ onRetry }) => {
  return (
    <div>
      <PageTracker />
      <h1>No Internet Connection</h1>
      <p>Please check your connection and try again.</p>
      <button onClick={onRetry}>Retry</button>
    </div>
  );
};

export default NoConnectionScreen;
