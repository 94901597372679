import React, { useState, useContext, useEffect} from 'react';
import './AccountUpdateScreen.css'
import { getAuth, EmailAuthProvider, updateEmail, sendPasswordResetEmail, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import {functions} from '../../firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust the import path as needed
import {httpsCallable} from 'firebase/functions';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Header from '../../componnts/Header/Header';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import AvatarSelection from '../../componnts/AvatarSelection/AvatarSelection';
import PageTracker from '../../componnts/PageTracker/PageTracker';

const AccountUpdateScreen: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [newEmail, setNewEmail] = useState('');
    const [newUsername, setNewUsername] = useState('');
    const [currentAvatarUri, setCurrentAvatarURI] = useState<string>('');
  const [currentUsername, setCurrentUsername] = useState<string>('');
    const [password, setPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
const [passwordConfirmation, setPasswordConfirmation] = useState('');
const [selectedAvatarUri, setSelectedAvatarUri] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [message, setMessage] = useState('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const location = useLocation();
    const actionType = location.state?.actionType;
    const { user, initializing } = useContext(AuthContext);
    const auth = getAuth();
    const navigate = useNavigate();

   
    useEffect(() => {
      // Allow users to access forgot_password actionType without being logged in
      if (!user && actionType !== 'forgot_password') {
          navigate('/login'); // Redirect to login if no user is found and the action is not forgot_password
      } else if (user && !user.emailVerified) {
          navigate('/verify'); // Redirect to verify if user is logged in but not verified
      } else if (user && user.emailVerified && !actionType) {
          navigate('/settings'); // Redirect to settings if actionType is not provided
      } else if (actionType === 'forgot_password' || (user && user.emailVerified && actionType)) {
          // Allow forgot_password action or other actions if user is logged in and verified
          if (user) {
              fetchUserProfile(); // Fetch user profile if logged in and verified
          }
      }
  }, [user, initializing, actionType, navigate]);
  
    
    const fetchUserProfile = async () => {
      if (user) {
        try {
          const userRef = doc(db, 'Users', user.uid);
          const userProfileSnapshot = await getDoc(userRef);
          if (userProfileSnapshot.exists()) {
            const userProfileData = userProfileSnapshot.data();
            setCurrentAvatarURI(userProfileData.avatar);
            setCurrentUsername(userProfileData.username);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      }
    };

    const handleForgotPassword = async () => {
        setLoading(true);
        setError('');
        setMessage('');
      
        try {
          await sendPasswordResetEmail(auth, email);
          setMessage('Password reset link sent! Check your email.');
        } catch (error: any) {
          setError('Failed to send password reset email. ' + error.message);
        }
      
        setLoading(false);
      };


    const handleEmailChange = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setMessage('');

        if (user) {
            try {
                await updateEmail(user, newEmail);
                setMessage('Email updated successfully.');
            } catch (error: any) {
                setError('Failed to update email. ' + error.message);
            }
        } else {
            setError('No user is currently logged in.');
        }
        setLoading(false);
    };

    const handlePasswordChange = async () => {
        if (password !== passwordConfirmation) {
          setError("Passwords do not match.");
          return;
        }
        // Additional password validations...
      
        setError('');
        setMessage('');
        setLoading(true);
      
        try {
          if (user && user.email) {
            const credential = EmailAuthProvider.credential(
              user.email,
              currentPassword
            );
            await reauthenticateWithCredential(user, credential);
      
            await updatePassword(user, password);
            setMessage('Password updated successfully.');
          } else {
            throw new Error('No user is currently logged in.');
          }
        } catch (error: any) {
          setError('Failed to update password. ' + error.message);
        }
        setLoading(false);
      };
      interface UniqueCheckResponse {
        unique: boolean;
      }

      const checkUsername = async (username: string) => {
        const isUsernameValid = (username: string) => {
            return username.length >= 2 && username.length <= 20;
          };
        if (!isUsernameValid(username)) {
          setError("Username must be between 2 and 20 characters long.");
          return false;
        }
        const checkUsernameUnique = httpsCallable(functions, 'checkUsernameUnique');
        try {
          const result = await checkUsernameUnique({ username });
          const response = result.data as UniqueCheckResponse;
          if (!response.unique) {
            setError("This username is already in use. Please choose a different username.");
            return false;
          }
          return true;
        } catch (error) {
          console.error("Error checking username uniqueness:", error);
          setError("Unable to check username uniqueness. Please try again later.");
          return false;
        }
      };

      const handleUsernameChange = async () => {
        setError('');
        setLoading(true);
      
        try {
          // Check if the username is valid and unique
          const isValidUsername = await checkUsername(newUsername);
          if (!isValidUsername) {
            setLoading(false);
            return; // Stop the function if the username is not valid or unique
          }
      
          // Assuming 'Users' is your Firestore collection where user profiles are stored
          if (user) {
            const userRef = doc(db, 'Users', user.uid);
            await updateDoc(userRef, { username: newUsername });
            setMessage('Username updated successfully.');
          } else {
            throw new Error('No user is currently logged in.');
          }
        } catch (error: any) {
          setError('Failed to update username. ' + error.message);
        }
        setLoading(false);
      };

      const handleAvatarSelect = (avatarUri: string) => {
        setSelectedAvatarUri(avatarUri); // Store the selected avatar URI
      };
    
      const updateAvatar = async () => {
        setLoading(true);
        setError('');
    
        try {
          if (user) {
            const userRef = doc(db, 'Users', user.uid);
            await updateDoc(userRef, { avatar: selectedAvatarUri });
            await fetchUserProfile(); // Fetch the updated profile
            setMessage('Avatar updated successfully.');
          } else {
            throw new Error('No user is currently logged in.');
          }
        } catch (error: any) {
          setError('Failed to update avatar. ' + error.message);
        }
        setLoading(false);
      };
    
      
      
      

    const handleUpdate = async (updateType: string) => {
        setError('');
        setLoading(true);
        try {
            if (updateType === 'change_password' && user) {
                await sendPasswordResetEmail(auth, email);
                setMessage('Password reset link sent! Check your email.');
            } else if (updateType === 'change_username') {
                await handleUsernameChange();
            } else if (updateType === 'change_avatar') {
                // Implement avatar update logic here
            }
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    };

    const renderFormContent = () => {
        switch (actionType) {
            case 'forgot_password':
                return (
                    <div className="change-form-inputs">
                    <h2 className="change-title">Forgot Password</h2>
                    <form className="change-form" onSubmit={(e) => { e.preventDefault(); handleForgotPassword(); }}>
                        <input
                        className="change-input"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email address"
                        required
                        />
                        <button type="submit" className="submit-button">
                        Send Reset Email
                        </button>
                        {message && <p className="message">{message}</p>}
                        {error && <p className="error-message">{error}</p>}
                    </form>
                    </div>
                );
          case 'forgot_password':
            return (
              <div className="change-form-inputs">
                <h2 className="change-title">Change Email</h2>
                <input className="change-input" type="email" value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       placeholder="Enter your email for password reset" required />
                <button className="submit-button" onClick={() => handleUpdate('change_password')}>
                  Send Reset Email
                </button>
                {message && <p className="message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
              </div>
            );
          case 'change_email':
            return (
                <div className="change-form-inputs">
                <h2 className="change-title">Change Email</h2>
              <form className="change-form" onSubmit={handleEmailChange}>
                <input 
                    className="change-input" 
                    type="email" value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    placeholder="Enter new email" required />
                <button type="submit" className="submit-button">Update Email</button>
                {message && <p className="message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
              </form>
              </div>
            );
          case 'change_password':
            return (
                <div className="change-form-inputs">
                <h2 className="change-title">Change Password</h2>
                <form className="change-form" onSubmit={(e) => { e.preventDefault(); handlePasswordChange(); }}>
                <input
                    className="change-input"
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    placeholder="Current password"
                    required
                />
                <input
                    className="change-input"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="New password"
                    required
                />
                <input
                    className="change-input"
                    type="password"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    placeholder="Confirm new password"
                    required
                />
                <button type="submit" className="submit-button">
                    Update Password
                </button>
                {message && <p className="message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
                </form>
                </div>
            
            );
          case 'change_username':
            return (
              <div className="change-form-inputs">
                <h2 className="change-title">Change Username</h2>
                <div className="change-form">
                <input className="change-input" type="text" value={newUsername}
                       onChange={(e) => setNewUsername(e.target.value)}
                       placeholder="Enter new username" required />
                <button className="submit-button" onClick={() => handleUpdate('change_username')}>
                  Update Username
                </button>
                {message && <p className="message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
                </div>
              </div>
            );
            case 'change_avatar':
                return (
                  <div className="change-form-inputs-avatar">
                    <h2 className="change-title">Change Avatar</h2>
                    <h3>Current Avatar</h3>
                    <img src={currentAvatarUri} className="change-avatar-image" alt="Avatar" />
                    <AvatarSelection onAvatarSelect={handleAvatarSelect} />
                    <button className="submit-button-avatar" onClick={updateAvatar}>
                      Update Avatar
                    </button>
                    {message && <p className="message">{message}</p>}
                    {error && <p className="error-message">{error}</p>}
                  </div>
                );
          default:
            return null;
        }
      };
      if (initializing) {
        return <LoadingScreen />;
    }

    return (
        <div className="account-update-container">
          <PageTracker />
        <Header />
        <div className="case-container">
            {initializing ? <LoadingScreen /> :
                actionType === 'forgot_password' || user ? renderFormContent() : (
                    <div>
                        <p>You need to be logged in to update your account settings.</p>
                        <button className="submit-button" onClick={() => navigate('/login')}>Go to Login</button>
                    </div>
                )
            }
        </div>
    </div>
    );
};

export default AccountUpdateScreen;
