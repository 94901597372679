import './LoginScreen.css';
import React, { useState, useEffect, useContext } from 'react';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, doc, updateDoc, serverTimestamp  } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
//import { useDispatch } from 'react-redux';
//import { fetchUserSettings } from '../../redux/actions';
import { AuthContext } from '../../contexts/AuthContext';
import Header from '../../componnts/Header/Header';
import PageTracker from '../../componnts/PageTracker/PageTracker';

const LoginScreen: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    
    const auth = getAuth();
    const firestore = getFirestore();
    const navigate = useNavigate();

    const { user, initializing } = useContext(AuthContext) ?? { user: null, initializing: true };

    useEffect(() => {
        if (!initializing && user) {
            navigate('/menu'); // Navigate to the Menu screen
        }
    }, [user, initializing, navigate]);

    const handleForgotPassword = async () => {
        if (!email) {
            setError('Please enter your email address.');
            return;
        }
    
        try {
            await sendPasswordResetEmail(auth, email);
            alert('Password reset link sent! Check your email.');
        } catch (error) {
            setError('Failed to send password reset email.');
        }
    };

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            // Explicitly declare userCredential before using it
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setLoading(false);

            // Ensure userCredential.user exists before proceeding
            if (userCredential.user) {
                // Update lastLogin in Firestore
                const userRef = doc(firestore, "Users", userCredential.user.uid); // Reference to the user's document
                await updateDoc(userRef, {
                    lastLogin: serverTimestamp() // Update lastLogin field with current date and time
                });
            }

            // Navigation to '/menu' will be handled by useEffect
        } catch (error: any) {
            setLoading(false);
            setError(getFriendlyErrorMessage(error.code));
        }
    };

    const getFriendlyErrorMessage = (errorCode: string) => {
        switch (errorCode) {
            case 'auth/wrong-password':
                return 'Incorrect password. Please try again.';
            case 'auth/user-not-found':
                return 'No account found with this email. Would you like to create one?';
            case 'auth/too-many-requests':
                return 'Too many login attempts. Please try again later.';
            // Add more cases for different error codes as needed
            default:
                return 'An unexpected error occurred. Please try again.';
        }
    };

    const navigateTo = (path: string, actionType: string) => {
        navigate(path, { state: { actionType } }); // Pass actionType in the state
    };

    return (
        <div className="login-container">
            <PageTracker />
            <Header></Header>
            <div className="form-container">
                <form className="form" onSubmit={handleLogin}>
                    <h1 className="login-title">Sign In</h1>
                    <label className="login-label">Email</label>
                    <input className="login-input"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                    />
                    <label className="login-label">Password</label>
                    <input className="login-input"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                    />
                    {error && <p className="login-error">{error}</p>}
                    <div className="login-button-container">
                        <button className="login-button" type="submit" disabled={isLoading}>
                            {isLoading ? 'Logging in...' : 'Login'}
                        </button>
                    </div>
                </form>
                <div className="forgot-container">
                    <button className="settings-link" onClick={() => navigateTo('/accountupdate', 'forgot_password')}>
                        <span>Forgot Password?</span>
                    </button> 
                </div>
                <div className="sign-up-container">
                    <span>New to Mathbucks?</span>
                    <a href="/register" className="forgot-link">
                        <span>Sign up now</span>
                    </a> 
                </div>
                
            </div>
        </div>
    );
};

export default LoginScreen;
