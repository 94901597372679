import './RewardScreen.css';
import Header from '../../componnts/Header/Header';
import React, { useState, useEffect, useContext } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
//import { tangoCardFetch, placeTangoOrder } from '../../tangoCardApi'
import { httpsCallable } from 'firebase/functions';
import {functions} from '../../firebase'
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import NumberPadButton from '../../componnts/NumberPadButton/NumberPadButton';
import Footer from '../../componnts/Footer/Footer';
import { FiChevronDown, FiChevronUp } from "react-icons/fi"; // Import Chevron icons from react-icons
import PageTracker from '../../componnts/PageTracker/PageTracker';




interface RedeemModalProps {
  isOpen: boolean;
  onClose: () => void;
  balance: number;
  onAmountChange: (amount: string) => void;
  amount: string;
  onRedeem: (amount: string) => Promise<void>;
  setError: (error: string | null) => void;
  error: string | null; // Add this line
}



const RedeemModal: React.FC<RedeemModalProps> = ({
  isOpen,
  onClose,
  balance,
  onAmountChange,
  amount,
  onRedeem,
  setError, // Use setError from props
  error, // Add this line to destructure error from props
}) => {
  const [inputValue, setInputValue] = useState<string>(amount);
  const [preview, setPreview] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [redeemSuccess, setRedeemSuccess] = useState<boolean | null>(null);

  useEffect(() => {
    setInputValue(amount);
    setPreview(false);
    setError(null);
    setModalLoading(false);
    setRedeemSuccess(null);
  }, [amount, isOpen]);

  const isValidInput = (value: string): boolean => {
    return value === '' || /^(\d+\.?\d{0,2}|\.\d{0,2})$/.test(value);
  };

  const handleKeypadPress = (key: string) => {
    setInputValue((currentValue) => {
      let newValue = currentValue;
      switch (key) {
        case '←':
          newValue = currentValue.slice(0, -1);
          break;
        case 'C':
          newValue = '';
          break;
        default:
          newValue = currentValue + key;
          break;
      }

      // Update state if new value is valid or revert to current value
      return isValidInput(newValue) ? newValue : currentValue;
    });
  };

  const handlePreview = () => {
    const value = parseFloat(inputValue);
    if (!inputValue || isNaN(value) || value <= 0) {
      setError('Please enter a valid amount greater than $0.00');
      return;
    }

    if (value > balance) {
      setError(`The amount exceeds your available balance of $${balance.toFixed(2)}`);
      return;
    }

    setError(null); // Clear error if input passes validation
    setPreview(true);
  };

  // Call onRedeem with inputValue when Redeem Now is clicked
  const handleRedeemClick = async () => {
    setModalLoading(true);
    try {
      await onRedeem(inputValue);
      setRedeemSuccess(true);
    } catch (error) {
      console.error('Error during redeem:', error);
      setError('Failed to redeem. Please try again.');
      setRedeemSuccess(false);
    }
    setModalLoading(false);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (!preview && isOpen) {
        const { key } = event;
        if (key === 'Backspace' || key === 'Delete') {
          handleKeypadPress('←');
        } else if (key.match(/[0-9.]/)) {
          handleKeypadPress(key);
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [preview, isOpen, handleKeypadPress]);

  if (!isOpen) return null;

  return (
    <div className="redeem-modal-overlay">
      <div className="redeem-modal-content" onClick={(e) => e.stopPropagation()}>
        {modalLoading ? (
          <div className="modal-loading">
            Processing Order
            <div className="spinner"></div>
          </div>
        ) : redeemSuccess === true ? (
          <>
            <h2 className="redeem-modal-title">Success!</h2>
            <p className="redeem-modal-success">Your redeem request was successful. Check your email for your Gift Card code.</p>
            <button className="redeem-buttons" onClick={onClose}>Done</button>
          </>
        ) : redeemSuccess === false ? (
          <>
            <h2>Failed</h2>
            <p>{error}</p>
            <button onClick={() => setPreview(false)}>Try Again</button>
          </>
        ) : (
          <>
            <h2 className="redeem-modal-title">{preview ? "Confirm Redeem" : "Redeem"}</h2>
            {preview ? (
              <>
                <p className="confirm-redeem-modal-balance">Amount to Redeem: ${parseFloat(inputValue).toFixed(2)}</p>
                <p className="confirm-redeem-modal-balance">Balance after Redeem: ${(balance - parseFloat(inputValue)).toFixed(2)}</p>
                <p className="rewards-info">All redemptions are final, we cannot process refunds.</p>
                <button className="redeem-buttons"  onClick={handleRedeemClick}>Redeem Now</button>
                <button className="redeem-buttons"  onClick={() => setPreview(false)}>Edit</button>
              </>
            ) : (
              <>
                <p className="redeem-modal-balance">Available Balance: ${balance.toFixed(2)}</p>
                {error && <p className="error-message">{error}</p>}
                <input className="redeem-input" type="text" value={inputValue} readOnly placeholder='Enter Amount' />
                <div className="redeem-numberPad">
                  {['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', '←'].map((key) => (
                    <NumberPadButton key={key} label={key} onPress={() => handleKeypadPress(key)} />
                  ))}
                </div>
                <button className="redeem-buttons" onClick={handlePreview}>Preview</button>
              </>
            )}
            <button className="redeem-buttons" onClick={onClose}>Cancel</button>
          </>
        )}
      </div>
    </div>
  );
  
  
  
};

interface FetchInformationResponseData {
    earned: number;
  }
  
  interface TangoCardFetchResponse {
    brands: Brand[];
  }
  
  interface Brand {
    brandKey: string;
    brandName: string;
    description: string;
    items: Item[];
    imageUrls: {
      [key: string]: string; // This allows for dynamic keys with string values
    };
  }
  
  
  interface Item {
    utid: string;
    rewardName: string;
    redemptionInstructions: string;
    valueType: 'FIXED_VALUE' | 'VARIABLE_VALUE'; // Add valueType
    faceValue?: number;  // Optional, as it may not be present for VARIABLE_VALUE types
    minValue?: number;   // Optional, as it may not be present for FIXED_VALUE types
    maxValue?: number;   // Optional, as it may not be present for FIXED_VALUE types
  }
  

  const RewardScreen: React.FC = () => {
    const auth = getAuth();
  const { user } = useContext(AuthContext) ?? { user: null };
  const [earnedBalance, setEarnedBalance] = useState<number>(0);
  const [redeemAmount, setRedeemAmount] = useState<string>('');
  const [error, setError] = useState<string | null>(null); // Add this line
  const [amount, setAmount] = useState('');
  const [step, setStep] = useState(1); // Step 1 for input, Step 2 for confirmation
  const [redeemModalOpen, setRedeemModalOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBrands, setFilteredBrands] = useState<Brand[]>([]);
  const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({});
  const [expandedBrand, setExpandedBrand] = useState<string | null>(null);
  const [placingOrder, setPlacingOrder] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [brands, setBrands] = useState<Brand[]>([]);
  const endpoint = "/catalogs?utid=U453114";
  const navigate = useNavigate();

  

   // Open the redeem modal
  const handleOpenRedeemModal = () => {
    setRedeemModalOpen(true);
  };

  // Close the redeem modal
  const handleCloseRedeemModal = () => {
    setRedeemModalOpen(false);
    setError(null);
  };

  

  const fetchBalanceAndCatalogs = async () => {
    // Only proceed if there is a user
    if (user) {
      setLoading(true); // Set loading to true at the start of the operation
      const idToken = await auth.currentUser?.getIdToken(true);
  
      try {
        const fetchInformationPromise = httpsCallable<unknown, FetchInformationResponseData>(
          functions,
          'fetchInformation'
        )({ idToken });
        const tangoCardFetchPromise = httpsCallable<unknown, TangoCardFetchResponse>(
          functions,
          'tangoCardFetch'
        )();
  
        // Wait for both promises to resolve
        const [fetchInformationResult, tangoCardFetchResult] = await Promise.all([
          fetchInformationPromise,
          tangoCardFetchPromise,
        ]);
  
        // Update state with results from both promises
        setEarnedBalance(fetchInformationResult.data.earned);
        setBrands(tangoCardFetchResult.data.brands);
        console.log(tangoCardFetchResult.data.brands)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false regardless of the result
      }
    }
  };

  
  
  useEffect(() => {
    if (!searchQuery) {
      setFilteredBrands(brands);
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const filtered = brands.filter((brand) =>
        brand.brandName.toLowerCase().includes(lowerCaseQuery)
      );
      setFilteredBrands(filtered);
    }
  }, [brands, searchQuery]);
  

  // Make sure to call this function without awaiting it directly in the useEffect hook
  useEffect(() => {
    fetchBalanceAndCatalogs();
  }, [user]); // Depend on user so it will re-run the effect when the user changes


  const handleRedeemNow = async (redeemAmount: string) => {
    setError(null);
    try {
      await handlePlaceOrder(redeemAmount);
      // Handle success
    } catch (error) {
      console.error('Error placing order:', error);
      setError('Failed to place order. Please try again.'); // Use setError here
    }
  };


  const handlePlaceOrder = async (redeemAmount: any) => {
    if (!user || !user.emailVerified) { // Check if user is not signed in or not verified
      if (!user) {
        console.log('Error', 'You must be signed in to place an order.');
      } else {
        console.log('Error', 'You must verify your email before placing an order.');
        alert('You must verify your email before placing an order.');
        navigate('/verify'); // Redirect to verification page if not verified
      }
      return;
    }
    
    setPlacingOrder(true);
  
    try {
      const idToken = await user.getIdToken(true);
      const amount = parseFloat(redeemAmount);
      if (amount <= 0 || amount > earnedBalance) {
        console.log('Error', 'Invalid amount entered.');
        setPlacingOrder(false);
        return;
      }
  
      // Prepare the necessary user data
      const userData = {
        displayName: user.displayName,
        email: user.email
      };
  
      // Place the order
      const placeTangoOrder = httpsCallable(functions, 'placeTangoOrder');
      await placeTangoOrder({ user: userData, amount, idToken });
      console.log('Order placed successfully');
  
      // Update the balance only if the order placement was successful
      const updateBalance = httpsCallable(functions, 'updateBalance');
      await updateBalance({ idToken, amountUsed: amount });
  
      setEarnedBalance(earnedBalance - amount);
    } catch (error) {
      console.error('Error placing order:', error);
      console.log('Error', 'Failed to place order');
    } finally {
      setPlacingOrder(false);
    }
  };
  


const handleRedeemAmountChange = (newAmount: string) => {
  setRedeemAmount(newAmount);
  // Close the modal and go to the next step if needed
};


// Toggle function for expanding and collapsing the faceValues of a brand
const handleToggleFaceValues = (brandKey: string) => {
  setExpandedItems(prevExpandedItems => ({
    ...prevExpandedItems,
    [brandKey]: !prevExpandedItems[brandKey]
  }));
};

// Render a brand with its items
const renderBrand = (brand: Brand) => {
  const hasMultipleValues = brand.items.length > 1;
  const isExpanded = expandedItems[brand.brandKey];

  return (
    <div key={brand.brandKey} className="brand">
      <h2 className="brand-name">{brand.brandName}</h2>
      <img className="Brand-img" src={brand.imageUrls['300w-326ppi']} alt={brand.brandName} />
      {hasMultipleValues ? (
        <>
          <div className="values-toggle" onClick={() => handleToggleFaceValues(brand.brandKey)}>
            {isExpanded ? <FiChevronUp /> : <FiChevronDown />} Multiple
          </div>
          {isExpanded && (
            <ul className="values-list">
              {brand.items.map(item => (
                <li className="values" key={item.utid}>${item.faceValue}</li>
              ))}
            </ul>
          )}
        </>
      ) : (
        <div className="values">${brand.items[0].minValue} - ${brand.items[0].maxValue}
          </div>
        
      )}
    </div>
  );
};

  

  if (loading) {
    return <LoadingScreen/>;
  }



// Main component return
return (
  <div className="rewards-container">
    <PageTracker />
    <Header />
    <h1 className="rewards-title">Rewards</h1>
    <div className="rewards-info-container">
      <p className="rewards-info">Please note the value range for each Gift Card before redemption. For example, Adidas Gift Cards can be redeemed for any amount between $5 and $500. Ensure your selected value falls within this range and does not exceed your available balance.</p>
      <p className="rewards-info">All redemptions are final, we cannot process refunds.</p>
      <div className="rewards-action">
        <p className="rewards-balance">Earned: ${earnedBalance.toFixed(2)}</p>
        <button className="rewards-order-button" onClick={handleOpenRedeemModal}>
          Redeem Rewards
        </button>
      </div>

      <RedeemModal
        isOpen={redeemModalOpen}
        onClose={handleCloseRedeemModal}
        balance={earnedBalance}
        onAmountChange={setRedeemAmount}
        amount={redeemAmount}
        onRedeem={handleRedeemNow}
        setError={setError} // Pass setError as a prop
        error={error} // Pass error as a prop
      />
    </div>

    <div className="search-container">
    <p className="rewards-info1">* The is a preview of the rewards catalog and does not show the entire list of gift cards *</p>
      <div className="search-text">Search:</div>
  <input
    type="text"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    placeholder="Search for brands..."
    className="search-input"
  />
</div>


    {/* Brands Grid */}
    <div className="brands-grid">
        {filteredBrands.map(renderBrand)}
      </div>
    <Footer></Footer>
  </div>
);


};

export default RewardScreen;