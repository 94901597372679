import React, { useState, useEffect } from 'react';
import './FAQScreen.css'; // Assuming you have a corresponding CSS file
import FAQ from '../../componnts/FAQ/FAQ';
import { getAuth } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase'; // Update the import path as needed
import Header from '../../componnts/Header/Header';
import Footer from '../../componnts/Footer/Footer';
import PageTracker from '../../componnts/PageTracker/PageTracker';

const FAQScreen: React.FC = () => {
  const [subject, setSubject] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');


  

  return (
    <div className="faq-screen-container">
      <PageTracker />
            <Header></Header>
        <div className="faq-screen-main-container">
            <h1 className="faq-screen-title">FAQ</h1>
            <p className="faq-screen-description">
                Below are frequently asked questions.
            </p>
            <FAQ></FAQ>
        </div>
        <Footer></Footer>
    </div>
  );
};

export default FAQScreen;
