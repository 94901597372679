import './MenuScreen.css';
import React, { useEffect, useContext, useState, FormEvent } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import {functions, db, auth} from '../../firebase'
import { doc, getDoc } from 'firebase/firestore';
import Header from '../../componnts/Header/Header';
import NavigationBar from '../../componnts/NavigationBar/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faGift, faUser, faLightbulb, faTrophy, faMedal, faQuestion, faGear, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import PageTracker from '../../componnts/PageTracker/PageTracker';
// Import Firebase from your firebase.js or firebase.ts file


const MenuScreen: React.FC = () => {
    const { user, initializing } = useContext(AuthContext) ?? { user: null, initializing: true };
    const [avatarURI, setAvatarURI] = useState<string>('');
    const [username, setUsername] = useState<string>('');

    const [isSubscribed, setIsSubscribed] = useState(false);
const [infoMessage, setInfoMessage] = useState('');
const [showInfoBlurb, setShowInfoBlurb] = useState(false);
    const navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
        // If the authentication status has been determined and there's no user logged in
        if (!initializing && !user) {
            navigate('/login');
        } else if (user) {
            // Fetch the subscription status only if there is a user
            fetchSubscriptionStatus();
            // Determine the message based on user's verification and subscription status
          let message = '';
          if (!user.emailVerified) {
            message = 'Your email has not been verified. You can start playing but will need to verify first to subscribe and start earning rewards.';
          } else if (user.emailVerified && !isSubscribed) {
            message = 'You can view rewards but need to be subscribed to place an order. Please subscribe first to earn rewards.';
          }
      
          setInfoMessage(message);
      
          // Decide whether to show the info blurb initially
          setShowInfoBlurb(message !== '');
        }
    }, [user, initializing, navigate]);
    
    const fetchSubscriptionStatus = async () => {
        // Make sure user object exists before proceeding
        if (!user) {
            console.log("Not authenticated, skipping fetch.");
            return;
        }
    
        try {
            const userRef = doc(db, 'Users', user.uid);
            const docSnap = await getDoc(userRef);
    
            if (docSnap.exists()) {
                setIsSubscribed(docSnap.data().subscriptionActive);
                setAvatarURI(docSnap.data().avatar);
                setUsername(docSnap.data().username);
                // ... rest of your code
            } else {
                console.log("No user document found in Firestore.");
            }
        } catch (error) {
            console.error("Error fetching subscription status:", error);
        }
    };
    
        
    

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/'); // Adjust the path to your welcome or login screen
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const toggleInfoBlurb = () => {
        setShowInfoBlurb(!showInfoBlurb);
      };

    const navigateTo = (path: string) => {
        navigate(path); // Replace with appropriate navigation logic
    };

    return (
        <div className="menu-container">
            <PageTracker />
            <Header/>
        <div className="topContainer menu-spikes">
            {/* <h1 className="menu-title">Start Learning and Earning</h1> */}
            <div className="top-button-container">
                <img src={avatarURI} alt="Avatar" className="menu-avatar" />
                    <h1 className="menu-welcome-title">Welcome, {username}</h1>
                <button className="menuButton playButton animated-button" onClick={() => navigateTo('/play')}>
                    <div className="button-content">
                    <FontAwesomeIcon icon={faPlay} className="faPlay"/>
                    <span className="button-title">Play</span>
                    </div>
                </button>
                {/* <button className="menuButton rewardsButton" onClick={() => navigateTo('/rewards')}>
                    <div className="button-content">
                    <FontAwesomeIcon icon={faGift} className="faGift"/>
                    <span className="button-title">Rewards</span>
                    </div>
                </button> */}
                {
                    (user && !user.emailVerified) && (
                        <div className="info-badge-container" onClick={toggleInfoBlurb}>
                        <div className="info-badge">!</div>
                        {showInfoBlurb && (
                            <div className="info-blurb">
                            {infoMessage}
                            </div>
                        )}
                        </div>
                    )
                    }

                    {
                    (user && user.emailVerified && !isSubscribed) && (
                        <div className="info-badge-container" onClick={toggleInfoBlurb}>
                        <div className="info-badge">!</div>
                        {showInfoBlurb && (
                            <div className="info-blurb">
                            {infoMessage}
                            </div>
                        )}
                        </div>
                    )
                    }



            </div>
            <div className="menuButtonContainer">
                <button className="circleButton profileButton animated-button" onClick={() => navigateTo('/profile')}>
                        <div className="button-content">
                        <FontAwesomeIcon icon={faUser} className="faUser"/>
                        <span className="button-title">Profile</span>
                        </div>
                </button>
                <button className="circleButton insightsButton animated-button" onClick={() => navigateTo('/insights')}>
                        <div className="button-content">
                        <FontAwesomeIcon icon={faLightbulb} className="faLightbulb"/>
                        <span className="button-title">Insights</span>
                        </div>
                </button>
                <button className="circleButton leaderboardButton animated-button" onClick={() => navigateTo('/leaderboard')}>
                        <div className="button-content">
                        <FontAwesomeIcon icon={faTrophy} className="faTrophy"/>
                        <span className="button-title">Leaderboard</span>
                        </div>
                </button>
                <button className="circleButton rewardsButton animated-button" onClick={() => navigateTo('/rewards')}>
                    <div className="button-content">
                    <FontAwesomeIcon icon={faGift} className="faGift"/>
                    <span className="button-title">Rewards</span>
                    </div>
                </button>
                {/* <button className="menuButton achievementsButton" onClick={() => navigateTo('/achievements')}>
                        <div className="button-content">
                        <FontAwesomeIcon icon={faMedal} className="faMedal"/>
                        <span className="button-title">Achievments</span>
                        </div>
                </button> */}
                {/* <button className="menuButton faqButton" onClick={() => navigateTo('/faq')}>
                        <div className="button-content">
                        <FontAwesomeIcon icon={faQuestion} className="faQuestion"/>
                        <span className="button-title">FAQ</span>
                        </div>
                </button> */}
                {/* <button className="menuButton settingsButton" onClick={() => navigateTo('/settings')}>
                        <div className="button-content">
                        <FontAwesomeIcon icon={faGear} className="faGear"/>
                        <span className="button-title">Settings</span>
                        </div>
                </button> */}
                {/* <button className="menuButton logoutButton" onClick={handleLogout}>
                        <div className="button-content">
                        <FontAwesomeIcon icon={faRightFromBracket} className="faRightFromBracket"/>
                        <span className="button-title">Logout</span>
                        </div>
                </button> */}

                {/* ...other buttons... */}
            </div>
        </div>

        
        {/* <NavigationBar></NavigationBar> */}
    </div>
    );
};

export default MenuScreen;
