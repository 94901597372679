import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext'; // Adjust the import path as needed

import './LoadingScreen.css'; // Path to your CSS file

 // Path to your logo

 const LoadingScreen = () => {
  
  
  
  
 return (
    <div className="loadingScreen">
      <div className="logo">
        <img src="/MathBucksLogo2.png" alt="MathBucks Logo" className="loading-image" />
      </div>
      <div className="spinningBar"></div>
    </div>
  );
 }
  
export default LoadingScreen;
