import './SettingsScreen.css';
import React, { useEffect, useContext, useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut, EmailAuthProvider, reauthenticateWithCredential  } from 'firebase/auth';
import { AuthContext } from '../../contexts/AuthContext';
import {functions, db, auth} from '../../firebase'
import { FirebaseError } from 'firebase/app';

import { doc, getDoc, updateDoc, runTransaction } from 'firebase/firestore';
import Header from '../../componnts/Header/Header';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { httpsCallable } from 'firebase/functions';
import Footer from '../../componnts/Footer/Footer';
import PageTracker from '../../componnts/PageTracker/PageTracker';

interface ConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (email: string, password: string) => Promise<void>;
  message: string;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  actionType: string; // Add this prop to determine the action type
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  errorMessage,
  setErrorMessage,
  actionType
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClose = () => {
    // Reset internal states when closing the modal
    setCheckbox1(false);
    setCheckbox2(false);
    setErrorMessage('');
    setEmail('');
    setPassword('');
    onClose(); // Call the onClose prop function to handle any additional logic needed when closing the modal
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Check if both checkboxes are checked
    if (!checkbox1 || !checkbox2) {
      setErrorMessage('Please agree to all terms.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(''); // Clear out any previous error messages
    try {
      await onConfirm(email, password);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
    } finally {
      setIsProcessing(false);
    }
  };

  // Define the checkbox messages based on the action type
  const checkboxMessages = actionType === 'disable' ? [
    "By disabling my account, it will be deleted after 1 year of inactivity.",
    "Balances remaining at the time of account deletion will be permanently lost."
  ] : [
    "Deleting my account will permanently erase all my data.",
    "Any balances will be permanently lost forever upon account deletion."
  ];
  

  if (!isOpen) return null;

  return (
    <div className="account-modal">
      <div className="account-modal-content">
        <p className="account-modal-message">{message}</p>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form className="account-modal-form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Confirm your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div>
            <label className="account-modal-checkbox-label">
              <input
              className="account-modal-checkbox"
                type="checkbox"
                checked={checkbox1}
                onChange={(e) => setCheckbox1(e.target.checked)}
              />
              {checkboxMessages[0]}
            </label>
            <label className="account-modal-checkbox-label">
              <input
              className="account-modal-checkbox"
                type="checkbox"
                checked={checkbox2}
                onChange={(e) => setCheckbox2(e.target.checked)}
              />
              {checkboxMessages[1]}
            </label>
          </div>
          <div className="account-modal-button-container">
            <button className="settings-link" type="submit" disabled={isProcessing}>Confirm</button>
            <button className="settings-link" type="button" onClick={handleClose} disabled={isProcessing}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};




// Define a type for your settings options
type SettingOption = {
  title: string;
  icon: string;
  screen: string;
};

const SettingsScreen: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [potential, setPotential] = useState<string>('');
  const [earned, setEarned] = useState<string>('');
  const [remaining, setRemaining] = useState<string>('');
  const [redeemed, setRedeemed] = useState<string>('');
  const [avatarURI, setAvatarURI] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>('');
  const [difficulty, setDifficulty] = useState<string>('');
  const navigate = useNavigate();
  const { user, initializing } = useContext(AuthContext) ?? { user: null, initializing: true };
  const [currentPlan, setCurrentPlan] = useState<string>('free');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState<() => void>(() => {});
  const [confirmEmail, setConfirmEmail] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');
const [actionType, setActionType] = useState<string>('');
const [errorMessage, setErrorMessage] = useState('');
    const auth = getAuth();

  // Define your settings options
  const settingsOptions: SettingOption[] = [
    { title: 'Game Settings', icon: 'videogame_asset', screen: 'GameSettings' },
    { title: 'Account Information', icon: 'person', screen: 'AccountInformation' },
    { title: 'Subscription', icon: 'help_outline', screen: 'subscription' },
    { title: 'Support', icon: 'support', screen: 'Support' },
    { title: 'FAQ', icon: 'question_answer', screen: 'FAQ' },
    // Additional options...
  ];

  // Navigation function
  // const navigateToScreen = (screen: string) => {
  //   navigate(`/${screen}`); // Adjust path as needed
  // };

  useEffect(() => {
    // Check if there is no signed-in user
    if (!user) {
      console.error("You must be logged in to view the Settings");
      navigate('/login'); // Redirect to login page
    } 
    // Check if the user is signed in but not verified
    else if (!user.emailVerified) {
      navigate('/verify'); // Redirect to verify page
    }
    // Fetch user profile if the user is signed in and verified
    else {
      try {
        fetchUserProfile();
      }catch (error) {
          alert('Error: Failed to fetch user data.');
          console.error(error);
        } finally {
          setLoading(false);
        }
    }
  }, [user, navigate]); // Dependencies include user and navigate to re-run the effect when they change
  
  

  const fetchUserProfile = async () => {
    if (user) {
      const userRef = doc(db, 'Users', user.uid); // Replace 'Users' with your specific collection name
      const userProfileSnapshot = await getDoc(userRef);
      if (userProfileSnapshot.exists()) {
        const userProfileData = userProfileSnapshot.data();
        setDifficulty(userProfileData.settings[difficulty]);
        setAvatarURI(userProfileData.avatar); // Replace 'avatarURI' with the actual field name in your Firestore document
        setDifficulty(userProfileData.settings.difficulty)
        setPotential(userProfileData.potential.toFixed(2))
        setEarned(userProfileData.earned.toFixed(2))
        setRemaining(userProfileData.remaining.toFixed(2))
        setRedeemed(userProfileData.redeemed.toFixed(2))
        setUsername(userProfileData.username)
        setEmail(userProfileData.email)
        setDisplayName(userProfileData.firstName + " " + userProfileData.lastName)
        setCurrentPlan(userProfileData.planName)
        if (userProfileData.subscriptionActive === true) {
          setSubscriptionStatus("Yes")
        } else {
          setSubscriptionStatus("No")
        }
      } else {
        console.log("No such document!");
      }
    }
  };

  const handleConfirm = async (email: string, password: string) => {
    const credential = EmailAuthProvider.credential(email, password);
    try {
      if (user) {
        await reauthenticateWithCredential(user, credential);
        // User re-authenticated, now you can perform the sensitive action
        if (actionType === 'disable') {
          await disableAccount();
        } else if (actionType === 'delete') {
          await deleteAccount();
        }
      }
    } catch (error) {
      let message = 'An unexpected error occurred. Please try again.';
      // Use a type assertion to tell TypeScript that error is of type FirebaseError
      const firebaseError = error as FirebaseError;
  
      switch (firebaseError.code) {
        case 'auth/invalid-credential':
          message = 'The provided credential is invalid. Please check your email and password.';
          break;
        case 'auth/wrong-password':
          message = 'The password is incorrect. Please try again.';
          break;
        case 'auth/user-not-found':
          message = 'No user found with this email. Please check the email address.';
          break;
        // Add more cases for other error codes as needed
        default:
          message = firebaseError.message; // Use the Firebase error message for other errors
      }
      setErrorMessage(message);
    }
  };
  
  

  const handleDisableAccount = () => {
    setActionType('disable'); // Set the action type to 'disable'
    setShowConfirmModal(true); // Then show the modal
  };
  
  const handleDeleteAccount = () => {
    setActionType('delete'); // Set the action type to 'delete'
    setShowConfirmModal(true); // Then show the modal
  };
  

  const disableAccount  = async () => {
    try {
      const deactivateUserAccount = httpsCallable(functions, 'deactivateUserAccount');
      const response = await deactivateUserAccount({ idToken: await user?.getIdToken() });
      // console.log(response);
      alert('Account has been disabled successfully.');
      signOut(auth).then(() => navigate('/'));
    } catch (error) {
      console.error('Error disabling account:', error);
      alert('Failed to disable account.');
    }
  };

 const deleteAccount  = async () => {
  try {
    const deleteUserAccount = httpsCallable(functions, 'deleteUserAccount');
    const response = await deleteUserAccount({ idToken: await user?.getIdToken() });
    // console.log(response);
    alert('Account has been deleted successfully.');
    // Sign out the user after account deletion
    signOut(auth).then(() => navigate('/'));
  } catch (error) {
    console.error('Error deleting account:', error);
    alert('Failed to delete account.');
  }
};
  

  const navigateTo = (path: string, actionType: string) => {
    navigate(path, { state: { actionType } }); // Pass actionType in the state
};

  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <div className="settings-container">
      <PageTracker />
      <Header/>
      <div className="settings-titleContainer">
      <h1 className="settings-title">Settings</h1>
        <div className="settings-info-container">

        </div>
      </div>

        <div className="category-container">
          <h1 className="category-title">Game Settings</h1>
          <div className="settings-info-container">
            <p className="settings-info">
              Difficulty: Grade {difficulty}
            </p>
          </div>
         

        </div>

        <div className="category-container">
          <h1 className="category-title">Account</h1>
          <div className="settings-info-container">
            <p className="settings-info">
              <span>
                Avatar: 
                <img src={avatarURI} alt="Avatar" className="settings-avatar" />
              </span>
              <button className="settings-link" onClick={() => navigateTo('/accountupdate', 'change_avatar')}>
                <span>Change Avatar</span>
              </button>
            </p>
            <p className="settings-info">
              Username: {username}
              <button className="settings-link" onClick={() => navigateTo('/accountupdate', 'change_username')}>
                <span>Change Username</span>
              </button>
            </p>
            <p className="settings-info">
              Name: {displayName}
            </p>
            <p className="settings-info">
              Email: {email}
              <button className="settings-link" onClick={() => navigateTo('/accountupdate', 'change_email')}>
                <span>Change Email</span>
              </button>
            </p>
            <p className="settings-info">
              Passsword: ********
              <button className="settings-link" onClick={() => navigateTo('/accountupdate', 'change_password')}>
                <span>Change Password</span>
              </button>
            </p>
          </div>
        </div>

        <div className="category-container">
          <h1 className="category-title">Subscription Details</h1>
          <div className="settings-info-container">
            <p className="settings-info">
              Subscription Active: {subscriptionStatus}
              <button className="settings-link" onClick={() => navigateTo('/subscription', 'change_password')}>
                <span>Manage Subscription</span>
              </button>
            </p>
            <p className="settings-info">
              Subscription Plan: {currentPlan}
            </p>
            <p className="settings-info">
              Card: ****
            </p>
          </div>
          

          </div>

        <div className="category-container">
          <h1 className="category-title">Balances</h1>
          <div className="settings-info-container">
              {/* <button className="settings-link" onClick={() => navigateTo('/subscription', 'change_password')}>
                <span>View History</span>
              </button> */}
            <p className="settings-info">
              Problem Value: $0.10/problem
            </p>
            <p className="settings-info">
              Potential: $ {potential}
            </p>
            <p className="settings-info">
              Earned: $ {earned}
            </p>
            <p className="settings-info">
              Remaining: $ {remaining}
            </p>
            <p className="settings-info">
              Redeemed: $ {redeemed}
            </p>
          </div>
        </div>

        <div className="category-container">
          {/* <h1 className="category-title">Balances</h1> */}
          <div className="settings-buttons-container">
              {/* <button className="settings-link" onClick={() => navigateTo('/subscription', 'change_password')}>
                <span>View History</span>
              </button> */}
           
           <button className="settings-link" onClick={handleDisableAccount}>
            <span>Disable Account</span>
          </button>

          <button className="settings-link" onClick={handleDeleteAccount}>
            <span>Delete Account</span>
          </button>

          </div>
        </div>
        <ConfirmModal 
          isOpen={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={handleConfirm}
          message={`Please confirm your email and password to ${actionType === 'disable' ? 'disable' : 'delete'} your account.`}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          actionType={actionType}
        />


      <Footer></Footer>
    </div>
  );
};

export default SettingsScreen;
